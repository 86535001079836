import { ReservationInsuranceInfoWithPreReservationDto } from '@orcar/common';
import {
  isMobileAtom,
  ReservationCard,
  ReservationListNull,
  SkeletonReservationCard,
  useRecoil,
} from '@orcar/common-d2c';
import { Fragment } from 'react/jsx-runtime';
import styled from 'styled-components';

type ReservationListByUserProps = {
  isLoading: boolean;
  reservationList?: ReservationInsuranceInfoWithPreReservationDto[];
  customerName?: string;
  onReservationCardClick: (id?: string) => void;
};

const ReservationList = ({
  isLoading,
  reservationList,
  onReservationCardClick,
}: ReservationListByUserProps) => {
  const { recoilValue: isMobile } = useRecoil(isMobileAtom);

  return isLoading ? (
    <CardWrapper isMobile={isMobile}>
      <SkeletonReservationCard isMobile={isMobile} />
      <SkeletonReservationCard isMobile={isMobile} />
    </CardWrapper>
  ) : (
    <Fragment>
      <CardWrapper isMobile={isMobile}>
        {reservationList?.length === 0 ? (
          <ReservationListNull />
        ) : (
          reservationList?.map(
            (reservation) =>
              reservation && (
                <ReservationCard
                  key={reservation.id}
                  isMobile={isMobile}
                  id={reservation.id}
                  vehicleModelName={reservation.vehicleModel.name}
                  companyName={reservation.preReservation?.companyName}
                  pickUpAt={reservation.pickUpAt}
                  dropOffAt={reservation.dropOffAt}
                  hasPayment={!!reservation.preReservation?.d2cPayment}
                  price={reservation.preReservation?.price}
                  reservationState={reservation.state}
                  onClick={() => onReservationCardClick(reservation?.id)}
                />
              ),
          )
        )}
      </CardWrapper>
    </Fragment>
  );
};

export default ReservationList;

const CardWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: ${({ isMobile }) => (isMobile ? '0' : '0 0 120px')};
  width: 100%;
`;
