import { Styles } from '@orcar/common-d2c';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: unset;
    box-sizing: border-box;

    font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    font-style: normal;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  :root {
      font-size: 16px;
      letter-spacing: -0.015em;
  }

  html,
  body {
    max-width: 100%;
    min-height: 100vh;

    margin: 0;
    padding: 0;
  }

  a,
  a:active,
  a:link,
  a:visited {
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
  }

  button {
    background-color: unset;
    cursor: pointer;
    border: 0;
    padding: 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  ul, ol, li, a, input, select, textarea {margin: 0;padding: 0; border: 0 none; }

  ul,
  ol,
  li {
    list-style: none;
  }

  a,
  img,
  button {
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: transparent;
  }

  p {
    margin: 0;
    color: ${Styles.COLOR.GREY_01}
  }
`;

export default GlobalStyle;
