import { Fuel, VehicleModelType } from '@orcar/common';
import { RecommendationCard, Styles } from '@orcar/common-d2c';
import styled, { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import { SwiperWrapper } from '@/components/recommendSwiper';
import { type RecommendCarType } from './RecommendSection';

type TwoByTwoShapeProps = {
  recommendCarData: RecommendCarType[];
  onClick: (id: VehicleModelType | Fuel) => void;
  isMobile: boolean;
  setCurrentSlideIdx: React.Dispatch<React.SetStateAction<number>>;
};

const TwoByTwoShape = ({
  recommendCarData,
  isMobile,
  onClick,
  setCurrentSlideIdx,
}: TwoByTwoShapeProps) => {
  if (recommendCarData.length <= 4)
    return (
      <TwoByTwoShapeWrapper inner isMobile={isMobile}>
        <CardWrapper isMobile={isMobile}>
          {recommendCarData.slice(0, 4).map((car) => (
            <RecommendationCard
              key={car.id}
              platform='mobile'
              imgUrl={car.img_url}
              title={car.text}
              fixedPrice={car.price}
              membershipPrice={car.discountPrice}
              vehicleModelType={car.type}
              hasBlackBox={car.hasBlackBox}
              onClick={() => onClick(car.id)}
            />
          ))}
        </CardWrapper>
      </TwoByTwoShapeWrapper>
    );

  return (
    <TwoByTwoShapeWrapper inner isMobile={isMobile}>
      <SwiperWrapper
        slidesPerView={1}
        setCurrentSlideIdx={setCurrentSlideIdx}
        hasNavButtonBorder={false}
        navButtonRadius={undefined}
        leftNavButtonPosition={{
          top: -30,
          left: 514,
        }}
        rightNavButtonPosition={{
          top: -30,
          right: 12,
        }}
        isShownNavButtonInDisabled
        height={'430px'}
        noNavigator={isMobile}
        noBullet={isMobile}
      >
        <SwiperSlide>
          <CardWrapper isMobile={isMobile}>
            {recommendCarData.slice(0, 4).map((car) => (
              <RecommendationCard
                platform='mobile'
                imgUrl={car.img_url}
                title={car.text}
                fixedPrice={car.price}
                membershipPrice={car.discountPrice}
                vehicleModelType={car.type}
                hasBlackBox={car.hasBlackBox}
                onClick={() => onClick(car.id)}
              />
            ))}
          </CardWrapper>
        </SwiperSlide>
        {recommendCarData.length > 4 ? (
          <SwiperSlide>
            <CardWrapper isMobile={isMobile}>
              {recommendCarData
                .slice(-(recommendCarData.length - 4))
                .map((car) => (
                  <RecommendationCard
                    platform='mobile'
                    imgUrl={car.img_url}
                    title={car.text}
                    fixedPrice={car.price}
                    membershipPrice={car.discountPrice}
                    vehicleModelType={car.type}
                    hasBlackBox={car.hasBlackBox}
                    onClick={() => onClick(car.id)}
                  />
                ))}
            </CardWrapper>
          </SwiperSlide>
        ) : null}
      </SwiperWrapper>
    </TwoByTwoShapeWrapper>
  );
};

export default TwoByTwoShape;

const CardWrapper = styled.div<{
  isMobile?: boolean;
}>`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 7px;
      margin-top: 30px;
    `}
`;

const TwoByTwoShapeWrapper = styled.div<{
  inner?: boolean;
  isMobile?: boolean;
}>`
  display: flex;
  justify-content: center;
  width: 100%;
  ${({ inner, isMobile }) =>
    inner &&
    isMobile &&
    css`
      max-width: ${`calc(${Styles.WIDTH.MOBILE_LAYOUT} - 40px)`};
    `}
`;
