import {
  authAtom,
  BREAK_TIME,
  COMPANY_NAME,
  CS_NUMBER,
  getPrimaryColor,
  Icon,
  isMobileAtom,
  LOCAL_STORAGE_KEY,
  LoginModal,
  NAV_LINK_LIST,
  OPERATING_HOURS,
  PAGE_NAME,
  SESSION_STORAGE_KEY,
  SideMenuBtn,
  Styles,
  useRecoil,
} from '@orcar/common-d2c';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ColorLogo from '@/assets/img/logo_rainbow-color-v2.svg';
import WhiteLogo from '@/assets/img/logo_rainbow-white-v2.svg';
import { APP_NAME } from '@/constants/common';
import Drawer from './Drawer';
import InfoConfirmModal from './InfoConfirmModal';
import * as S from './styles';

const LOGO_WIDTH = 150;
const LOGO_WIDTH_MOBILE = 173;

const CommonHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { recoilValue: isMobile } = useRecoil(isMobileAtom);
  const {
    recoilState: [user, setUser],
  } = useRecoil(authAtom);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const isHomePage = pathname === PAGE_NAME.index;
  const isSearchPage = pathname === PAGE_NAME.search;

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [pathname]);

  const handleLogin = () => {
    setIsLoginModalOpen(true);
  };

  const handleLogout = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    sessionStorage.removeItem(SESSION_STORAGE_KEY.PRE_RESERVATION_ID);
    setUser(null);
    navigate(PAGE_NAME.index);
  };

  return (
    <>
      {isDrawerOpen && isMobile && (
        <Drawer
          onClose={() => setIsDrawerOpen(false)}
          isDrawerOpen={isDrawerOpen}
          setIsModalOpen={setIsModalOpen}
          handleLogin={handleLogin}
          handleLogout={handleLogout}
        />
      )}
      {isModalOpen && (
        <InfoConfirmModal
          onClose={() => setIsModalOpen(false)}
          title={COMPANY_NAME.RAINBOW}
          callNumber={CS_NUMBER.RAINBOW}
        >
          운영시간: {OPERATING_HOURS.RAINBOW} <br />
          점심시간: {BREAK_TIME.RAINBOW}
        </InfoConfirmModal>
      )}
      <S.OuterWrapper
        $isHomePage={isHomePage}
        $isMobile={isMobile}
        hasShadow={(!isSearchPage || !isMobile) && !isHomePage}
      >
        {isMobile && (
          <S.IconWrapper>
            <Icon
              src={SideMenuBtn}
              width={24}
              height={24}
              iconcolor={
                isHomePage ? Styles.SVG_FILTER.WHITE : Styles.SVG_FILTER.GREY_01
              }
              onClick={() => setIsDrawerOpen(true)}
            />
          </S.IconWrapper>
        )}
        <S.InnerWrapper $isMobile={isMobile}>
          <S.NavLink
            $logo
            tabIndex={1}
            onClick={() => {
              navigate(PAGE_NAME.index);
            }}
          >
            <img
              src={isMobile && isHomePage ? WhiteLogo : ColorLogo}
              alt={`${COMPANY_NAME.RAINBOW} 로고`}
              width={isMobile ? LOGO_WIDTH_MOBILE : LOGO_WIDTH}
            />
          </S.NavLink>
          {!isMobile && (
            <S.Gnb>
              <S.GnbPart>
                {NAV_LINK_LIST.map((nav, idx) =>
                  !!nav.moveTo ? (
                    <S.NavLink
                      key={idx}
                      tabIndex={1}
                      onClick={() => {
                        navigate(nav.moveTo);
                      }}
                    >
                      {nav.title}
                    </S.NavLink>
                  ) : (
                    <S.NavLink
                      key={idx}
                      tabIndex={1}
                      onClick={() => setIsModalOpen(true)}
                    >
                      {nav.title}
                    </S.NavLink>
                  ),
                )}
                {!!user ? (
                  <S.NavLink
                    tabIndex={1}
                    onClick={() => navigate(PAGE_NAME.my_page)}
                  >
                    마이페이지
                  </S.NavLink>
                ) : (
                  <S.NavLink
                    tabIndex={1}
                    onClick={() => navigate(PAGE_NAME.find_reservation)}
                  >
                    비회원 예약 조회
                  </S.NavLink>
                )}
                {!!user ? (
                  <S.NavLink
                    tabIndex={1}
                    color={getPrimaryColor(APP_NAME)}
                    onClick={handleLogout}
                  >
                    로그아웃
                  </S.NavLink>
                ) : (
                  <S.NavLink
                    tabIndex={1}
                    color={getPrimaryColor(APP_NAME)}
                    onClick={handleLogin}
                  >
                    로그인
                  </S.NavLink>
                )}
              </S.GnbPart>
            </S.Gnb>
          )}
        </S.InnerWrapper>
      </S.OuterWrapper>
      <LoginModal
        platform={isMobile ? 'mobile' : 'desktop'}
        isOpen={isLoginModalOpen}
        originUrl={import.meta.env.VITE_ORIGIN_URL}
        onClose={() => {
          setIsLoginModalOpen(false);
        }}
      />
    </>
  );
};

export default CommonHeader;
