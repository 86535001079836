import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Icon, LeftIcon, RightIcon, Styles } from '@orcar/common-d2c';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper } from 'swiper/react';
import { type SwiperStyleProps } from './styles';
import * as S from './styles';

type BreakpointsType = {
  [key: number]: UnitBreakpointType;
};

export type UnitBreakpointType = Pick<
  SwiperProps,
  'slidesPerView' | 'spaceBetween'
>;

export interface SwiperProps
  extends Omit<SwiperStyleProps, 'arrow' | 'clientWidth'> {
  title?: string;
  slidesPerView: number; // 한 swiper에 표시될 slide 갯수
  spaceBetween?: number; // slide 사이 간격
  children: React.ReactNode;
  isAutoPlay?: boolean;
  isMobile?: boolean;
  isSumcarRecommendCar?: boolean;
  noNavigator?: boolean; // 화살표 버튼이 없는 경우
  currentSlideIdx?: number;
  arrowWidth?: number;
  arrowHeight?: number;
  breakpoints?: BreakpointsType;
  setCurrentSlideIdx?: Dispatch<SetStateAction<number>>;
  setSlidesLength?: Dispatch<SetStateAction<number>>;
}

const SwiperWrapper = ({
  title,
  noBullet,
  noNavigator,
  width,
  height,
  mini,
  slidesPerView,
  spaceBetween,
  children,
  arrowWidth,
  arrowHeight,
  leftNavButtonPosition,
  rightNavButtonPosition,
  navButtonRadius,
  hasNavButtonBorder,
  hasNavButtonShadow,
  isAutoPlay,
  isBanner,
  isMobile,
  isShownNavButtonInDisabled,
  breakpoints,
  currentSlideIdx,
  setCurrentSlideIdx,
}: SwiperProps) => {
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  const [clientWidth, setClientWidth] = useState(0);

  useEffect(() => {
    setClientWidth(document.documentElement.clientWidth);

    const handleClientWidth = () => {
      setClientWidth(document.documentElement.clientWidth);

      return;
    };

    window.addEventListener('resize', handleClientWidth);

    return () => window.removeEventListener('resize', handleClientWidth);
  }, []);

  return (
    <S.SwiperContainer
      width={width}
      height={height}
      noBullet={noBullet}
      isShownNavButtonInDisabled={isShownNavButtonInDisabled}
    >
      {isBanner && (
        <S.Pagination
          isMobile={isMobile}
          clientWidth={clientWidth}
          currentSlideIdx={currentSlideIdx}
        >
          {`${currentSlideIdx! + 1} / ${React.Children.count(children)}`}
        </S.Pagination>
      )}
      {!noNavigator && (
        <S.NavButton
          className={`swiper-nav-button.${title}`}
          ref={prevRef}
          arrow='left'
          mini={mini}
          height={height}
          leftNavButtonPosition={leftNavButtonPosition}
          rightNavButtonPosition={rightNavButtonPosition}
          navButtonRadius={navButtonRadius}
          hasNavButtonBorder={hasNavButtonBorder}
          hasNavButtonShadow={hasNavButtonShadow}
          isBanner={isBanner && !isMobile}
          clientWidth={clientWidth}
          currentSlideIdx={currentSlideIdx}
          noBullet={noBullet}
        >
          <Icon
            src={LeftIcon}
            width={arrowWidth ? arrowWidth : 6}
            height={arrowHeight ? arrowHeight : 10}
            margin='0 2px 0 0'
            iconcolor={
              isBanner ? Styles.SVG_FILTER.WHITE : Styles.SVG_FILTER.GREY_01
            }
          />
        </S.NavButton>
      )}

      <Swiper
        pagination={{
          clickable: true,
        }}
        touchRatio={1}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        autoplay={
          isAutoPlay
            ? {
                delay: isAutoPlay ? 6000 : undefined,
                disableOnInteraction: isAutoPlay && false,
              }
            : undefined
        }
        loop={isAutoPlay && true}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        onSlideChange={(e: any) => {
          setCurrentSlideIdx?.(e.realIndex);
        }}
        onInit={(swiper: any) => {
          if (typeof swiper.params.navigation !== 'boolean') {
            if (swiper.params.navigation) {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
            }
          }
          swiper.navigation?.update();
          if (isAutoPlay) {
            // swiper.autoplay.paused = false;
            swiper.autoplay.running = true;
            setCurrentSlideIdx?.(0);
          } else return;
        }}
        breakpoints={breakpoints}
      >
        {children}
      </Swiper>

      {!noNavigator && (
        <S.NavButton
          className={`swiper-nav-button.${title}`}
          ref={nextRef}
          arrow='right'
          mini={mini}
          height={height}
          leftNavButtonPosition={leftNavButtonPosition}
          rightNavButtonPosition={rightNavButtonPosition}
          navButtonRadius={navButtonRadius}
          hasNavButtonBorder={hasNavButtonBorder}
          hasNavButtonShadow={hasNavButtonShadow}
          isBanner={isBanner && !isMobile}
          clientWidth={clientWidth}
          currentSlideIdx={currentSlideIdx}
          noBullet={noBullet}
        >
          <Icon
            src={RightIcon}
            width={arrowWidth ? arrowWidth : 6}
            height={arrowHeight ? arrowHeight : 10}
            margin='0 0 0 2px'
            iconcolor={
              isBanner ? Styles.SVG_FILTER.WHITE : Styles.SVG_FILTER.GREY_01
            }
          />
        </S.NavButton>
      )}
    </S.SwiperContainer>
  );
};

export { SwiperWrapper };
