import styled, { css } from 'styled-components';

export const PaddingWrapper = styled.div<{
  isMobile?: boolean;
}>`
  padding: ${({ isMobile }) => (isMobile ? '0 20px' : '0 33px')};
`;

export const PaymentWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => isMobile && 'column'};
  gap: ${({ isMobile }) => !isMobile && '24px'};
`;

export const PaymentInfoWrapper = styled.div`
  position: sticky;
  display: flex;
  top: 113px;
  flex-direction: column;
  width: 588px;
  height: fit-content;
  padding: 36px 78px;
  border: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  border-radius: 10px;
`;

export const PolicyLinkSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div<{
  isMobile?: boolean;
  screenHeight?: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 104px 0 100px;

  ${({ isMobile, screenHeight }) =>
    isMobile &&
    css`
      height: ${screenHeight}px;
      margin: 0;
    `}
`;

export const Text = styled.p<{
  type: 'main' | 'sub' | 'number';
  isMobile?: boolean;
}>`
  ${({ type, theme }) =>
    type === 'main'
      ? theme.FONT.H1
      : type === 'sub'
      ? theme.FONT.H4
      : theme.FONT.B1};
  margin: ${({ type }) =>
    type === 'main' ? '0 0 124px' : type === 'sub' && '0 0 6px'};
  text-align: center;
  word-break: keep-all;

  ${({ isMobile, type, theme }) =>
    isMobile &&
    css`
      ${type === 'main' && theme.FONT.H3};
      margin: ${type === 'main' && '0 0 22px'};
    `}
`;

export const ButtonWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 102px;
  margin-top: 137px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: fixed;
      align-items: center;
      bottom: 20px;
      left: 50%;
      flex-direction: column;
      gap: 8px;
      transform: translateX(-50%);
    `}
`;
