import { getPrimaryColor } from '@orcar/common-d2c';
import styled, { css } from 'styled-components';
import { APP_NAME } from '@/constants/common';

export const Wrapper = styled.footer`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: ${({ theme }) => theme.WIDTH.PC_LAYOUT};
  height: fit-content;
  border-top: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
`;

export const InnerWrapper = styled.div<{
  bottom?: boolean;
  isMobile?: boolean;
}>`
  display: flex;
  justify-content: ${({ bottom }) => (bottom ? 'flex-start' : 'center')};
  width: ${({ isMobile, theme }) =>
    isMobile ? theme.WIDTH.MOBILE_LAYOUT : theme.WIDTH.PC_LAYOUT};
  max-width: ${({ isMobile, theme }) =>
    isMobile ? theme.WIDTH.MOBILE_LAYOUT : theme.WIDTH.PC_LAYOUT};

  ${({ isMobile, bottom }) =>
    isMobile &&
    css`
      justify-content: ${bottom && 'center'};
    `}
`;

export const CsSectionWrapper = styled.div<{ isMobile?: boolean }>`
  padding: ${({ isMobile }) => isMobile && '30px 0'};
`;

export const CompanyInfoSectionWrapper = styled.div`
  margin-top: 40px;
`;

export const TopSection = styled.div`
  display: flex;
  width: 100%;
  padding: 38px 0 30px;
`;

export const BottomSection = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 85px;
  margin-top: ${({ isMobile }) => isMobile && '30px'};
  border-top: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
`;

export const RightSection = styled.div`
  display: flex;
  width: 50%;
  padding-left: 70px;
`;

export const NavLinkWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => isMobile && 'column'};
  justify-content: flex-start;
  margin-top: ${({ isMobile }) => isMobile && '35px'};
  gap: 20px;
`;

export const NavLink = styled.a<{ sub?: boolean; isMobile?: boolean }>`
  height: fit-content;
  margin-left: ${({ sub }) => sub && `20px`};
  ${({ isMobile, theme }) => (isMobile ? theme.FONT.B2 : theme.FONT.B1)};
  font-weight: ${({ sub }) => (sub ? 400 : 600)};
  color: ${({ sub, theme }) =>
    sub ? getPrimaryColor(APP_NAME) : theme.COLOR.GREY_01};
  cursor: pointer;
`;

export const InfoContent = styled.p<{
  margin?: string;
  color?: string;
  font?: Record<string, string>;
}>`
  margin: ${({ margin }) => margin};
  ${({ font, theme }) => (font ? font : theme.FONT.B3)};
  font-weight: ${({ font, theme }) =>
    font === theme.FONT.B1 ? 600 : theme.FONT.B2 && 500};
  color: ${({ color, theme }) => (color ? color : theme.COLOR.GREY_02)};
`;

export const InfoLink = styled.a<{ icon?: boolean }>`
  ${({ theme }) => theme.FONT.B2};
  color: ${({ theme }) => theme.COLOR.GREY_01};
  cursor: pointer;

  ${({ icon }) =>
    icon &&
    css`
      &::after {
        margin-left: 10px;
        background: url('/ic_arrow_right.svg') no-repeat 0 0;
        content: '';
        display: inline-block;
        width: 6px;
        height: 10px;
      }
    `}
`;

export const NoticeSection = styled.div`
  width: 208px;
  padding: 0 10px 10px;
`;

export const NoticeTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
`;

export const ListWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;
