import { ListStyle } from '@orcar/common-d2c';
import styled, { css } from 'styled-components';

export const LayoutWrapper = styled.div<{
  isMobile: boolean;
  isPayment?: boolean;
}>`
  display: flex;
  flex-direction: ${({ isMobile }) => isMobile && 'column'};
  gap: 24px;

  ${({ isMobile, isPayment }) =>
    isMobile &&
    css`
      padding: ${isPayment ? '40px 0 0' : '40px 0 54px'};
      flex-direction: ${isPayment && 'column'};
      gap: ${isPayment && '0'};
    `}
`;

export const ContentWrapper = styled.div<{
  hasPadding?: boolean;
  isMobile?: boolean;
}>`
  width: 100%;
  padding: ${({ hasPadding }) => hasPadding && '30px 0'};
  scroll-margin: ${({ isMobile }) => (isMobile ? '110px' : '150px')};
`;

export const WhiteBgWrapper = styled.div<{
  isMobile: boolean;
}>`
  width: 100%;
  gap: 30px;
  padding: ${({ isMobile }) => (isMobile ? '0 20px' : '0 30px')};
  margin-bottom: ${({ isMobile }) => (isMobile ? '10px' : '24px')};
  background-color: ${({ theme }) => theme.COLOR.WHITE};
  background-color: white;
  border-radius: ${({ theme }) => theme.BORDER_RADIUS.LEVEL_02};

  & > *:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  }

  & li {
    ${ListStyle}
  }
`;

export const PaymentInfoWrapper = styled.div`
  position: sticky;
  display: flex;
  top: ${({ theme }) => `calc(${theme.HEIGHT.PC_HEADER} + 24px)`};
  flex-direction: column;
  min-width: 384px;
  height: fit-content;
  padding: 30px;
  border: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  box-shadow: ${({ theme }) => theme.BOX_SHADOW.LEVEL_01};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.COLOR.WHITE};
`;

export const SectionTitle = styled.p<{
  isMobile: boolean;
  isPageTitle?: boolean;
  isPayment?: boolean;
  margin?: string;
}>`
  ${({ margin }) => (margin ? `margin: ${margin}` : 'margin-bottom: 20px')};
  ${({ isMobile, theme }) =>
    isMobile ? theme.NEW_FONT.H5 : theme.NEW_FONT.H4};
  font-weight: 700;

  ${({ isPageTitle, theme, isMobile, isPayment }) =>
    isPageTitle &&
    css`
      ${isMobile ? theme.NEW_FONT.H4 : theme.NEW_FONT.H3};
      padding: ${isPayment && '0 20px'};
    `}
`;

export const FlexColWrapper = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`;

export const InfoTitle = styled.p`
  min-width: 110px;
  ${({ theme }) => theme.FONT.B1};
  font-weight: 600;
  line-height: 27px;
`;

export const InfoText = styled.p`
  width: fit-content;
  ${({ theme }) => theme.FONT.B1};
  word-break: keep-all;
`;

export const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2px;
  line-height: 27px;
`;

export const AreaTitle = styled.p<{ isMobile: boolean }>`
  ${({ isMobile, theme }) =>
    isMobile ? theme.NEW_FONT.H5 : theme.NEW_FONT.H4};
`;
