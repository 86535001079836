import { Button, PAGE_NAME, Styles, useDetectMobile } from '@orcar/common-d2c';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import * as S from '../styles';

const PaymentFailPage = () => {
  const navigate = useNavigate();
  const { isMobile, screenHeight } = useDetectMobile();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const message = searchParams.get('message');

  return (
    <S.Wrapper screenHeight={screenHeight - 80} isMobile={isMobile}>
      <div>
        <Title
          isMobile={isMobile}
        >{`결제에 실패했습니다.\n 다시 시도해주세요.`}</Title>
        <MainMessage isMobile={isMobile}>{code}</MainMessage>
        <SubMessage isMobile={isMobile}>{message}</SubMessage>
      </div>
      <S.ButtonWrapper isMobile={isMobile}>
        <Button
          borderRadius='50px'
          font={Styles.FONT.H4}
          width={isMobile ? '90%' : '384px'}
          height='59px'
          onClick={() => navigate(`${PAGE_NAME.index}`)}
        >
          홈으로 돌아가기
        </Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default PaymentFailPage;

const Title = styled.p<{ isMobile?: boolean }>`
  ${({ theme, isMobile }) => (isMobile ? theme.FONT.H3 : theme.FONT.H2)};
  white-space: pre-line;
  text-align: center;
  margin-bottom: ${({ isMobile }) => (isMobile ? '32px' : '124px')};
`;

const MainMessage = styled.p<{ isMobile?: boolean }>`
  ${({ theme, isMobile }) => (isMobile ? theme.FONT.H4 : theme.FONT.H5)};
  text-align: center;
`;

const SubMessage = styled.p<{ isMobile?: boolean }>`
  ${({ theme, isMobile }) => (isMobile ? theme.FONT.B2 : theme.FONT.B1)};
  text-align: center;
`;
