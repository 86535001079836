import {
  Button,
  ClockIcon,
  Icon,
  Modal,
  QUERY_KEY,
  Styles,
} from '@orcar/common-d2c';
import { useQueryClient } from '@tanstack/react-query';
import styled, { css } from 'styled-components';

type TimeoutModalProps = {
  onClose: () => void;
  isMobile?: boolean;
};

const TimeoutModal = ({ onClose, isMobile }: TimeoutModalProps) => {
  const queryClient = useQueryClient();

  const handleResearch = () => {
    queryClient.resetQueries({
      queryKey: [QUERY_KEY.GET_VEHICLE_MODELS],
    });
    onClose();
  };
  return (
    <Modal
      onClose={onClose}
      paddingHorizontal={isMobile ? 50 : 85}
      paddingVertical={isMobile ? 20 : 32}
      topLevel
    >
      <Wrapper>
        <Icon
          src={ClockIcon}
          width={50}
          height={50}
          iconcolor={Styles.SVG_FILTER.GREY_01}
          margin={isMobile ? '10px 0 25px' : '0 0 40px'}
        />
        <TextWrapper>
          <Text>앗! 차량 검색 시간이 초과되었어요.</Text>
          <Text sub>확인을 누르시면 차량을 재검색합니다.</Text>
        </TextWrapper>
        <Button
          width='150px'
          height='33px'
          font={Styles.FONT.B1}
          onClick={handleResearch}
        >
          확인
        </Button>
      </Wrapper>
    </Modal>
  );
};

export default TimeoutModal;

const Wrapper = styled.div`
  display: grid;
  justify-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 215px;
`;

const Text = styled.p<{ sub?: boolean }>`
  ${({ theme }) => theme.FONT.B1};
  font-weight: 600;

  ${({ sub }) =>
    sub &&
    css`
      ${({ theme }) => theme.FONT.B3};
      margin: 10px 0 40px;
    `}
`;
