import { MobileProps } from '@orcar/common-d2c';
import styled, { css } from 'styled-components';

type IconWrapperStyledType = {
  isLeft: boolean;
  disabled?: boolean;
};

export const Wrapper = styled.div<MobileProps>`
  position: relative;
  display: flex;
  flex-direction: ${({ isMobile }) => isMobile && 'column'};
  gap: ${({ isMobile }) => !isMobile && '80px'};
  width: fit-content;
`;

export const IconWrapper = styled.div<IconWrapperStyledType>`
  position: absolute;
  padding: 0 5px;

  ${({ isLeft }) =>
    isLeft
      ? css`
          left: 5px;
        `
      : css`
          right: 5px;
        `};
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0;
        `
      : css`
          cursor: pointer;
        `};
`;

export const ArrowIcon = styled('img')`
  ${({ theme }) => theme.SVG_FILTER.GREY_01};
`;

export const Text = styled.p`
  position: absolute;
  bottom: -45px;
  font-size: 15px;
  font-weight: 300;
  line-height: 25px;
  color: ${({ theme }) => theme.COLOR.DANGER};
`;
