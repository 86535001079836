import { PAGE_NAME } from '@orcar/common-d2c';
import { Route, Routes } from 'react-router-dom';
import PolicyPage from '@/pages//PolicyPage';
import FindReservationPage from '@/pages/findReservation/FindReservationPage';
import HomePage from '@/pages/home/HomePage';
import KakaoLoginRedirectPage from '@/pages/login/kakao/KakaoLoginRedirectPage';
import NaverLoginRedirectPage from '@/pages/login/naver/NaverLoginRedirectPage';
import MyPage from '@/pages/mypage/Mypage';
import ReservationDetailPage from '@/pages/mypage/reservation/ReservationDetailPage';
import NotFound from '@/pages/NotFound';
import RentInfoPage from '@/pages/rentInfo/RentInfoPage';
import OrderConfirmationPage from '@/pages/reservation/orderConfirmation/OrderConfirmationPage';
import ConfirmPaymentPage from '@/pages/reservation/payment/confirm/ConfirmPaymentPage';
import PaymentFailPage from '@/pages/reservation/payment/fail/PaymentFailPage';
import PaymentPage from '@/pages/reservation/payment/PaymentPage';
import RequestPaymentPage from '@/pages/reservation/payment/request/RequestPaymentPage';
import SearchPage from '@/pages/search/SearchPage';

const App = () => {
  return (
    <Routes>
      <Route path={PAGE_NAME.index} element={<HomePage />} />
      <Route path={PAGE_NAME.search} element={<SearchPage />} />
      <Route
        path={PAGE_NAME.order_confirmation}
        element={<OrderConfirmationPage />}
      />

      <Route path={PAGE_NAME.payment} element={<PaymentPage />} />
      <Route
        path={PAGE_NAME.request_payment}
        element={<RequestPaymentPage />}
      />
      <Route
        path={PAGE_NAME.confirm_payment}
        element={<ConfirmPaymentPage />}
      />
      <Route path={PAGE_NAME.payment_fail} element={<PaymentFailPage />} />
      <Route path={PAGE_NAME.rent_info} element={<RentInfoPage />} />
      <Route
        path={PAGE_NAME.find_reservation}
        element={<FindReservationPage />}
      />
      <Route path={PAGE_NAME.my_page} element={<MyPage />} />
      <Route
        path={PAGE_NAME.reservation_detail + '/:reservationId'}
        element={<ReservationDetailPage />}
      />
      <Route path={PAGE_NAME.policy} element={<PolicyPage />} />
      <Route
        path={PAGE_NAME.login + '/kakao'}
        element={<KakaoLoginRedirectPage />}
      />
      <Route
        path={PAGE_NAME.login + '/naver'}
        element={<NaverLoginRedirectPage />}
      />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default App;
