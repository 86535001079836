import {
  Button,
  filterOptionAtom,
  Styles,
  TOAST_MESSAGE_TYPE,
  useDetectMobile,
  useRecoil,
  useToast,
} from '@orcar/common-d2c';
import * as S from './styles';

const SearchFilterNull = () => {
  const { isMobile } = useDetectMobile();
  const { addToast } = useToast();

  const { resetRecoilState: resetRecoilFilterOption } =
    useRecoil(filterOptionAtom);

  const handleResetFiltering = () => {
    resetRecoilFilterOption();
    addToast({ content: TOAST_MESSAGE_TYPE.RESET_FILTER });
  };

  return (
    <S.SearchNullWrapper isMobile={isMobile}>
      <S.SearchNullMessageText isMobile={isMobile} isFilter>
        설정하신 필터에 맞는 차량이 없어요.
      </S.SearchNullMessageText>
      <S.SearchNullMessageText sub isMobile={isMobile}>
        필터 조건을 변경하거나, 초기화해 주세요.
      </S.SearchNullMessageText>
      <S.SearchNullButtonWrapper isMobile={isMobile}>
        <Button
          width={isMobile ? '100%' : '282px'}
          height={isMobile ? '60px' : '43px'}
          font={isMobile ? Styles.FONT.H4 : Styles.FONT.B1}
          onClick={handleResetFiltering}
          borderRadius='50px'
        >
          필터 초기화하기
        </Button>
      </S.SearchNullButtonWrapper>
    </S.SearchNullWrapper>
  );
};

export default SearchFilterNull;
