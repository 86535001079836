import { PreReservationDto } from '@orcar/common';
import {
  ALERT_MESSAGE_TYPE,
  Button,
  getPreReservation,
  PAGE_NAME,
  SESSION_STORAGE_KEY,
  Styles,
  useDetectMobile,
  useGetCompanyInfo,
} from '@orcar/common-d2c';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { InfoConfirmModal } from '@/components/header';
import { CAR_SHEET_COMPANY } from '@/constants/common';
import * as S from '../styles';

const ConfirmPaymentPage = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isMobile, screenHeight } = useDetectMobile();
  const { data: companyData } = useGetCompanyInfo();

  const preReservationId = sessionStorage.getItem(
    SESSION_STORAGE_KEY.PRE_RESERVATION_ID,
  );
  const [preReservationData, setPreReservationData] =
    useState<PreReservationDto>();

  const handleConfirmOrder = () => {
    navigate(
      `${PAGE_NAME.reservation_detail}/${preReservationData?.reservation?.id}`,
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!preReservationId) {
      alert(ALERT_MESSAGE_TYPE.NOT_ALLOWED_ACCESS);
      navigate(PAGE_NAME.index);
      return;
    }

    getPreReservation(preReservationId)
      .then((res) => {
        if (!res.reservation) {
          alert(ALERT_MESSAGE_TYPE.INVALID_RESERVATION);
          navigate(PAGE_NAME.index);
          return;
        }
        setPreReservationData(res);
      })
      .catch(() => {
        alert(ALERT_MESSAGE_TYPE.INVALID_RESERVATION);
        navigate(PAGE_NAME.index);
        return;
      });
  }, [preReservationId]);

  if (!preReservationData || !preReservationData?.reservation) return null;

  return (
    <Wrapper isMobile={isMobile} screenHeight={screenHeight - 148}>
      <S.Text type='main' isMobile={isMobile}>
        예약이 완료되었습니다
      </S.Text>
      <S.Text type='sub' isMobile={isMobile}>
        {`${preReservationData.customerName}님,`}
      </S.Text>
      <S.Text type='sub' isMobile={isMobile}>
        예약 확정을 위해 영업 시간 중에 {companyData?.name}에서 전화드릴
        예정입니다.
      </S.Text>
      <S.Text type='number' isMobile={isMobile}>
        주문 번호: {preReservationId}
      </S.Text>
      <S.ButtonWrapper isMobile={isMobile}>
        <Button
          borderRadius='50px'
          font={Styles.FONT.H4}
          width={isMobile ? '90%' : '384px'}
          height='59px'
          bgColor={Styles.COLOR.WHITE}
          color={Styles.COLOR.GREY_02}
          borderColor={Styles.COLOR.GREY_02}
          onClick={() => setIsModalOpen(true)}
        >
          유모차/카시트 대여 안내
        </Button>
        <Button
          borderRadius='50px'
          font={Styles.FONT.H4}
          width={isMobile ? '90%' : '384px'}
          height='59px'
          onClick={handleConfirmOrder}
        >
          주문 확인하기
        </Button>
      </S.ButtonWrapper>
      {isModalOpen && (
        <InfoConfirmModal
          onClose={() => setIsModalOpen(false)}
          callNumber={`${preReservationData.companyName} 한정 카시트 및 유모차 대여 서비스`}
        >
          <OuterWrapper>
            <Text>저렴한 가격으로 편리하게 이용하세요!</Text>

            <InnerWrapper margin='12px 0 0'>
              <Text fontWeight={600} margin='0 0 4px 0'>
                꾸러기 유모차 예약 방법:
              </Text>
              <Text>
                전화 예약:{' '}
                <Link
                  href={`tel:+82${CAR_SHEET_COMPANY.tel.replace(/-/g, '')}`}
                >
                  {CAR_SHEET_COMPANY.tel}
                </Link>
              </Text>
              <Text>
                온라인 예약:{' '}
                <Link
                  href={CAR_SHEET_COMPANY.url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {CAR_SHEET_COMPANY.url}
                </Link>
              </Text>
            </InnerWrapper>
            <InnerWrapper>
              <Text fontWeight={600} margin='0 0 4px 0'>
                {`${preReservationData.companyName} 고객 우대 혜택:`}
              </Text>
              <Text>사전 장착 서비스 무료 제공</Text>
              <Text>반납 시 차량 내에 그대로 두시면 직접 수거</Text>
            </InnerWrapper>
          </OuterWrapper>{' '}
        </InfoConfirmModal>
      )}
    </Wrapper>
  );
};

export default ConfirmPaymentPage;

const Wrapper = styled.div<{
  isMobile?: boolean;
  screenHeight?: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 104px 0 100px;

  ${({ isMobile, screenHeight }) =>
    isMobile &&
    css`
      height: ${screenHeight}px;
      margin: 0;
    `}
`;

const Link = styled.a`
  color: ${Styles.COLOR.BLUE};
  text-decoration: underline;
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InnerWrapper = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  ${({ margin }) => margin && `margin: ${margin};`}
`;

const Text = styled.p<{
  margin?: string;
  fontSize?: number;
  fontWeight?: number;
  color?: string;
}>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 14)}px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  color: ${({ color }) => color || Styles.COLOR.GREY_01};
  ${({ margin }) => margin && `margin: ${margin};`}
`;
