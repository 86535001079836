import {
  getSureUserId,
  isMobileAtom,
  PAGE_NAME,
  removeSureUserId,
  storeSureUserId,
  Styles,
  SureTag,
  ToastV2,
  toastV2StateAtom,
  useDetectMobile,
  useRecoil,
  useValidateSureDiscount,
} from '@orcar/common-d2c';
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import MyPageWrapper from './MyPageWrapper';
import Footer from '../footer';
import MobileFooter from '../footer/components/MobileFooter';
import { CommonHeader } from '../header';

type LayoutProps = {
  children: React.ReactNode;
  isSearchPage?: boolean;
};

const companyId = import.meta.env.VITE_COMPANY_ID;

const Layout = ({ children }: LayoutProps) => {
  const [searchParams] = useSearchParams();
  const sureUserIdParam = Number(searchParams.get('sureUserId'));
  const storedSureUserId = getSureUserId();

  const { pathname } = useLocation();
  const { isMobile } = useDetectMobile();

  const { setRecoilState: setIsMobile } = useRecoil(isMobileAtom);
  const {
    recoilState: [isSureToastOpen, setIsSureToastOpen],
  } = useRecoil(toastV2StateAtom);

  const isHomePage = pathname === PAGE_NAME.index;
  const isSearchPage = pathname === PAGE_NAME.search;
  const isMyPage = pathname.includes(PAGE_NAME.my_page);
  const isOrderPage = pathname === PAGE_NAME.order_confirmation;
  const isPaymentPage = pathname === PAGE_NAME.payment;

  const { data: validateSureDiscountData } = useValidateSureDiscount({
    params: { sureUserId: sureUserIdParam },
    enabled: isHomePage,
  });

  useEffect(() => {
    setIsMobile(isMobile);
  }, [isMobile]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!!validateSureDiscountData) {
      const canApplySureDiscount = !!validateSureDiscountData.find(
        (item) => item.companyId === Number(companyId),
      );

      if (canApplySureDiscount) {
        storeSureUserId(sureUserIdParam.toString());
        handleShowSureDiscountToast(sureUserIdParam.toString());
      } else {
        removeSureUserId();
        handleShowSureDiscountToast(null);
      }
    }
  }, [validateSureDiscountData]);

  useEffect(() => {
    handleShowSureDiscountToast(storedSureUserId);
  }, [isHomePage, isSearchPage, storedSureUserId]);

  const handleShowSureDiscountToast = (storedSureUserId: string | null) => {
    if ((isHomePage || isSearchPage) && !!storedSureUserId) {
      setIsSureToastOpen(true);
    } else {
      setIsSureToastOpen(false);
    }
  };

  return (
    <LayoutContainer
      backgroundColor={
        isSearchPage || isMyPage || isOrderPage || isPaymentPage
          ? Styles.COLOR.GREY_BG
          : Styles.COLOR.WHITE
      }
    >
      <CommonHeader />
      {(isHomePage || isSearchPage) && (
        <ToastV2
          open={isSureToastOpen}
          onClose={() => setIsSureToastOpen(false)}
          type={isMobile ? 'mobile' : 'desktop'}
          text='할인이 적용된 가격이에요!'
          Icon={<SureTag />}
        />
      )}
      <MainWrapper
        isMobile={isMobile}
        isHomePage={isHomePage}
        backgroundColor={
          isSearchPage || isMyPage || isOrderPage || isPaymentPage
            ? Styles.COLOR.GREY_BG
            : Styles.COLOR.WHITE
        }
      >
        <MyPageWrapper isMobile={isMobile}>{children}</MyPageWrapper>
      </MainWrapper>
      {isMobile && isHomePage && <MobileFooter />}
      {!isMobile && <Footer />}
    </LayoutContainer>
  );
};

export default Layout;

const LayoutContainer = styled.div<{
  backgroundColor?: string;
}>`
  position: relative;
  width: 100%;
  min-width: ${({ theme }) => theme.WIDTH.MOBILE_LAYOUT};
  min-height: 100vh;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.COLOR.WHITE};
`;

const MainWrapper = styled.main<{
  isMobile: boolean;
  isHomePage: boolean;
  backgroundColor?: string;
}>`
  margin: 0 auto;
  width: ${({ isMobile }) => !isMobile && 'fit-content'};
  padding-top: ${({ isHomePage, theme }) =>
    isHomePage ? '0' : `calc(${theme.HEIGHT.PC_HEADER} + 44px)`};
  padding-bottom: ${({ theme }) => `calc(${theme.HEIGHT.FOOTER} + 60px)`};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.COLOR.WHITE};

  ${({ isMobile, isHomePage, theme }) =>
    isMobile &&
    css`
      padding-top: ${isHomePage ? '0' : theme.HEIGHT.MOBILE_HEADER};
      padding-bottom: 0;
    `};
`;
