import { getPrimaryColor, getPrimaryOpaqueColor } from '@orcar/common-d2c';
import styled, { css } from 'styled-components';
import { APP_NAME } from '@/constants/common';

export const LayoutWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isMobile }) => (isMobile ? '300px' : '922px')};
  padding-top: ${({ isMobile }) => isMobile && '50px'};
`;

export const Section = styled.section<{
  border?: boolean;
  sub?: boolean;
}>`
  width: 100%;
  margin-top: 80px;
  ${({ sub, theme }) => sub && theme.FONT.B2};
  ${({ border, theme }) =>
    border &&
    css`
      border: 1px solid ${theme.COLOR.GREY_05};
      padding: 30px;
      margin: 50px 0 50px;
      font-weight: 500;
    `}
`;

export const ImageSection = styled.div`
  margin-top: 24px;
`;

export const Title = styled.p<{
  type?: 'page' | 'section' | 'reservation' | 'subNum';
  isColor?: boolean;
}>`
  margin-bottom: ${({ type, isColor }) => {
    if (type) {
      if (type === 'reservation') return '43px';
      else return '16px';
    } else {
      if (isColor) return '12px';
      else return '10px';
    }
  }};
  margin-top: ${({ type }) => type === 'subNum' && '34px'};
  ${({ type, theme }) => {
    switch (type) {
      case 'page':
        return theme.FONT.H3;
      case 'section':
        return theme.FONT.H4;
      case 'reservation':
        return theme.FONT.H5;
      default:
        return theme.FONT.B1;
    }
  }};
  font-weight: 600;
  color: ${({ isColor, type, theme }) =>
    (isColor || type === 'reservation') && getPrimaryColor(APP_NAME)};
  ${({ type }) =>
    type === 'page' &&
    css`
      display: flex;
      width: 100%;
      justify-content: center;
    `}
`;

export const Text = styled.span<{ type?: 'highlight' | 'emphasis' | 'main' }>`
  word-break: keep-all;
  white-space: pre-line;
  color: ${({ type, theme }) => {
    switch (type) {
      case 'highlight':
        return getPrimaryColor(APP_NAME);
      case 'emphasis':
        return theme.COLOR.DANGER;
      default:
        return theme.COLOR.GREY_02;
    }
  }};
  ${({ type, theme }) => {
    switch (type) {
      case 'main':
        return theme.FONT.B1;
      default:
        return undefined;
    }
  }};
  font-weight: ${({ type }) => type === 'main' && 500};
`;

//**************** 테이블 스타일 *****************//
export const TableWrapper = styled.div``;

export const Table = styled.table<{ width?: string }>`
  width: ${({ width }) => (!!width ? width : '100%')};
  margin-top: 30px;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
`;

export const TableRow = styled.tr<{ isColor?: boolean; height?: string }>`
  height: ${({ height }) => (height ? height : '70px')};
  ${({ theme }) => theme.FONT.B2};
`;

export const Th = styled.th<{ isFirst?: boolean }>`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  background-color: ${({ theme }) => getPrimaryColor(APP_NAME)};
  color: ${({ theme }) => theme.COLOR.WHITE};
  border: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  ${({ isFirst }) =>
    isFirst &&
    css`
      width: 274px;
    `}
`;

export const Td = styled.td<{ header?: boolean }>`
  text-align: center;
  font-weight: 500;
  white-space: pre-wrap;
  border: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  ${({ header }) =>
    header &&
    css`
      background-color: ${getPrimaryOpaqueColor(APP_NAME)};
      font-weight: 600;
    `}
  & > p {
    ${({ theme }) => theme.FONT.B3};
    font-weight: 500;
    color: ${({ theme }) => theme.COLOR.GREY_02};
  }
`;

export const List = styled.li<{ number?: boolean; none?: boolean }>`
  display: list-item;
  list-style-position: outside;
  list-style-type: '•';
  margin-left: 20px;
  margin-bottom: 5px;
  padding-inline-start: 6px;
  ${({ theme }) => theme.FONT.B1};
  font-weight: 500;
  color: ${({ theme }) => theme.COLOR.GREY_02};
  ${({ number }) =>
    number &&
    css`
      list-style-type: decimal;
    `}
  ${({ none }) =>
    none &&
    css`
      list-style-type: none;
      margin-left: 5px;
    `}
`;
