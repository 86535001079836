import {
  BottomSheet,
  getPrimaryOpaqueColor,
  INSURANCE_INFO,
  INSURANCE_RANGE,
  useDetectMobile,
} from '@orcar/common-d2c';
import styled from 'styled-components';
import { APP_NAME } from '@/constants/common';
import * as S from './styles';

type SingleInsuranceInfoProps = {
  title: string;
  subInfo: string;
};

const SingleInsuranceInfo = ({ title, subInfo }: SingleInsuranceInfoProps) => {
  return (
    <SingleWrapper>
      <Item>
        <Text type='title'>{title}</Text>
        <Text type='sub'>{subInfo}</Text>
      </Item>
      {INSURANCE_INFO.map((item, idx) => (
        <Item key={idx}>
          <Text type='sub'>
            {item.title +
              INSURANCE_RANGE.find((i) => i.insurance === title)?.range[idx]}
          </Text>
          <Text type='info'>{item.subs}</Text>
        </Item>
      ))}
    </SingleWrapper>
  );
};

type MobileInsuranceInfoModalProps = {
  onClose: () => void;
  keepScrollLocked?: boolean;
};

const MobileInsuranceInfoModal = ({
  onClose,
  keepScrollLocked,
}: MobileInsuranceInfoModalProps) => {
  const { screenHeight } = useDetectMobile();
  return (
    <>
      <BottomSheet onClose={onClose} keepScrollLocked={keepScrollLocked}>
        <S.FilterTitlerWrapper header>
          <S.MobileFilterTitle header>자차 보험 안내</S.MobileFilterTitle>
        </S.FilterTitlerWrapper>
        <S.MobileScrollWrapper screenHeight={screenHeight}>
          {INSURANCE_RANGE.map((item, idx) => (
            <SingleInsuranceInfo
              key={idx}
              title={item.insurance}
              subInfo={item.info}
            />
          ))}
        </S.MobileScrollWrapper>
      </BottomSheet>
    </>
  );
};

export default MobileInsuranceInfoModal;

const SingleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 20px 0;
  border: 1px solid ${({ theme }) => theme.COLOR.GREY_01};

  & > div {
    &:first-child {
      background-color: ${getPrimaryOpaqueColor(APP_NAME)};
      border-bottom: 1px solid ${({ theme }) => theme.COLOR.GREY_01};
    }
    &:last-child {
      border: 0;
    }
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 20px;
  border-bottom: 1px dashed ${({ theme }) => theme.COLOR.GREY_01};
`;

const Text = styled.p<{ type: 'title' | 'sub' | 'info' }>`
  ${({ type, theme }) => {
    switch (type) {
      case 'title':
        return theme.FONT.B1;
      case 'sub':
        return theme.FONT.B2;
      case 'info':
        return theme.FONT.B3;
      default:
        return;
    }
  }}
  font-weight: ${({ type }) => type === 'title' && '600'};
`;
