import { getPrimaryColor, Styles } from '@orcar/common-d2c';
import styled, { css } from 'styled-components';
import { APP_NAME } from '@/constants/common';

interface NavButtonPosition {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

export interface SwiperStyleProps {
  arrow?: 'left' | 'right';
  mini?: boolean;
  width?: string;
  height: string;
  navButtonRadius?: string;
  leftNavButtonPosition?: NavButtonPosition;
  rightNavButtonPosition?: NavButtonPosition;
  hasNavButtonBorder?: boolean; // navigator 버튼에 border가 있는 경우
  hasNavButtonShadow?: boolean;
  isShownNavButtonInDisabled?: boolean;
  clientWidth?: number;
  currentSlideIdx?: number;
  noBullet?: boolean; // bullet(pagination)이 없는 경우
  borderColor?: string;
  backgroundColor?: string;
  isBanner?: boolean; // home banner의 경우 버튼이 슬라이더 내부에 위치해야 함
}

export const SwiperContainer = styled.div<SwiperStyleProps>`
  position: relative;
  display: flex;
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => height};

  .swiper {
    &-pagination {
      ${({ noBullet }) =>
        noBullet &&
        css`
          display: none;
        `}
    }

    &-pagination-bullet {
      background-color: ${getPrimaryColor(APP_NAME)};
      &-active {
        opacity: 1;
      }
    }

    &-button-disabled {
      visibility: ${({ isShownNavButtonInDisabled }) =>
        isShownNavButtonInDisabled ? 'visible' : 'hidden'};

      ${({ isShownNavButtonInDisabled }) =>
        isShownNavButtonInDisabled &&
        css`
          opacity: 0.3;
        `}
    }

    &-button-prev::after,
    &-button-next::after {
      display: none;
    }
  }
`;

export const NavButton = styled.button<SwiperStyleProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;

  top: ${({ mini, height, noBullet }) =>
    mini
      ? `calc((${height} - 24px - 17px) / 2)` // (height - bullet까지의 height - button의 50%) / 2
      : noBullet
      ? `calc((${height} - 17px) / 2)` // (height - button의 50%) / 2
      : `calc((${height} - 54px - 17px) / 2)`}; // (height - bullet까지의 height - button의 50%) / 2
  z-index: 2;

  ${({ leftNavButtonPosition, arrow }) =>
    leftNavButtonPosition &&
    arrow === 'left' &&
    css`
      ${leftNavButtonPosition.top && `top: ${leftNavButtonPosition.top}px;`}
      ${leftNavButtonPosition.left && `left: ${leftNavButtonPosition.left}px;`}
      ${leftNavButtonPosition.right &&
      `right: ${leftNavButtonPosition.right}px;`}
      ${leftNavButtonPosition.bottom &&
      `bottom: ${leftNavButtonPosition.bottom}px;`}
    `};

  ${({ rightNavButtonPosition, arrow }) =>
    rightNavButtonPosition &&
    arrow === 'right' &&
    css`
      ${rightNavButtonPosition.top && `top: ${rightNavButtonPosition.top}px;`}
      ${rightNavButtonPosition.left &&
      `left: ${rightNavButtonPosition.left}px;`}
      ${rightNavButtonPosition.right &&
      `right: ${rightNavButtonPosition.right}px;`}
    ${rightNavButtonPosition.bottom &&
      `bottom: ${rightNavButtonPosition.bottom}px;`}
    `};

  ${({ navButtonRadius }) =>
    navButtonRadius &&
    css`
      width: ${navButtonRadius};
      height: ${navButtonRadius};
    `}

  ${({ hasNavButtonBorder, theme }) =>
    hasNavButtonBorder &&
    css`
      padding: 0;
      border: 1px solid ${Styles.COLOR.GREY_05};
      background-color: ${Styles.COLOR.WHITE};
    `}

  ${({ hasNavButtonShadow }) =>
    hasNavButtonShadow && 'box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);'}


  ${({ isBanner, arrow, clientWidth, theme, currentSlideIdx }) =>
    isBanner &&
    css`
      width: 20px;
      height: 20px;
      padding: 0;
      top: 300px;
      left: ${arrow === 'left'
        ? `calc((${clientWidth}px - 1200px) / 2)` // 배너 전체 영역에서 PC layout에 맞춘 위치 설정
        : arrow === 'right' &&
          `calc(((${clientWidth}px - 1200px) / 2) + 28px)`};
      right: 0;
      background-color: ${currentSlideIdx === 0
        ? 'rgba(0, 0, 0, 0.3)'
        : Styles.COLOR.GREY_OPAQUE};
    `}
`;

export const Pagination = styled.span<{
  isMobile?: boolean;
  clientWidth: number;
  currentSlideIdx?: number;
}>`
  position: absolute;
  top: ${({ isMobile }) => !isMobile && '300px'};
  left: ${({ clientWidth, theme, isMobile }) =>
    !isMobile &&
    `calc(((${clientWidth}px - ${Styles.WIDTH.PC_LAYOUT}) / 2) + 56px)`};
  height: 20px;
  padding: 0 9px;
  background: ${({ theme, currentSlideIdx }) =>
    currentSlideIdx === 0 ? 'rgba(0, 0, 0, 0.3)' : Styles.COLOR.GREY_OPAQUE};
  border-radius: 30px;
  color: ${({ theme }) => Styles.COLOR.WHITE};
  ${Styles.FONT.B3};
  font-weight: 400;
  z-index: 2;

  ${({ isMobile, clientWidth, theme }) =>
    isMobile &&
    css`
      bottom: 163px;
      right: ${`calc(((${clientWidth}px - ${Styles.WIDTH.MOBILE_LAYOUT}) / 2) + 20px)`};
    `}
`;
