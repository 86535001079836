import { getPrimaryColor } from '@orcar/common-d2c';
import styled, { css } from 'styled-components';
import { APP_NAME } from '@/constants/common';

type OptionWrapperStyledType = {
  selected: boolean;
  isMobile?: boolean;
  isSumcar?: boolean;
};

export const OptionWrapper = styled.div<OptionWrapperStyledType>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isMobile, isSumcar }) =>
    isMobile ? '156px' : isSumcar ? '120px' : '145px'};
  height: ${({ isSumcar }) => (isSumcar ? '40px' : '37px')};
  border: 1px solid
    ${({ selected, isSumcar, theme }) =>
      selected
        ? getPrimaryColor(APP_NAME)
        : isSumcar
        ? theme.COLOR.GREY_03
        : theme.COLOR.GREY_02};
  border-radius: ${({ isSumcar }) => (isSumcar ? '5px' : '50px')};
  ${({ isSumcar, theme }) => isSumcar && theme.FONT.B2};
  cursor: pointer;
`;

export const OptionText = styled.p<OptionWrapperStyledType>`
  color: ${({ selected, theme }) =>
    selected ? getPrimaryColor(APP_NAME) : theme.COLOR.GREY_02};
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
  font-size: ${({ isSumcar }) => (isSumcar ? '14px' : '16px')};
  line-height: 27px;
  letter-spacing: -0.015em;
  user-select: none;
`;

export const DriverOptionSectionWrapper = styled.div<{
  isLast?: boolean;
  isMobile?: boolean;
}>`
  width: fit-content;
  box-sizing: border-box;
  padding-top: ${({ isLast, isMobile }) => !isMobile && isLast && '30px'};
`;
//TODO: 이 부분 스타일 변경 수정 필요 @deveassy

export const SelectionWrapper = styled.div<{
  isMobile?: boolean;
  screenHeight?: number;
}>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 75px;
  width: fit-content;
  padding-top: 19px;
  ${({ isMobile }) =>
    isMobile &&
    css`
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 320px;
      padding-top: 0;
    `};
  ${({ isMobile, screenHeight, theme }) =>
    isMobile && !!screenHeight
      ? `min-height: calc(${screenHeight}px - ${theme.HEIGHT.MOBILE_HEADER})`
      : null}
`;

export const OptionsWrapper = styled.div<{ isMobile?: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${({ isMobile }) => (isMobile ? '8px' : '44px')};
  grid-row-gap: ${({ isMobile }) => (isMobile ? '8px' : '22px')};
  /* width: fit-content; */
  padding-top: ${({ isMobile }) => !isMobile && '11px'};
`;

export const OptionsColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  height: 371px;
  padding-top: 19px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
