import { Button, Modal, Styles, useDetectMobile } from '@orcar/common-d2c';
import styled, { css } from 'styled-components';

type InfoConfirmModalProps = {
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  callNumber: string;
};

const InfoConfirmModal = ({
  onClose,
  title,
  children,
  callNumber,
}: InfoConfirmModalProps) => {
  const { isMobile } = useDetectMobile();
  return (
    <Modal
      paddingHorizontal={isMobile ? 20 : 30}
      paddingVertical={isMobile ? 20 : 32}
      onClose={onClose}
      topLevel
    >
      <Wrapper isMobile={isMobile}>
        {title ? (
          <Text type='title' isMobile={isMobile}>
            {title}
          </Text>
        ) : null}
        <Text type='num' isMobile={isMobile}>
          {callNumber}
        </Text>
        <Text type='sub' isMobile={isMobile}>
          {children}
        </Text>
        <Button
          width={isMobile ? '100%' : '150px'}
          height='40px'
          onClick={onClose}
          margin='30px 0 0'
          font={Styles.FONT.B1}
        >
          확인
        </Button>
      </Wrapper>
    </Modal>
  );
};

export default InfoConfirmModal;

const Wrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ isMobile }) => (isMobile ? '280px' : '330px')};
`;

const Text = styled.p<{ type: 'title' | 'sub' | 'num'; isMobile?: boolean }>`
  max-width: ${({ type }) => type === 'num' && '300px'};
  text-align: center;
  margin: ${({ type }) =>
    type === 'title' ? '20px 0 0' : type === 'num' ? '16px 0 0' : '12px 0 0'};
  ${({ type }) =>
    type === 'title'
      ? Styles.FONT.H3
      : type === 'sub'
      ? Styles.FONT.B3
      : Styles.FONT.B1};

  ${({ isMobile, type }) =>
    isMobile &&
    css`
      width: ${type === 'sub' && '230px'};
      ${type === 'title' ? Styles.FONT.B1 : Styles.FONT.B2};
      color: ${type === 'sub' && Styles.COLOR.GREY_02};
    `}
  font-weight: ${({ type }) => (type === 'title' || type === 'num') && '600'};
  white-space: pre-line;
`;
