import { isMobileAtom, useRecoil } from '@orcar/common-d2c';
import Image1 from '@/assets/img/img_reservation-info-1.png';
import Image2 from '@/assets/img/img_reservation-info-2.png';
import MobileReservationInfoImg from '@/assets/img/img_reservation-info-mobile.svg';
import * as S from '../styles';

const ReservationInfo = () => {
  const { recoilValue: isMobile } = useRecoil(isMobileAtom);

  return (
    <>
      {isMobile ? (
        <img
          src={MobileReservationInfoImg}
          width='100%'
          alt='모바일버전 대여안내 이미지'
        />
      ) : (
        <S.Wrapper isMobile={isMobile}>
          <S.Title type='page'>렌트카 인수 및 대여 안내(공항 도착)</S.Title>
          <S.Section>
            <S.Title type='reservation'>[공항 도착 시 대여 안내]</S.Title>
            <S.Title type='section'>1. 공항 도착 후 5번 GATE로 이동</S.Title>
            <S.Text type='main'>
              제주공항 도착 후 5번 GATE 앞에서 횡단보도를 건넌 후 오른편 통로를
              따라 <S.Text type='emphasis'>렌트카하우스</S.Text>로 이동합니다.
              <br />
              <S.Text type='emphasis'>
                {`** 면허증을 미소지 하신 분은 운전 경력 증명서를 발급 받아오거나, 본인 명의 휴대폰으로 "182"에 전화하여 면허번호 조회 후 방문바랍니다. 
                (면허 미조회 시 차량 대여 불가)`}
              </S.Text>
            </S.Text>
          </S.Section>
          <S.Section>
            <S.Title type='section'>2. 셔틀 탑승(8구역 1번 자리)</S.Title>
            <S.Title type='subNum'>
              1) 셔틀버스에 탑승(<S.Text type='highlight'>무지개, 통일</S.Text>{' '}
              로고가 적혀 있는 버스)
            </S.Title>
            <S.Title type='subNum'>2) 계약서 작성</S.Title>
            <ul>
              <S.List>본사 미팅 라운지 내에서 예약 확인 후 계약서 작성</S.List>
              <S.List>운전자 면허증 확인 후 대여 가능합니다.</S.List>
            </ul>

            <div style={{ marginLeft: '30px' }}>
              <S.Text type='main'>
                <S.Text type='emphasis'>
                  ** 면허증이 없는 경우(주민등록증만 지참하였을 경우) <br />
                </S.Text>
              </S.Text>

              <ol>
                <S.List>
                  주 중, 09:00~18:00 경찰청 민원센터 “182”로 통화하여 면허번호를
                  조회 받으시고 오시면 됩니다. (본인 명의 휴대폰만 가능)
                </S.List>
              </ol>
            </div>

            <ul>
              <S.List>무지개, 통일렌트카 통합미팅라운지 안으로 이동</S.List>
              <S.List>
                키오스크를 통해 순서대로 계약서를 작성하실 수 있습니다.
              </S.List>
              <S.List>순서대로 계약서 작성 후 차량으로 이동</S.List>
            </ul>

            <S.Title type='subNum'>3) 차량 인수(전차량 무인배차)</S.Title>
            <ul>
              <S.List>
                출고 장소에서 차량 외관 확인 및 연료 확인 후 차량 인수 <br />
                <S.Text type='emphasis'>
                  (차량 내・외부 상태 확인 시 자차 가입 여부와 상관없이 사진
                  촬영은 꼭 해두셔야 합니다.)
                </S.Text>
              </S.List>
              <S.List>
                차량 인수 시 차량 내・외부 확인 후 정비 불량 및 세차 불량 차량은
                현장에서 교체 가능 <br />
                <S.Text type='emphasis'>
                  (차량 이동 후 교체를 원하실 경우 본사 내방 시 교체가 가능하며,
                  외부에서 교체는 불가할 수 있습니다.)
                </S.Text>
              </S.List>
              <S.List>인수와 반납은 본사에서만 이루어집니다.</S.List>
            </ul>

            <S.ImageSection>
              <S.Text type='main'>
                앞면 : 접촉 사고 및 주차 이동 시 사용될 번호이오니 차량 앞면에
                비치해 주십시오.
              </S.Text>
              <img src={Image1} alt='차량에 비치할 안내증 앞면 이미지' />
            </S.ImageSection>
            <S.ImageSection>
              <p>
                <S.Text type='main'>
                  뒷면 : 계약조건은 별도의 구두 설명 없이 배포되오니 필히 확인
                  부탁드립니다.
                </S.Text>
              </p>
              <img src={Image2} alt='계약 조건 안내문 뒷면 이미지' />
            </S.ImageSection>
            <S.Title type='subNum'>
              4) 계약서 작성 후 받은 계약조건 안내문(앞면)에 기재된 주차 구역,
              차량번호 확인 후 이동
            </S.Title>
            <S.Text type='main'>
              지정된 구역에서 차량번호 확인 후 외관 및 연료게이지 사진 촬영이
              완료되면 바로 이동하셔도 됩니다.
              <br />
              <S.Text type='emphasis'>
                ** 외관 및 연료게이지 사진 촬영은 필수이며 반납 시까지 보관
                부탁드립니다. 반납 시 생기는 불이익에 대해 책임지지 않습니다.
              </S.Text>
            </S.Text>
            <S.Title type='subNum'>5) 전자 계약서 확인</S.Title>
            <S.Text type='main'>
              <S.Text type='emphasis'>
                ** 문자메시지로 전송되는 전자 계약서 및 계약조건 안내문을 반드시
                확인한 후 차량 이동 부탁드립니다.
              </S.Text>
            </S.Text>
          </S.Section>
          <S.Section>
            <S.Title type='reservation'>[무지개렌트카 대여 규정]</S.Title>
            <ul>
              <S.List>
                모든 차량은 금연차량입니다. (흡연 시 차량 크리닝비 10만원이
                청구됩니다.)
              </S.List>
              <S.List>
                차량 대여 시 실물면허증 소지(
                <S.Text type='highlight'>2종 보통</S.Text> 면허 이상 필요,
                차종에 따라 <S.Text type='highlight'>1종 보통</S.Text> 면허
                필요)
              </S.List>
              <S.List>
                제 2운전자도 대여 조건을 모두 충족해야하며, 계약서 작성 후 보험
                효력이 발생합니다.
              </S.List>
              <S.List>
                <S.Text type='highlight'>반려동물, 낚시용품</S.Text> 지참 시
                대여 불가능하며, 대여 조건 미달로 렌트가 불가한 경우, 추가
                수수료가 발생할 수 있습니다.
              </S.List>
              <S.List>
                악취/오염 발생 시 정도에 따라 실내 크리닝 비용이 추가로
                청구됩니다. (최대 30만원)
              </S.List>
              <S.List>
                <S.Text type='highlight'>군인(직업군인 포함)</S.Text> 대여
                가능합니다.
              </S.List>
              <S.List>
                동절기 기간 동안 스노우체인은 스프레이체인만 사용 가능하며
                현장에서 구매 가능합니다.
              </S.List>
              <S.List>
                <S.Text type='highlight'>외국인</S.Text>의 경우,{' '}
                <S.Text type='highlight'>064-710-1600</S.Text>으로 전화
                부탁드립니다. 예약 전 대여자격 확인 및 안내가 필수입니다. (For
                foreign customers, please call 064-710-1600. You must verify
                your rental eligibility and receive guidance before making a
                reservation.)
              </S.List>
            </ul>
          </S.Section>
          <S.Section>
            <S.Title type='reservation'>[대여 자격 안내]</S.Title>
            <S.Title>
              1) 2022년까지 출고된 경차, 소형, 준중형, 중형 차종
            </S.Title>
            <ul>
              <S.List>
                일반자차보험 이하 보험은 운전자 나이{' '}
                <S.Text type='highlight'>만 21세 이상</S.Text> 면허취득일{' '}
                <S.Text type='highlight'>1년 이상</S.Text>
              </S.List>
              <S.List>
                고급자차보험&무제한특약 보험은 운전자 나이{' '}
                <S.Text type='highlight'>만 23세 이상</S.Text> 면허취득일{' '}
                <S.Text type='highlight'>2년 이상</S.Text>
              </S.List>
            </ul>
            <S.Title>
              2) 2023년부터 출고된 경차, 소형, 준중형, 중형 차종
            </S.Title>
            <ul>
              <S.List>
                일반자차보험 이하 보험은 운전자 나이{' '}
                <S.Text type='highlight'>만 26세 이상</S.Text> 면허취득일{' '}
                <S.Text type='highlight'>1년 이상</S.Text>
              </S.List>
              <S.List>
                고급자차보험&무제한특약 보험은 운전자 나이{' '}
                <S.Text type='highlight'>만 26세 이상</S.Text> 면허취득일{' '}
                <S.Text type='highlight'>2년 이상</S.Text>
              </S.List>
            </ul>
            <S.Title>3) SUV, 승합, 고급, 수입, 전기차 차종</S.Title>
            <ul>
              <S.List>
                일반자차보험 이하 보험은 운전자 나이{' '}
                <S.Text type='highlight'>만 26세 이상</S.Text> 면허취득일{' '}
                <S.Text type='highlight'>1년 이상</S.Text>
              </S.List>
              <S.List>
                고급자차보험&무제한특약 보험은 운전자 나이{' '}
                <S.Text type='highlight'>만 26세 이상</S.Text> 면허취득일{' '}
                <S.Text type='highlight'>2년 이상</S.Text>
              </S.List>
            </ul>
            <S.Text type='main'>
              <S.Text type='emphasis'>
                ** 솔라티 15인승(자동) : 1종보통면허, 만 30세 이상, 운전 경력
                3년 이상인 경우 대여 가능 <br />
              </S.Text>
              <S.Text type='main'>
                차종별 대여 조건 및 자차 면책 가입 조건이 상이하오니 반드시 확인
                후 예약 부탁드립니다.
              </S.Text>
            </S.Text>
          </S.Section>
        </S.Wrapper>
      )}
    </>
  );
};

export default ReservationInfo;
