import { Nullable } from '@orcar/common';
import { getPrimaryColor, getPrimaryOpaqueColor } from '@orcar/common-d2c';
import styled, { css } from 'styled-components';
import { APP_NAME } from '@/constants/common';
import { fadeIn } from '@/styles/animation';

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.COLOR.WHITE};
`;

export const VehicleCardWrapper = styled(FlexSpaceBetween)`
  align-items: flex-end;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  }
`;

export const CarInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 62px 0 35px;
`;

export const CarImageWrapper = styled.span<{ animate?: boolean }>`
  animation: ${({ animate }) =>
    animate ? css`0.3s ease-out ${fadeIn}` : 'none'};
`;

export const InfoTagWrapper = styled(FlexSpaceBetween)`
  width: 150px;
`;

export const SelectCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 572px;
  height: 100%;
`;

export const FilterButtonWrapper = styled(FlexSpaceBetween)`
  align-items: center;
  width: 100%;
  height: 45px;
`;

export const ThirdCardWrapper = styled.div<{ isSwiper?: boolean }>`
  width: 100%;
  margin-bottom: ${({ isSwiper }) => isSwiper && '21px'};

  ${({ isSwiper }) =>
    !isSwiper &&
    css`
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(3, 1fr);
      height: 276px;
    `}
`;

export const SearchNullWrapper = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: relative;
      height: 100%;
    `}
`;

export const SearchNullMessageText = styled.p<{
  sub?: boolean;
  double?: boolean;
  isMobile?: boolean;
  isFilter?: boolean;
}>`
  margin: 103px 0 42px;
  ${({ sub, double, theme }) =>
    sub || double ? theme.FONT.H4 : theme.FONT.H3};

  ${({ sub }) =>
    sub &&
    css`
      margin-top: 0;
      margin-bottom: 100px;
      ${({ theme }) => theme.FONT.H4}
      font-weight: 400;
    `};

  ${({ double }) =>
    double &&
    css`
      display: flex;
      flex-direction: column;
      margin: 0 0 20px;
      font-weight: 400;

      &:nth-child(3) {
        margin-bottom: 60px;
      }
    `}

  ${({ isMobile, sub, isFilter, theme }) =>
    isMobile &&
    css`
      width: ${!sub ? (isFilter ? '220px' : '250px') : null};
      text-align: center;
      color: ${sub && theme.COLOR.GREY_02};
      ${sub && theme.FONT.B1};
      margin: ${!sub && '103px 0 9px'};
    `}
`;

export const SearchNullButtonWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  ${({ isMobile }) =>
    isMobile &&
    css`
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    `}
`;

// ********* 개별 card style ********* //

export const CompanyCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
  width: 180px;
  height: 231px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  border-radius: 10px;
  cursor: pointer;
`;

export const CardWrapper = styled(FlexSpaceBetween)`
  flex-direction: column;
  align-items: flex-start;

  width: 180px;
  height: 231px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  border-radius: 10px;
  cursor: pointer;
`;

export const TitleText = styled.span<{ model?: boolean }>`
  display: flex;
  margin-top: 12px;
  ${({ theme }) => theme.FONT.B1};
  font-weight: 600;
  line-height: 19px;
  color: ${({ theme }) => theme.COLOR.GREY_01};
  ${({ model }) =>
    model &&
    css`
      margin: 22px 0 12px;
    `}
`;

export const InfoListWrapper = styled.ul<{
  isInsurance?: boolean;
}>`
  width: 100%;
  height: 55px;
`;

export const CompanyInfoListWrapper = styled.ul`
  padding-left: 10px;
  margin-top: 12px;
`;

export const InsuranceInfoListWrapper = styled.ul<{
  isSuper?: boolean;
}>`
  width: 140px;
  height: 60px;
  background-color: ${({ theme, isSuper }) =>
    isSuper ? getPrimaryOpaqueColor(APP_NAME, true) : theme.COLOR.GREY_BG};
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: ${({ theme, isSuper }) =>
    isSuper
      ? `1px solid ${getPrimaryColor(APP_NAME)}`
      : `1px solid ${theme.COLOR.GREY_05}`};
`;

export const InfoList = styled.li<{
  isCompany?: boolean;
}>`
  display: list-item;
  list-style-type: '•';
  padding-inline-start: 6px;
  ${({ theme }) => theme.FONT.B3};
  color: ${({ isCompany, theme }) =>
    isCompany ? theme.COLOR.GREY_02 : theme.COLOR.GREY_01};
`;

export const InsuranceInfoList = styled.li<{
  isSuper?: boolean;
}>`
  display: list-item;
  color: ${({ theme, isSuper }) =>
    isSuper ? getPrimaryColor(APP_NAME) : theme.COLOR.GREY_02};
  ${({ theme }) => theme.FONT.B3};
  line-height: 14px;

  &:nth-child(2) {
    color: ${({ theme, isSuper }) => !isSuper && theme.COLOR.GREY_01};
    ${({ theme }) => theme.FONT.B2};
    line-height: 17px;
    font-weight: 500;
    margin-top: 4px;
  }
`;
export const CompanyPriceWrapper = styled.div`
  margin-top: auto;
`;
export const PriceWrapper = styled.div`
  display: flex;
  height: 46px;
  align-items: center;
`;

export const Sub = styled.p`
  margin: 4px 0px 30px 0px;
  ${({ theme }) => theme.FONT.B3};
  color: ${({ theme }) => theme.COLOR.GREY_02};
`;
