import { MobileProps, PAGE_NAME } from '@orcar/common-d2c';
import { useNavigate } from 'react-router-dom';
import * as S from '../styles';

const FooterLinkArr = [
  {
    id: 'travel',
    title: '국내여행약관',
  },
  {
    id: 'privacy',
    title: '개인정보처리방침',
  },
  {
    id: 'commerce',
    title: '전자상거래표준약관',
  },
  {
    id: 'rent',
    title: '렌트카대여표준약관',
  },
];

const LinkButtonSection = ({ isMobile }: MobileProps) => {
  const navigate = useNavigate();

  return (
    <S.NavLinkWrapper isMobile={isMobile}>
      {FooterLinkArr.map((item) => (
        <S.NavLink
          key={item.id}
          onClick={() => navigate(`${PAGE_NAME.policy}?type=${item.id}`)}
          target='_blank'
          isMobile={isMobile}
        >
          {item.title}
        </S.NavLink>
      ))}
    </S.NavLinkWrapper>
  );
};

export default LinkButtonSection;
