import { AxiosException, D2CLoginDto } from '@orcar/common';
import {
  ALERT_MESSAGE_TYPE,
  authAtom,
  createPreReservation,
  getPreReservation,
  kakaoLogin,
  LOCAL_STORAGE_KEY,
  PAGE_NAME,
  SESSION_STORAGE_KEY,
  useRecoil,
  useToast,
} from '@orcar/common-d2c';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { COMPANY_ID, ORIGIN_URL } from '@/constants/common';

const KakaoLoginRedirectPage = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const { addToast } = useToast();
  const navigate = useNavigate();
  const { setRecoilState: setUser } = useRecoil(authAtom);

  const redirectPath =
    sessionStorage.getItem(SESSION_STORAGE_KEY.REDIRECT_PATH) || '/';

  const handleSuccessLogin = (response: D2CLoginDto) => {
    const { accessToken, ...user } = response;

    addToast({
      content: `${user.name}님, 환영해요!`,
    });
    setUser(user);
    localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, accessToken);

    if (redirectPath === PAGE_NAME.payment) {
      const preReservationId = sessionStorage.getItem(
        SESSION_STORAGE_KEY.PRE_RESERVATION_ID,
      );
      if (preReservationId) {
        getPreReservation(preReservationId).then((preReservation) => {
          createPreReservation({
            vehicleModelId: preReservation.vehicleModelId,
            insuranceName: preReservation.insuranceName,
            pickUpAt: String(preReservation.pickUpAt),
            dropOffAt: String(preReservation.dropOffAt),
            companyId: preReservation.companyId,
          }).then((res) => {
            sessionStorage.setItem(
              SESSION_STORAGE_KEY.PRE_RESERVATION_ID,
              res.id,
            );
            navigate(PAGE_NAME.payment, { replace: true });
          });
        });
      } else {
        navigate(PAGE_NAME.index, { replace: true });
      }
    } else {
      navigate(redirectPath, { replace: true });
    }
  };

  useEffect(() => {
    if (!code) return;

    kakaoLogin({
      companyId: COMPANY_ID,
      code,
      redirectURI: ORIGIN_URL + 'login/kakao',
    })
      .then(handleSuccessLogin)
      .catch((error: AxiosError<AxiosException>) => {
        if (error.response?.data.statusCode === 409) {
          alert(ALERT_MESSAGE_TYPE.ALREADY_REGISTERED_WITH_ANOTHER_SOCIAL);
        } else {
          alert(ALERT_MESSAGE_TYPE.LOGIN_FAIL);
        }
        navigate(redirectPath, { replace: true });
      });
  }, [code]);

  return null;
};

export default KakaoLoginRedirectPage;
