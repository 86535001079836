import { D2CCompanyProfileDto, Nullable } from '@orcar/common';
import { Viewer } from '@orcar/common-d2c';
import { MutableRefObject } from 'react';
import styled from 'styled-components';
import {
  ContentWrapper,
  FlexColWrapper,
  InfoText,
  InfoTitle,
  SectionTitle,
} from './commonStyles';

interface CompanyInfoProps {
  company?: D2CCompanyProfileDto;
  isMobile: boolean;
  tabRef?: MutableRefObject<Nullable<HTMLDivElement>[]>;
  hasPadding?: boolean;
}

const CompanyInfo = ({
  company,
  isMobile,
  tabRef,
  hasPadding,
}: CompanyInfoProps) => {
  return (
    <ContentWrapper
      ref={(ref) => (tabRef ? (tabRef.current[2] = ref) : null)}
      hasPadding={hasPadding}
    >
      <SectionTitle isMobile={isMobile}>{company?.name}</SectionTitle>
      {/* <ImageWrapper></ImageWrapper> */}
      <FlexColWrapper gap='12px'>
        <InfoWrapper isMobile={isMobile}>
          <InfoTitle>인수/반납 장소</InfoTitle>
          <InfoText>{company?.address}</InfoText>
        </InfoWrapper>
        <InfoWrapper isMobile={isMobile}>
          <InfoTitle>전화번호</InfoTitle>
          <InfoText>{company?.phoneNumber}</InfoText>
        </InfoWrapper>
        <InfoWrapper isMobile={isMobile}>
          <InfoTitle>무료 셔틀 정보</InfoTitle>
          <Viewer
            content={company?.freeShuttleInfo || ''}
            style={{ lineHeight: '27px' }}
          />
        </InfoWrapper>
      </FlexColWrapper>
    </ContentWrapper>
  );
};

export { CompanyInfo };

const ImageWrapper = styled.div`
  width: 100%;
  height: 200px;
  border: 1px solid #000;
  margin-bottom: 20px;
  border-radius: 10px;
`;

const InfoWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => isMobile && 'column'};
  white-space: pre-line;
`;
