import { Nullable } from '@orcar/common';
import {
  ALERT_MESSAGE_TYPE,
  convertToLocalTime,
  DriverOptionType,
  PAGE_NAME,
  RESERVATION_MONTHS_LIMIT,
  SearchParamsType,
  setStaticDate,
} from '@orcar/common-d2c';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DRIVER_OPTIONS } from '@/constants/searchOptions';

type searchOptionsType = {
  pickUpAtOption: string;
  dropOffAtOption: string;
  driverAgeOption: DriverOptionType;
  drivingExperienceOption: DriverOptionType;
  numberOfPeopleOption: DriverOptionType;
};

const useGetSearchOptionFromUrl = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const pickUpAt = searchParams.get('pickUpAt') || setStaticDate('pickUp');
  const dropOffAt = searchParams.get('dropOffAt') || setStaticDate('dropOff');
  const driverAge = searchParams.get('driverAge') || '';
  const drivingExperience = searchParams.get('drivingExperience') || '';
  const numberOfPeople = searchParams.get('numberOfPeople') || '';

  const [initialSearchOptions, setInitialSearchOptions] =
    useState<SearchParamsType>({
      pickUpAt: null,
      dropOffAt: null,
      driverAge: null,
      numberOfPeople: null,
      drivingExperience: null,
    });

  const getSearchOptionsFromQuery = () => {
    const pickUpAtInLocalTime = pickUpAt
      ? convertToLocalTime(pickUpAt)
      : setStaticDate('pickUp');
    const dropOffAtInLocalTime = dropOffAt
      ? convertToLocalTime(dropOffAt)
      : setStaticDate('dropOff');
    const driverAgeOption = getDriverOptionFromQuery(driverAge, 'driverAge');
    const drivingExperienceOption = getDriverOptionFromQuery(
      drivingExperience,
      'drivingExperience',
    );
    const numberOfPeopleOption = getDriverOptionFromQuery(
      numberOfPeople,
      'numberOfPeople',
    );

    const isBeyondLimit = dayjs(dropOffAt).isAfter(
      dayjs().add(RESERVATION_MONTHS_LIMIT, 'month'),
    );

    if (isBeyondLimit) {
      alert(ALERT_MESSAGE_TYPE.EXCEED_SEARCH_DATE_LIMIT);
      navigate(PAGE_NAME.index);
      return;
    }

    if (
      !pickUpAtInLocalTime ||
      !dropOffAtInLocalTime ||
      !driverAgeOption ||
      !drivingExperienceOption ||
      !numberOfPeopleOption
    ) {
      alert(ALERT_MESSAGE_TYPE.NOT_ALLOWED_SEARCH_PARAM);
      navigate(PAGE_NAME.index);
      return;
    }

    return {
      pickUpAtOption: pickUpAtInLocalTime,
      dropOffAtOption: dropOffAtInLocalTime,
      driverAgeOption,
      drivingExperienceOption,
      numberOfPeopleOption,
    };
  };

  const getDriverOptionFromQuery = (
    query: Nullable<string>,
    type: 'driverAge' | 'drivingExperience' | 'numberOfPeople',
  ) => {
    if (type === 'driverAge' || type === 'drivingExperience') {
      return DRIVER_OPTIONS[type].find(
        (option) => option.value === (query ? Number(query) : null),
      );
    } else {
      return DRIVER_OPTIONS[type].find(
        (option) => option.value === (query ? Number(query) : null),
      );
    }
  };

  const setDataFromQuery = (searchOptions?: searchOptionsType) => {
    if (!searchOptions) return;

    const {
      pickUpAtOption,
      dropOffAtOption,
      driverAgeOption,
      drivingExperienceOption,
      numberOfPeopleOption,
    } = searchOptions;

    setInitialSearchOptions({
      pickUpAt: pickUpAtOption,
      dropOffAt: dropOffAtOption,
      driverAge: driverAgeOption?.value ?? null,
      drivingExperience: drivingExperienceOption?.value ?? null,
      numberOfPeople: numberOfPeopleOption?.value ?? null,
    });
  };

  useEffect(() => {
    setDataFromQuery(getSearchOptionsFromQuery());
  }, [pickUpAt, dropOffAt, driverAge, drivingExperience, numberOfPeople]);

  return {
    initialSearchOptions,
    setInitialSearchOptions,
  };
};

export { useGetSearchOptionFromUrl };
