import {
  authAtom,
  getPrimaryColor,
  PAGE_NAME,
  useRecoil,
} from '@orcar/common-d2c';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { APP_NAME } from '@/constants/common';

type MyPageWrapperProps = {
  isMobile: boolean;
  children: React.ReactNode;
};

type MyPageTab = {
  id: number;
  label: string;
  pathname: string;
};

export const MY_PAGE_TABS: MyPageTab[] = [
  {
    id: 0,
    label: '내 예약 목록',
    pathname: '',
  },
  // {
  //   id: 1,
  //   label: '내 정보 수정',
  //   pathname: '/profile',
  // },
];

const MyPageWrapper = ({ isMobile, children }: MyPageWrapperProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { recoilValue: user } = useRecoil(authAtom);

  const [activeTab, setActiveTab] = useState(MY_PAGE_TABS[0].id);

  const isMyPage = pathname.includes(PAGE_NAME.my_page);

  const handleTabClick = (id: number, pathname: string) => {
    setActiveTab(id);
    navigate(PAGE_NAME.my_page + pathname);
  };

  if (isMobile) return children;

  return isMyPage && !!user ? (
    <div className='flex w-[1000px] justify-between'>
      <SideBarWrapper>
        <SideBarTitle>마이페이지</SideBarTitle>
        <MenuWrapper>
          {MY_PAGE_TABS.map((tab) => (
            <MenuTab
              key={tab.id}
              isSelected={tab.id === activeTab}
              onClick={() => handleTabClick(tab.id, tab.pathname)}
            >
              {tab.label}
            </MenuTab>
          ))}
        </MenuWrapper>
      </SideBarWrapper>
      {children}
    </div>
  ) : (
    children
  );
};

export default MyPageWrapper;

const SideBarWrapper = styled.div`
  position: sticky;
  top: 144px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 140px;
  height: fit-content;
  gap: 60px;
`;

const SideBarTitle = styled.p`
  ${({ theme }) => theme.NEW_FONT.H1};
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const MenuTab = styled.p<{ isSelected?: boolean }>`
  ${({ theme }) => theme.NEW_FONT.H5};
  color: ${({ isSelected, theme }) =>
    isSelected ? getPrimaryColor(APP_NAME) : theme.COLOR.BLACK};
  cursor: pointer;
`;
