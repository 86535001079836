import { Nullable } from '@orcar/common';
import { DriverOptionType, useDetectMobile } from '@orcar/common-d2c';
import * as S from './styles';

type OptionProps = {
  option: DriverOptionType;
  selected: boolean;
  onSelect: (key: Nullable<DriverOptionType>) => void;
  isSumcar?: boolean;
};

const DriverOption = ({
  option,
  selected,
  onSelect,
  isSumcar,
}: OptionProps) => {
  const { isMobile } = useDetectMobile();
  return (
    <S.OptionWrapper
      selected={selected}
      onClick={() => {
        onSelect(selected ? null : option);
      }}
      isMobile={isMobile}
      isSumcar={isSumcar}
    >
      <S.OptionText selected={selected} isSumcar={isSumcar}>
        {option.text}
      </S.OptionText>
    </S.OptionWrapper>
  );
};

export default DriverOption;
