import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import InsuranceInfo from './components/InsuranceInfo';
import ReservationInfo from './components/ReservationInfo';
import { LayoutWrapper } from './styles';

const RentInfoPage = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const eng = searchParams.get('eng');
  const [queryString, setQueryString] = useState('');

  useEffect(() => {
    if (!!type) {
      setQueryString(type);
    }
  }, [type]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <LayoutWrapper>
        {queryString === 'insurance' || eng === 'inshelp' ? (
          <InsuranceInfo />
        ) : queryString === 'reservation' || eng === 'renthelp' ? (
          <ReservationInfo />
        ) : null}
      </LayoutWrapper>
    </div>
  );
};

export default RentInfoPage;
