import {
  Button,
  COMPANY_NAME,
  isMobileAtom,
  NAME_REGEX,
  PAGE_NAME,
  PHONE_NUMBER_REGEX,
  Styles,
  TextInput,
  useRecoil,
} from '@orcar/common-d2c';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

const FindReservationPage = () => {
  const navigate = useNavigate();

  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const { recoilValue: isMobile } = useRecoil(isMobileAtom);

  const handlePhoneNumber: Dispatch<SetStateAction<string>> = (value) => {
    value = value.toString().replaceAll('-', '');

    if (value.length > 11) return;
    setPhoneNumber(value);
  };

  const handleOrderConfirmation = () => {
    navigate({
      pathname: PAGE_NAME.my_page,
      search: `?customerPhoneNumber=${phoneNumber}&customerName=${userName}`,
    });
  };

  return (
    <OuterWrapper isMobile={isMobile}>
      <Title isMobile={isMobile}>{COMPANY_NAME.RAINBOW} 예약 조회</Title>
      <InnerWrapper isMobile={isMobile}>
        <InfoText isMobile={isMobile}>
          상품 구매를 위해 사용했던 고객 정보를 입력해주세요.
        </InfoText>
        <WidthWrapper isMobile={isMobile}>
          <TextInput
            label='예약자명'
            placeholder='예약자명을 입력해주세요'
            explanation='실명을 입력해주세요.'
            value={userName}
            setValue={setUserName}
            pattern={NAME_REGEX}
            type='text'
            autoFocus
          />
          <TextInput
            type='tel'
            label='휴대전화'
            placeholder='휴대전화 번호를 입력해주세요.'
            explanation='-빼고 숫자만 입력해주세요.'
            value={phoneNumber}
            setValue={handlePhoneNumber}
            pattern={PHONE_NUMBER_REGEX}
          />
          <ButtonWrapper isMobile={isMobile}>
            <Button
              onClick={handleOrderConfirmation}
              disabled={!userName || phoneNumber.length < 11}
              font={Styles.FONT.H4}
              borderRadius='50px'
              width='100%'
              height='59px'
            >
              예약 조회
            </Button>
          </ButtonWrapper>
        </WidthWrapper>
      </InnerWrapper>
    </OuterWrapper>
  );
};

const OuterWrapper = styled.div<{ isMobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0 50px;

  ${({ isMobile, theme }) =>
    isMobile &&
    css`
      height: calc(100vh - 67px); // mobile header height만큼 빼주기
      padding-top: 32px;
      margin: 0;
      background-color: ${theme.COLOR.GREY_BG};
    `}
`;

const Title = styled.h4<{ isMobile: boolean }>`
  ${({ isMobile, theme }) => (isMobile ? theme.FONT.H5 : theme.FONT.H4)};
  color: ${({ theme }) => theme.COLOR.GREY_01};
  margin: ${({ isMobile }) => (isMobile ? '0 0 16px' : '34px 0')};
`;

const InnerWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 560px;
  padding: 26px 0;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
      padding: 0 20px;
    `}
`;

const InfoText = styled.span<{ isMobile: boolean }>`
  width: ${({ isMobile }) => isMobile && '176px'};
  ${({ isMobile, theme }) => (isMobile ? theme.FONT.B3 : theme.FONT.B1)};
  color: ${({ theme }) => theme.COLOR.GREY_01};
  margin-bottom: ${({ isMobile }) => (isMobile ? '15px' : '29px')};
  text-align: center;
`;

const WidthWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '384px')};
`;

const ButtonWrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  margin-top: 66px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: fixed;
      bottom: 20px;
      left: 0;
      padding: 0 20px;
      margin-top: 0;
    `}
`;

export default FindReservationPage;
