import { Button, NotFoundIcon, PAGE_NAME, Styles } from '@orcar/common-d2c';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <img src={NotFoundIcon} alt='404 아이콘' />
      <Text>페이지를 찾을 수 없습니다.</Text>
      <ButtonWrapper>
        <Button
          width='120px'
          height='36px'
          borderColor={Styles.COLOR.GREY_02}
          borderRadius='5px'
          bgColor={Styles.COLOR.WHITE}
          font={Styles.FONT.B2}
          color={Styles.COLOR.GREY_02}
          onClick={() => navigate(-1)}
        >
          이전 페이지로
        </Button>
        <Button
          width='120px'
          height='36px'
          borderColor={Styles.COLOR.GREY_02}
          borderRadius='5px'
          bgColor={Styles.COLOR.WHITE}
          font={Styles.FONT.B2}
          color={Styles.COLOR.GREY_02}
          onClick={() => navigate(PAGE_NAME.index)}
        >
          홈으로
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default NotFound;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 120px 0;
`;

const Text = styled.p`
  ${({ theme }) => theme.FONT.H4};
  color: ${({ theme }) => theme.COLOR.GREY_02};
  margin: 16px 0 50px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
`;
