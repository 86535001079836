import styled from 'styled-components';

export const MobileFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterTitlerWrapper = styled.div<{ header?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${({ header }) => (header ? '65px' : '60px')};
  padding: 0 10px 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.COLOR.GREY_03};
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.COLOR.GREY_03};
`;

export const MobileScrollWrapper = styled.div<{
  isFilter?: boolean;
  screenHeight: number;
}>`
  height: ${({ screenHeight, theme, isFilter }) =>
    isFilter
      ? `calc(${screenHeight}px - ${theme.HEIGHT.MOBILE_HEADER} - 165px)`
      : `calc(${screenHeight}px - ${theme.HEIGHT.MOBILE_HEADER} - 65px)`};

  padding-bottom: 20px;
  overflow: scroll;
  overflow-x: hidden;
`;

export const FilterTitleWrapper = styled.div`
  display: flex;
`;

export const MobileFilterTitle = styled.p<{ header?: boolean }>`
  ${({ theme, header }) => (header ? theme.FONT.H4 : theme.FONT.B1)};
  font-weight: ${({ header }) => !header && '600'};
`;

export const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.COLOR.WHITE};
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
`;
