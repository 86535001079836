import { ArrowUpIcon, Icon, isMobileAtom, useRecoil } from '@orcar/common-d2c';
import styled from 'styled-components';

type TopButtonProps = {
  bottomPosition?: string;
};

const TopButton = ({ bottomPosition }: TopButtonProps) => {
  const { recoilValue: isMobile } = useRecoil(isMobileAtom);

  const handlePosition = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Wrapper
      onClick={handlePosition}
      isMobile={isMobile}
      bottom={bottomPosition}
    >
      <Icon src={ArrowUpIcon} width={10} height={6} margin='0 0 7px' />
      TOP
    </Wrapper>
  );
};

export { TopButton };

const Wrapper = styled.div<{ isMobile: boolean; bottom?: string }>`
  position: fixed;
  bottom: ${({ isMobile, bottom }) =>
    bottom ? bottom : isMobile ? '21px' : '35px'};
  right: ${({ isMobile }) => (isMobile ? '21px' : '35px')};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ isMobile }) => (isMobile ? '40px' : '40px')};
  height: ${({ isMobile }) => (isMobile ? '40px' : '40px')};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.COLOR.WHITE};
  font-size: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  z-index: 5;
`;
