import { InsuranceName } from '@orcar/common';
import {
  ALERT_MESSAGE_TYPE,
  authAtom,
  CarInfo,
  CarOption,
  convertToKST,
  convertToLocalTime,
  getAvailableVehicleOptionsArray,
  getDiscountList,
  getMinDriverAge,
  getMinDriverExperience,
  getSureUserId,
  INSURANCE_TYPE,
  isMobileAtom,
  ListStyle,
  MustRead,
  MUTATION_KEY,
  OptionalService,
  OrderConfirmationInsuranceInfo,
  PAGE_NAME,
  PaymentInfoCard,
  RentalCondition,
  SESSION_STORAGE_KEY,
  TabArea,
  TOAST_MESSAGE_TYPE,
  useCreatePreReservation,
  useGetCompanyInfo,
  useGetDiscountPolicy,
  useGetVehicleModel,
  useRecoil,
  useTabHandler,
  useToast,
  useVehicleTag,
} from '@orcar/common-d2c';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { CompanyInfo } from '@/components/reservation/CompanyInfo';
import RentPolicy from '@/components/reservation/RentPolicy';
import { CAR_SHEET_COMPANY } from '@/constants/common';
import { TopButton } from '@/pages/search/components/TopButton';
import { convertTimeStringToNumber, isValidRentTime } from '@/utils/date';

const OrderConfirmationPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const pickUpAt = searchParams.get('pickUpAt') || '';
  const dropOffAt = searchParams.get('dropOffAt') || '';
  const insuranceName = searchParams.get('insuranceName') || '';
  const sureUserId = getSureUserId();

  const [addInsurance, setAddInsurance] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    pickUpAt: dayjs().format(),
    dropOffAt: dayjs().format(),
  });

  const { data: vehicleModelData, isError } = useGetVehicleModel({
    id: Number(sessionStorage.getItem(SESSION_STORAGE_KEY.PRODUCT_ID)),
    params: {
      pickUpAt,
      dropOffAt,
      insuranceName: insuranceName as InsuranceName,
      isFullCoverage: addInsurance,
      sureUserId: sureUserId ? Number(sureUserId) : undefined,
    },
  });
  const { data: companyData } = useGetCompanyInfo();
  const { data: discountPolicy } = useGetDiscountPolicy();

  const { mutate: createPreReservation } = useCreatePreReservation();

  const isMutatingCreatePreReservation =
    queryClient.isMutating({
      mutationKey: [MUTATION_KEY.CREATE_PRE_RESERVATION],
    }) > 0;

  const { options } = useVehicleTag(vehicleModelData);
  const { recoilValue: isMobile } = useRecoil(isMobileAtom);
  const { recoilValue: user } = useRecoil(authAtom);

  const { tabRef, selectedTabIdx, tabHandler } = useTabHandler();
  const { addToast } = useToast();

  const isSuperInsurance =
    vehicleModelData?.insurance.insuranceName === INSURANCE_TYPE.SUPER;
  const isPerfectInsurance =
    vehicleModelData?.insurance.insuranceName === INSURANCE_TYPE.PERFECT;
  const isNormalInsurance =
    vehicleModelData?.insurance.insuranceName === INSURANCE_TYPE.NORMAL;
  const canAddUnlimitedOption =
    vehicleModelData?.insurance.insuranceName === INSURANCE_TYPE.NORMAL
      ? !!vehicleModelData?.availableWithInsuranceOption1
      : vehicleModelData?.insurance.insuranceName === INSURANCE_TYPE.PERFECT
      ? !!vehicleModelData?.availableWithInsuranceOption2
      : false;

  const price = !!user
    ? vehicleModelData?.insurance.userFinalPrice || 0
    : vehicleModelData?.insurance.finalPrice || 0;
  const memberDiscountRate = discountPolicy?.discountValue;

  const handleAddUnlimitedInsurance = () => {
    if (addInsurance) {
      addToast({ content: TOAST_MESSAGE_TYPE.REMOVE_UNLIMITED_INSURANCE });
      setAddInsurance(false);
    } else {
      addToast({ content: TOAST_MESSAGE_TYPE.ADD_UNLIMITED_INSURANCE });
      setAddInsurance(true);
    }
  };

  const handlePostReservation = () => {
    if (!vehicleModelData?.id) return;

    createPreReservation(
      {
        vehicleModelId: vehicleModelData?.id,
        pickUpAt: convertToKST(selectedDate.pickUpAt),
        dropOffAt: convertToKST(selectedDate.dropOffAt),
        insuranceName: vehicleModelData.insurance.insuranceName,
      },
      {
        onSuccess: (res) => {
          sessionStorage.setItem(
            SESSION_STORAGE_KEY.PRE_RESERVATION_ID,
            res.id,
          );
          if (addInsurance) {
            sessionStorage.setItem(
              SESSION_STORAGE_KEY.IS_FULL_COVERAGE,
              'true',
            );
          } else {
            sessionStorage.removeItem(SESSION_STORAGE_KEY.IS_FULL_COVERAGE);
          }
          navigate(PAGE_NAME.payment);
        },
        onError: () => {
          alert(ALERT_MESSAGE_TYPE.FAIL_GET_PRODUCT);
          navigate(PAGE_NAME.index);
        },
      },
    );
  };

  useEffect(() => {
    if (!companyData) return;

    const productId = Number(
      sessionStorage.getItem(SESSION_STORAGE_KEY.PRODUCT_ID),
    );

    if (!pickUpAt || !dropOffAt || !insuranceName) {
      alert(ALERT_MESSAGE_TYPE.NOT_ALLOWED_ACCESS);
      navigate(PAGE_NAME.index);
      return;
    }

    const pickUpAtInLocalTime = convertToLocalTime(pickUpAt);
    const dropOffAtInLocalTime = convertToLocalTime(dropOffAt);

    const pickUpOpenTime = convertTimeStringToNumber(
      companyData.pickUpOpenTime,
    );
    const pickUpCloseTime = convertTimeStringToNumber(
      companyData.pickUpCloseTime,
    );
    const dropOffOpenTime = convertTimeStringToNumber(
      companyData.dropOffOpenTime,
    );
    const dropOffCloseTime = convertTimeStringToNumber(
      companyData.dropOffCloseTime,
    );

    if (!pickUpAt || !dropOffAt || !insuranceName) {
      alert(ALERT_MESSAGE_TYPE.NOT_ALLOWED_ACCESS);
      navigate(PAGE_NAME.index);
      return;
    }

    if (
      !pickUpAtInLocalTime ||
      !dropOffAtInLocalTime ||
      !isValidRentTime(pickUpAt, dropOffAt, {
        pickUpOpenTime,
        pickUpCloseTime,
        dropOffOpenTime,
        dropOffCloseTime,
      })
    ) {
      alert(ALERT_MESSAGE_TYPE.NOT_ALLOWED_ACCESS);
      navigate(PAGE_NAME.index);
      return;
    }
    if (!productId) {
      alert(ALERT_MESSAGE_TYPE.NOT_ALLOWED_ACCESS);
      navigate(PAGE_NAME.index);
      return;
    }
    setSelectedDate({
      pickUpAt: pickUpAtInLocalTime,
      dropOffAt: dropOffAtInLocalTime,
    });

    if (addInsurance) return setAddInsurance(true);
  }, [pickUpAt, dropOffAt, insuranceName, companyData]);

  useEffect(() => {
    if (isError) {
      alert(ALERT_MESSAGE_TYPE.FAIL_GET_PRODUCT);
      navigate(PAGE_NAME.index);
    }
  }, [isError]);

  return (
    <LayoutWrapper isMobile={isMobile}>
      <ContentWrapper isMobile={isMobile}>
        <CarInfo
          pickUpAt={selectedDate.pickUpAt}
          dropOffAt={selectedDate.dropOffAt}
          carImageList={vehicleModelData?.imageUrls}
          modelName={vehicleModelData?.name || ''}
          options={options}
          isMobile={isMobile}
        />
        <div>
          <TabArea
            selectedTabIdx={selectedTabIdx}
            tabHandler={tabHandler}
            isMobile={isMobile}
          />
          <WhiteBgWrapper isMobile={isMobile}>
            <RentalCondition
              hasPadding
              tabRef={tabRef}
              isMobile={isMobile}
              company={companyData}
              numberOfSeat={vehicleModelData?.seat}
              driverAge={getMinDriverAge(
                vehicleModelData,
                vehicleModelData?.insurance.insuranceName,
              )}
              drivingExperience={getMinDriverExperience(
                vehicleModelData,
                vehicleModelData?.insurance.insuranceName,
              )}
            />
            <OrderConfirmationInsuranceInfo
              isMobile={isMobile}
              insuranceId={vehicleModelData?.insurance.insuranceName || ''}
              fullCoverageInsurancePrice={
                vehicleModelData?.insurance.fullCoverageInsurancePrice || 0
              }
              onClick={handleAddUnlimitedInsurance}
              addInsurance={addInsurance && !isSuperInsurance}
              companyName={companyData?.name || ''}
              canAddUnlimitedOption={canAddUnlimitedOption}
              coverageLimit={
                isPerfectInsurance || isSuperInsurance
                  ? vehicleModelData?.insurance2?.limit || ''
                  : isNormalInsurance
                  ? vehicleModelData?.insurance1?.limit || ''
                  : ''
              }
              deductible={
                isPerfectInsurance || isSuperInsurance
                  ? vehicleModelData?.insurance2?.indemnification || ''
                  : isNormalInsurance
                  ? vehicleModelData?.insurance1?.indemnification || ''
                  : ''
              }
              lossOfUseFee={
                isPerfectInsurance
                  ? vehicleModelData?.insurance2?.compensation || ''
                  : isNormalInsurance
                  ? vehicleModelData?.insurance1?.compensation || ''
                  : ''
              }
            />
            <CarOption
              hasPadding
              isMobile={isMobile}
              options={getAvailableVehicleOptionsArray(vehicleModelData)}
            />
          </WhiteBgWrapper>
          <WhiteBgWrapper isMobile={isMobile}>
            <OptionalService
              hasPadding
              isMobile={isMobile}
              carSheetCompanyName={CAR_SHEET_COMPANY.name}
              carSheetCompanyURL={CAR_SHEET_COMPANY.url}
              carSheetCompanyTelNumber={CAR_SHEET_COMPANY.tel}
            />
            <MustRead
              tabRef={tabRef}
              isMobile={isMobile}
              rentPolicies={[
                '모든 차량은 금연차량입니다. (흡연 시 차량 크리닝비 10만원이 청구됩니다.)',
                '차량 대여 시 실물면허증 소지 (2종 보통 면허 이상 필요, 차종에 따라 1종 보통 면허 필요)',
                '제 2운전자도 대여 조건을 모두 충족해야하며, 계약서 작성 후 보험 효력이 발생합니다.',
                '반려동물, 낚시용품 지참 시 대여 불가능하며, 대여 조건 미달로 렌트가 불가한 경우, 추가 수수료가 발생할 수 있습니다.',
                '악취/오염 발생 시 정도에 따라 실내 크리닝 비용이 추가로 청구됩니다. (최대 30만원)',
                '외국인의 경우 064-710-1600 으로 전화 부탁드립니다. 예약 전 대여자격 확인 및 안내가 필수입니다.',
              ]}
            />
            {/* TODO: 취소 수수료 규정 추가 */}
          </WhiteBgWrapper>
          <WhiteBgWrapper isMobile={isMobile}>
            <CompanyInfo
              company={companyData}
              isMobile={isMobile}
              tabRef={tabRef}
              hasPadding
            />
            <RentPolicy
              name={companyData?.name || ''}
              rentPolicies={companyData?.rentalRules || ''}
              isMobile={isMobile}
              hasPadding
            />
          </WhiteBgWrapper>
        </div>
      </ContentWrapper>
      {isMobile ? (
        <div className='fixed bottom-0 z-10 flex h-fit w-full flex-col items-start rounded-t-l shadow-060'>
          <PaymentInfoCard
            isMobile
            isLogin={!!user}
            insurance={vehicleModelData?.insurance.insuranceName || ''}
            addInsurance={addInsurance}
            listPrice={vehicleModelData?.insurance.listPrice || 0}
            price={price}
            discountList={getDiscountList(vehicleModelData?.tempDiscounts)}
            fullCoverageInsurancePrice={
              vehicleModelData?.insurance.fullCoverageInsurancePrice || 0
            }
            memberDiscountRate={memberDiscountRate}
            isMemberDiscountActive={!!discountPolicy?.isActive}
            buttonLoading={isMutatingCreatePreReservation}
            isOnSitePaymentOnly
            onClick={handlePostReservation}
          />
        </div>
      ) : (
        <div className='sticky top-36 h-fit w-96 rounded-m shadow-050'>
          <PaymentInfoCard
            isMobile={false}
            isLogin={!!user}
            insurance={vehicleModelData?.insurance.insuranceName || ''}
            addInsurance={addInsurance}
            listPrice={vehicleModelData?.insurance.listPrice || 0}
            price={price}
            discountList={getDiscountList(vehicleModelData?.tempDiscounts)}
            fullCoverageInsurancePrice={
              vehicleModelData?.insurance.fullCoverageInsurancePrice || 0
            }
            memberDiscountRate={memberDiscountRate}
            isMemberDiscountActive={!!discountPolicy?.isActive}
            buttonLoading={isMutatingCreatePreReservation}
            isOnSitePaymentOnly
            onClick={handlePostReservation}
          />
        </div>
      )}
      <TopButton bottomPosition={isMobile ? '100px' : undefined} />{' '}
    </LayoutWrapper>
  );
};

export default OrderConfirmationPage;

const LayoutWrapper = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: ${({ isMobile }) => isMobile && 'column'};
  gap: 24px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '1200px')};
  ${({ isMobile }) => isMobile && 'padding: 40px 0 90px'};
`;

const ContentWrapper = styled.div<{
  isMobile?: boolean;
}>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '792px')};
  scroll-margin: ${({ isMobile }) => (isMobile ? '110px' : '150px')};
`;

const WhiteBgWrapper = styled.div<{
  isMobile: boolean;
}>`
  width: 100%;
  gap: 30px;
  padding: 0 28px;
  margin-bottom: ${({ isMobile }) => (isMobile ? '10px' : '24px')};
  background-color: ${({ theme }) => theme.COLOR.WHITE};
  background-color: white;
  border-radius: ${({ theme }) => theme.BORDER_RADIUS.LEVEL_02};

  & > *:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  }

  & li {
    ${ListStyle}
  }
`;
