import { isMobileAtom, Styles, useRecoil } from '@orcar/common-d2c';
import styled from 'styled-components';
import Logo from '@/assets/img/logo_rainbow-grey-v2.svg';
import CompanyInfoSection from './CompanyInfoSection';
import LinkButtonSection from './LinkButtonSection';
import * as S from '../styles';

const MobileFooter = () => {
  const { recoilValue: isMobile } = useRecoil(isMobileAtom);

  return (
    <Wrapper>
      <TopSection>
        <img src={Logo} alt='mobile-footer로고' />
        <LinkButtonSection isMobile={isMobile} />
        <CompanyInfoSection />
      </TopSection>
      <S.BottomSection isMobile={isMobile}>
        <S.InnerWrapper bottom isMobile={isMobile}>
          <S.InfoContent color={Styles.COLOR.GREY_01}>
            Copyright © 2015 www.rainbowrent.co.kr All Rights Reserved.
          </S.InfoContent>
        </S.InnerWrapper>
      </S.BottomSection>
    </Wrapper>
  );
};

export default MobileFooter;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 0 0;
  border-top: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
`;

const TopSection = styled.div`
  padding: 0 20px;
`;
