import { VehicleModelWithInsurancesForD2CDto } from '@orcar/common';
import {
  CAR_TYPE,
  convertToKST,
  filterOptionAtom,
  PAGE_NAME,
  SearchParamsType,
  SearchResultBlock,
  SearchResultBlockDivider,
  SearchResultBlockFooter,
  SearchResultBlockHeader,
  SESSION_STORAGE_KEY,
  TabList,
  useRecoil,
} from '@orcar/common-d2c';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchFilterNull from './vehicleCard/SearchFilterNull';

export type SearchResultProps = {
  searchOptions: SearchParamsType;
  vehicleModelList?: VehicleModelWithInsurancesForD2CDto[];
};

const SearchResult = ({
  searchOptions,
  vehicleModelList,
}: SearchResultProps) => {
  const navigate = useNavigate();

  const [fadeInTrigger, setFadeInTrigger] = useState(false);
  const { recoilValue: recoilFilterOption } = useRecoil(filterOptionAtom);

  useEffect(() => {
    setFadeInTrigger(true);
    setTimeout(() => {
      setFadeInTrigger(false);
    }, 100);
  }, [recoilFilterOption]);

  const handleSelectProducts = async (id: number, insuranceName: string) => {
    sessionStorage.setItem(SESSION_STORAGE_KEY.PRODUCT_ID, String(id));
    const pickUpAt = convertToKST(searchOptions.pickUpAt);
    const dropOffAt = convertToKST(searchOptions.dropOffAt);

    navigate(
      `${PAGE_NAME.order_confirmation}?pickUpAt=${pickUpAt}&dropOffAt=${dropOffAt}&insuranceName=${insuranceName}`,
    );
  };
  return (
    <div className='flex w-[1071px] flex-col justify-start rounded-t-m bg-grey-000'>
      <div className='sticky top-[200px] z-10 rounded-m bg-grey-000'>
        <SearchResultBlockHeader />
        <div className='border-x border-grey-010 px-5 py-[6px]'>
          <TabList tabList={CAR_TYPE} />
        </div>
      </div>
      {vehicleModelList?.length === 0 ? (
        <SearchFilterNull />
      ) : (
        <div className='flex w-[1071px] flex-col justify-start'>
          {vehicleModelList?.map((vehicleModel, idx) => {
            return (
              <Fragment key={vehicleModel.id}>
                <SearchResultBlock
                  platform='desktop'
                  fadeInTrigger={fadeInTrigger}
                  vehicleModel={vehicleModel}
                  unavailable={vehicleModel.availableCount === 0}
                  handleClickCard={handleSelectProducts}
                />
                {idx < vehicleModelList.length - 1 && (
                  <SearchResultBlockDivider />
                )}
              </Fragment>
            );
          })}
          <SearchResultBlockFooter />
        </div>
      )}
    </div>
  );
};

export default SearchResult;
