import { keyframes } from 'styled-components';

export const fadeUp = keyframes`
0%, 100% {
  transform: translateY(30%);
  opacity: 0;
}
30%, 70% {
  transform: translateY(0);
  opacity: 1;
}
`;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const slideUp = keyframes`
from {
  transform: translateY(100%);
}
to {
  transform: translateY(0);
}
`;

export const slideIn = keyframes`
from {
  transform: translateX(-100%);
}
to {
  transform: translateX(0);
}
`;

export const loadingAnimation = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;
