import { AxiosException, D2CErrorCode } from '@orcar/common';
import {
  Button,
  PAGE_NAME,
  SearchOption,
  selectOptionAtom,
  Styles,
  useDetectMobile,
  useRecoil,
} from '@orcar/common-d2c';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';

type SearchNullProps = {
  error: AxiosError<AxiosException, any> | null;
  onMobileBottomSheetOpen?: () => void;
};

const SearchNull = ({ error, onMobileBottomSheetOpen }: SearchNullProps) => {
  const navigate = useNavigate();

  const { isMobile } = useDetectMobile();
  const { setRecoilState: setRecoilOption } = useRecoil(selectOptionAtom);

  const handleResearch = () => {
    const errorCode = error?.response?.data?.code;

    switch (errorCode) {
      case D2CErrorCode.EXCEED_MAX_RESERVATION_DURATION:
      case D2CErrorCode.EXCEEDED_PICK_UP_TIME_DEADLINE:
        return setRecoilOption(SearchOption.Date);
      case D2CErrorCode.MIN_LEAD_TIME_NOT_MET:
      case D2CErrorCode.UNAVAILABLE_OPERATION_TIME:
        return setRecoilOption(SearchOption.Time);

      default:
        navigate(PAGE_NAME.index);
    }
  };

  const handleMobileResearch = () => {
    if (onMobileBottomSheetOpen) {
      const errorCode = error?.response?.data?.code;

      switch (errorCode) {
        case D2CErrorCode.EXCEED_MAX_RESERVATION_DURATION:
        case D2CErrorCode.EXCEEDED_PICK_UP_TIME_DEADLINE:
        case D2CErrorCode.MIN_LEAD_TIME_NOT_MET:
        case D2CErrorCode.UNAVAILABLE_OPERATION_TIME:
          return onMobileBottomSheetOpen();

        default:
          navigate(PAGE_NAME.index);
      }
    }
  };

  return (
    <S.SearchNullWrapper isMobile={isMobile}>
      <S.SearchNullMessageText isMobile={isMobile}>
        선택하신 검색 조건에 맞는 렌터카가 없어요.
      </S.SearchNullMessageText>

      <S.SearchNullMessageText sub isMobile={isMobile}>
        검색 조건을 조금 바꿔보시겠어요?
      </S.SearchNullMessageText>
      <S.SearchNullButtonWrapper isMobile={isMobile}>
        <Button
          width={isMobile ? '100%' : '282px'}
          height={isMobile ? '60px' : '43px'}
          font={isMobile ? Styles.FONT.H4 : Styles.FONT.B1}
          onClick={isMobile ? handleMobileResearch : handleResearch}
          borderRadius='10px'
        >
          검색조건 변경하기
        </Button>
      </S.SearchNullButtonWrapper>
    </S.SearchNullWrapper>
  );
};

export default SearchNull;
