import { Nullable } from '@orcar/common';
import {
  Icon,
  LOADING_MESSAGE_ARR,
  Modal,
  SearchIcon,
  Styles,
} from '@orcar/common-d2c';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const SearchLoading = () => {
  const [loadingText, setLoadingText] = useState<Nullable<string>>(null);

  useEffect(() => {
    setLoadingText(LOADING_MESSAGE_ARR[0]);

    let index = 1;
    const textTimer = setInterval(function () {
      if (index == LOADING_MESSAGE_ARR.length) clearInterval(textTimer);
      else setLoadingText(LOADING_MESSAGE_ARR[index++]);
    }, 3000);

    return () => {
      clearInterval(textTimer);
    };
  }, []);

  return (
    <Modal hasClose={false} topLevel scrollY={0}>
      <Wrapper>
        <Icon
          src={SearchIcon}
          width={60}
          height={60}
          iconcolor={Styles.SVG_FILTER.WHITE}
        />
        <LoadingText>{loadingText}</LoadingText>
      </Wrapper>
    </Modal>
  );
};

export default SearchLoading;

const Wrapper = styled.div`
  position: absolute;
  display: grid;
  justify-items: center;
  grid-gap: 33px;
  width: 894px;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
`;

const Loader = styled.div`
  width: 120px;
  height: 120px;
  background-color: #fff;
`;

const LoadingText = styled.p`
  ${({ theme }) => theme.FONT.H3};
  color: ${({ theme }) => theme.COLOR.WHITE};
`;
