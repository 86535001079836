import { Nullable } from '@orcar/common';
import {
  BottomSheet,
  Button,
  DriverOptionType,
  OPTION_TITLES,
  SearchOptionEnum,
  SHOW_TIME_WARNING_FROM,
  Styles,
  Tab,
} from '@orcar/common-d2c';
import { Dayjs } from 'dayjs';
import { type Dispatch, type SetStateAction } from 'react';
import styled from 'styled-components';
import { MobileDateSelection } from '../dateSelection/MobileDateSelection';
import { DriverInfoSelection } from '../driverInfoSelection/DriverInfoSelection';
import { TimeSelection } from '../timeSelection/TimeSelection';

interface MobileOptionSearchModalProps {
  showModal: boolean;
  onClose: (option?: { onRouterBack?: boolean }) => void;
  searchOptionIdx: number;
  setSearchOptionIdx: Dispatch<SetStateAction<number>>;
  searchOptions: {
    pickUpDate: Nullable<Dayjs>;
    setPickUpDate: Dispatch<SetStateAction<Nullable<Dayjs>>>;
    dropOffDate: Nullable<Dayjs>;
    setDropOffDate: Dispatch<SetStateAction<Nullable<Dayjs>>>;
    pickUpTimeValue: number;
    setPickUpTimeValue: Dispatch<SetStateAction<number>>;
    dropOffTimeValue: number;
    setDropOffTimeValue: Dispatch<SetStateAction<number>>;
    driverAge: Nullable<DriverOptionType>;
    setDriverAge: Dispatch<SetStateAction<Nullable<DriverOptionType>>>;
    numberOfPeople: Nullable<DriverOptionType>;
    setNumberOfPeople: Dispatch<SetStateAction<Nullable<DriverOptionType>>>;
    drivingExperience: Nullable<DriverOptionType>;
    setDrivingExperience: Dispatch<SetStateAction<Nullable<DriverOptionType>>>;
  };
  isNextBtnDisabled: boolean;
  isCheckTimeSelected: boolean;
  handleNextBtn: () => void;
  handleTabBtn: (idx: number) => void;
}

const MobileOptionSearchModal = ({
  searchOptions,
  showModal,
  onClose,
  searchOptionIdx,
  setSearchOptionIdx,
  isNextBtnDisabled,
  isCheckTimeSelected,
  handleNextBtn,
  handleTabBtn,
}: MobileOptionSearchModalProps) => {
  const {
    pickUpDate,
    setPickUpDate,
    dropOffDate,
    setDropOffDate,
    pickUpTimeValue,
    setPickUpTimeValue,
    dropOffTimeValue,
    setDropOffTimeValue,
    driverAge,
    setDriverAge,
    numberOfPeople,
    setNumberOfPeople,
    drivingExperience,
    setDrivingExperience,
  } = searchOptions;
  return (
    <>
      {showModal && (
        <BottomSheet onClose={onClose}>
          <TabWrapper>
            {OPTION_TITLES.map((item, idx) => (
              <Tab
                key={idx}
                isMobile
                selected={idx === searchOptionIdx}
                onClick={() => handleTabBtn(idx)}
                height='57px'
              >
                {item}
              </Tab>
            ))}
          </TabWrapper>
          <SelectionWrapper>
            {searchOptionIdx === 0 ? (
              <MobileDateSelection
                pickUpDate={pickUpDate}
                setPickUpDate={setPickUpDate}
                dropOffDate={dropOffDate}
                setDropOffDate={setDropOffDate}
                isMobile
              />
            ) : searchOptionIdx === 1 ? (
              <TimeSelection
                pickUpTimeValue={pickUpTimeValue}
                setPickUpTimeValue={(value) => {
                  setPickUpTimeValue(value);
                }}
                dropOffTimeValue={dropOffTimeValue}
                setDropOffTimeValue={(value) => {
                  setDropOffTimeValue(value);
                }}
                pickUpDate={pickUpDate}
                dropOffDate={dropOffDate}
                showWarning={
                  (isCheckTimeSelected &&
                    pickUpTimeValue >= SHOW_TIME_WARNING_FROM) ||
                  (isCheckTimeSelected &&
                    dropOffTimeValue >= SHOW_TIME_WARNING_FROM)
                }
                isMobile
              />
            ) : (
              <DriverInfoSelection
                driverAge={driverAge}
                setDriverAge={setDriverAge}
                numberOfPeople={numberOfPeople}
                setNumberOfPeople={setNumberOfPeople}
                drivingExperience={drivingExperience}
                setDrivingExperience={setDrivingExperience}
                isMobile
              />
            )}
          </SelectionWrapper>
          <ButtonWrapper isColumn={searchOptionIdx === SearchOptionEnum.DATE}>
            {searchOptionIdx > 0 && (
              <Button
                onClick={() => setSearchOptionIdx(searchOptionIdx - 1)}
                {...buttonStyle}
                borderColor={Styles.COLOR.GREY_02}
                bgColor={Styles.COLOR.WHITE}
                color={Styles.COLOR.GREY_02}
              >
                뒤로
              </Button>
            )}
            <Button
              className={searchOptionIdx === 2 ? 'searchButton' : undefined}
              width={searchOptionIdx > 0 ? '45%' : '85%'}
              height='60px'
              borderRadius='50px'
              font={Styles.FONT.H4}
              onClick={handleNextBtn}
              disabled={isNextBtnDisabled}
            >
              {searchOptionIdx < 2 ? '다음' : '검색'}
            </Button>
          </ButtonWrapper>
        </BottomSheet>
      )}
    </>
  );
};

export { MobileOptionSearchModal };

const buttonStyle = {
  width: '45%',
  height: '60px',
  borderRadius: '50px',
  font: Styles.FONT.H4,
};

const TabWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 60px;
  ${Styles.FONT.B1};
`;

const SelectionWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100vh;
  padding-top: 35px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const ButtonWrapper = styled.div<{ isColumn: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: ${({ isColumn }) => isColumn && 'column'};
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ isColumn }) => (isColumn ? '129px' : '100px')};
  gap: 8px;
  background-color: ${Styles.COLOR.WHITE};
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const InfoText = styled.p`
  ${Styles.FONT.B2};
  font-weight: 400;
  color: ${Styles.COLOR.GREY_02};
`;
