import { Nullable } from '@orcar/common';
import {
  DRIVER_OPTIONS,
  DriverOptionType,
  useDetectMobile,
} from '@orcar/common-d2c';
import { type Dispatch, type SetStateAction } from 'react';
import DriverOptionSection from './DriverOptionSection';
import * as S from './styles';

const app = import.meta.env.VITE_PACKAGE_NAME;

export type DriverInfoSelectionProps = {
  driverAge: Nullable<DriverOptionType>;
  setDriverAge: Dispatch<SetStateAction<Nullable<DriverOptionType>>>;
  numberOfPeople: Nullable<DriverOptionType>;
  setNumberOfPeople: Dispatch<SetStateAction<Nullable<DriverOptionType>>>;
  drivingExperience: Nullable<DriverOptionType>;
  setDrivingExperience: Dispatch<SetStateAction<Nullable<DriverOptionType>>>;
  isMobile?: boolean;
};

const DriverInfoSelection = ({
  driverAge,
  setDriverAge,
  numberOfPeople,
  setNumberOfPeople,
  drivingExperience,
  setDrivingExperience,
  isMobile,
}: DriverInfoSelectionProps) => {
  const { screenHeight } = useDetectMobile();

  const getDriverOptionText = () => {
    if (isMobile)
      return '연령에 따라 빌릴 수 있는 차량의 종류가 달라질 수 있어요.';
    else return '운전자의 연령을 선택해 주세요.';
  };

  return (
    <S.Wrapper>
      <S.SelectionWrapper isMobile={isMobile} screenHeight={screenHeight}>
        <DriverOptionSection
          title='연령'
          description={getDriverOptionText()}
          options={DRIVER_OPTIONS.driverAge.RAINBOW}
          onSelect={(option) => {
            setDriverAge(option);
          }}
          selectedOption={driverAge}
          isMobile={isMobile}
        />
        <DriverOptionSection
          title='경력'
          description={
            isMobile
              ? '경력에 따라 선택 가능한 보험 및 차량이 다를 수 있어요.'
              : '면허취득일 기준 운전 경력을 선택해 주세요.'
          }
          options={DRIVER_OPTIONS.drivingExperience.RAINBOW}
          onSelect={(option) => {
            setDrivingExperience(option);
          }}
          selectedOption={drivingExperience}
          isMobile={isMobile}
        />
        {/* 섬카 검색페이지에서는 일행 옵션 변경이 가능함 */}
        {
          <DriverOptionSection
            title='일행'
            description='함께 여행할 인원에 적합한 차량을 추천해 드릴게요.'
            options={DRIVER_OPTIONS.numberOfPeople}
            onSelect={(option) => {
              setNumberOfPeople(option);
            }}
            selectedOption={numberOfPeople}
            isMobile={isMobile}
            isLast
          />
        }
      </S.SelectionWrapper>
    </S.Wrapper>
  );
};

export { DriverInfoSelection };
