import {
  FinalConfirmModalText,
  getPrimaryColor,
  isMobileAtom,
  Styles,
  useRecoil,
} from '@orcar/common-d2c';
import styled from 'styled-components';
import { APP_NAME } from '@/constants/common';

export type CancellationTableProps = {
  hasAdditionalDescription?: boolean;
};

const CancellationTable = ({
  hasAdditionalDescription,
}: CancellationTableProps) => {
  const { recoilValue: isMobile } = useRecoil(isMobileAtom);

  return (
    <>
      <CommissionTable isMobile={isMobile}>
        <colgroup>
          <Column col={1} />
          <Column backgroundColor={Styles.COLOR.WHITE} col={2} />
        </colgroup>
        <thead>
          <tr>
            <TableHeader isMobile={isMobile}>취소 시점</TableHeader>
            <TableHeader isMobile={isMobile}>취소 수수료</TableHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableItem isMobile={isMobile}>예약 당일 23시 59분까지</TableItem>
            <TableItem isMobile={isMobile} color={getPrimaryColor(APP_NAME)}>
              수수료 없음
            </TableItem>
          </tr>
          <tr>
            <TableItem isMobile={isMobile}>
              예약 다음날 ~ 인수 7일 전 (인수 168시간 이전)
            </TableItem>
            <TableItem isMobile={isMobile} color={getPrimaryColor(APP_NAME)}>
              수수료 없음
            </TableItem>
          </tr>
          <tr>
            <TableItem isMobile={isMobile}>
              인수 6일 전 ~ 인수 3일 전 (인수 72시간 이전)
            </TableItem>
            <TableItem isMobile={isMobile} color={getPrimaryColor(APP_NAME)}>
              수수료 없음
            </TableItem>
          </tr>
          <tr>
            <TableItem isMobile={isMobile}>
              인수 2일 전 ~ 인수 1일 전 (인수 24시간 이전)
            </TableItem>
            <TableItem isMobile={isMobile} color={getPrimaryColor(APP_NAME)}>
              수수료 없음
            </TableItem>
          </tr>
          <tr>
            <TableItem isMobile={isMobile}>인수시점 24시간 이내</TableItem>
            <TableItem isMobile={isMobile}>수수료 10%</TableItem>
          </tr>
          <tr>
            <TableItem isMobile={isMobile}>대여 당일 No Show</TableItem>
            <TableItem isMobile={isMobile} color={Styles.COLOR.DANGER}>
              환불 불가
            </TableItem>
          </tr>
        </tbody>
      </CommissionTable>
      {hasAdditionalDescription && (
        <FinalConfirmModalText sub isMobile={isMobile}>
          차량 및 일정 변경은 불가하며 반드시 취소 후 재예약 부탁드립니다.
        </FinalConfirmModalText>
      )}
    </>
  );
};

export default CancellationTable;

const CommissionTable = styled.table<{
  isMobile: boolean;
}>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  margin: ${({ isMobile }) => (isMobile ? '20px 0 12px' : '40px 0 12px')}};
  border-collapse: collapse;
`;

const TableHeader = styled.th<{ isMobile: boolean; padding?: string }>`
  text-align: left;
  padding: ${({ isMobile, padding }) =>
    padding ? padding : isMobile ? '6px 8px' : '8px 12px'};
  ${({ theme, isMobile }) =>
    isMobile ? theme.NEW_FONT.B2 : theme.NEW_FONT.B1};
  font-weight: 600;
  line-height: ${({ isMobile }) => (isMobile ? '24px' : '27px')};
  color: ${({ theme }) => theme.COLOR.GREY_01};
  background-color: ${({ theme }) => theme.COLOR.GREY_BG};
  border-top: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  border-bottom: 1px solid ${({ theme }) => theme.COLOR.GREY_05};

  &:first-child {
    border-right: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  }
`;

const TableItem = styled.td<{
  color?: string;
  padding?: string;
  isMobile: boolean;
}>`
  padding: ${({ isMobile, padding }) =>
    padding ? padding : isMobile ? '6px 8px' : '8px 12px'};
  text-align: left;
  ${({ isMobile, theme }) =>
    isMobile ? theme.NEW_FONT.B2 : theme.NEW_FONT.B1};
  font-weight: ${({ isMobile }) => isMobile && '400'};
  line-height: ${({ isMobile }) => (isMobile ? '24px' : '27px')};
  color: ${({ color, theme }) => (color ? color : theme.COLOR.GREY_01)};
  border-bottom: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  border-left: 1px solid ${({ theme }) => theme.COLOR.GREY_05};

  &:first-child {
    border-left: none;
  }
`;

const Column = styled.col<{
  backgroundColor?: string;
  col?: number;
}>`
  width: ${({ col }) => {
    switch (col) {
      case 1:
        return '70%';
      case 2:
        return '30%';
    }
  }};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.COLOR.GREY_BG};
`;
