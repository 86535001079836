import { Nullable } from '@orcar/common';
import {
  convertToKST,
  Dropdown,
  isKST,
  OptionLabel,
  useGetCompanyInfo,
} from '@orcar/common-d2c';
import dayjs, { Dayjs } from 'dayjs';
import { type Dispatch, type SetStateAction } from 'react';
import {
  getAvailablePickUpTimeOptions,
  getRentalOperatingHours,
  getTimeOptions,
} from '@/utils/date';
import * as S from './styles';

export type TimeSelectionProps = {
  pickUpTimeValue: number;
  setPickUpTimeValue: Dispatch<SetStateAction<number>>;
  dropOffTimeValue: number;
  setDropOffTimeValue: Dispatch<SetStateAction<number>>;
  pickUpDate: Nullable<Dayjs>;
  dropOffDate: Nullable<Dayjs>;
  showWarning: boolean;
  isMobile?: boolean;
};

const TimeSelection = ({
  pickUpTimeValue,
  setPickUpTimeValue,
  dropOffTimeValue,
  setDropOffTimeValue,
  pickUpDate,
  dropOffDate,
  showWarning,
  isMobile,
}: TimeSelectionProps) => {
  const todayInKST = dayjs().tz('Asia/seoul');
  const pickUpAtInKST = dayjs(convertToKST(pickUpDate?.format())).tz(
    'Asia/seoul',
  );
  const isToday = dayjs(pickUpAtInKST).isSame(todayInKST, 'date');

  const { data: companyData } = useGetCompanyInfo();
  const rentalOperatingHours = getRentalOperatingHours(companyData);

  const pickUpTimeOptions = getTimeOptions('pickUp', rentalOperatingHours);
  const dropOffTimeOptions = getTimeOptions('dropOff', rentalOperatingHours);
  const availablePickUpTimeOptions =
    getAvailablePickUpTimeOptions(rentalOperatingHours);

  const pickUpOptions = isToday
    ? availablePickUpTimeOptions
    : pickUpTimeOptions;

  const dropOffOptions = pickUpDate?.isSame(dropOffDate, 'date')
    ? dropOffTimeOptions.filter((option) => option.value > pickUpTimeValue)
    : dropOffTimeOptions;

  return (
    <S.Wrapper isMobile={isMobile}>
      <div>
        <S.LabelWrapper>
          <OptionLabel
            title='대여 시간'
            description={
              isMobile
                ? `차량을 대여할 시간을 선택해 주세요. ${
                    !isKST ? '(한국 시간 기준)' : ''
                  }`
                : `여행의 시작! 차를 인수하고자 하는 시간대를 선택해주세요. 
                ${!isKST ? '(한국 시간 기준)' : ''}`
            }
          />
          <Dropdown
            options={pickUpOptions}
            selectedValue={pickUpTimeValue}
            onSelect={setPickUpTimeValue}
            isMobile={isMobile}
          />
        </S.LabelWrapper>
      </div>
      <div>
        <S.LabelWrapper>
          <OptionLabel
            title='반납 시간'
            description={
              isMobile
                ? `차량을 반납할 시간을 선택해 주세요. ${
                    !isKST ? '(한국 시간 기준)' : ''
                  }`
                : `즐거운 여행의 끝! 차를 반납하고자 하는 시간대를 선택해주세요.
                ${!isKST ? '(한국 시간 기준)' : ''}`
            }
          />
          <Dropdown
            options={dropOffOptions}
            selectedValue={dropOffTimeValue}
            onSelect={setDropOffTimeValue}
            isMobile={isMobile}
          />
        </S.LabelWrapper>
      </div>
    </S.Wrapper>
  );
};

export { TimeSelection };
