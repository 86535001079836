import { Fuel, Nullable, VehicleModelType } from '@orcar/common';
import {
  Button,
  convertToKST,
  filterInsuranceAtom,
  filterOptionAtom,
  getSureUserId,
  isMobileAtom,
  MODAL_TYPE,
  PAGE_NAME,
  SearchParamsType,
  setStaticDate,
  Styles,
  useGetVehicleModels,
  useRecoil,
} from '@orcar/common-d2c';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MobileHomeBanner from '@/assets/img/img_banner-mobile-temporary.svg';
import HomeBanner from '@/assets/img/img_banner-temporary.svg';
import RainbowHomeMobileBanner from '@/assets/img/img_rainbow_home_banner_mobile.png';
import RainbowHomePcBanner from '@/assets/img/img_rainbow_home_banner_pc.png';
import { useSearchOptions } from '@/hooks/useSearchOptions';
import RecommendSection from './components/recommendedCar/RecommendSection';
import { MobileOptionSearchModal } from './components/searchModal/MobileOptionSearchModal';
import { SearchModal } from './components/searchModal/SearchModal';
import * as S from './styles';

const HomePage = () => {
  const navigate = useNavigate();
  const sureUserId = getSureUserId();

  const [isModalOpen, setIsModalOpen] = useState<Nullable<string>>(null);

  const { recoilValue: isMobile } = useRecoil(isMobileAtom);
  const {
    recoilState: [recoilFilterOption, setRecoilFilterOption],
    resetRecoilState: resetFilterOption,
  } = useRecoil(filterOptionAtom);
  const { resetRecoilState: resetFilterInsurance } =
    useRecoil(filterInsuranceAtom);

  const { data: vehicleModelList } = useGetVehicleModels({
    params: {
      pickUpAt: convertToKST(setStaticDate('pickUp')),
      dropOffAt: convertToKST(setStaticDate('dropOff')),
      driverAge: undefined,
      numberOfPeople: undefined,
      drivingExperience: undefined,
      sureUserId: sureUserId ? Number(sureUserId) : undefined,
    },
  });

  const handleSubmitSearch = (params?: SearchParamsType) => {
    navigate({
      pathname: PAGE_NAME.search,
      search: new URLSearchParams({
        pickUpAt: convertToKST(params?.pickUpAt),
        dropOffAt: convertToKST(params?.dropOffAt),
        driverAge: params?.driverAge || '',
        drivingExperience: params?.drivingExperience || '',
        numberOfPeople: params?.numberOfPeople || '',
      }).toString(),
    });
    if (isMobile) {
      setIsModalOpen(null);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(null);
  };

  const {
    searchOptions,
    searchOptionIdx,
    setSearchOptionIdx,
    isNextBtnDisabled,
    isCheckTimeSelected,
    indicatorTexts,
    handleClickIndicator,
    handleNextButton,
    handleTabButton,
  } = useSearchOptions({
    onSubmit: handleSubmitSearch,
    onModalClose: handleCloseModal,
  });

  const handleOpenModal = () => {
    setIsModalOpen(isMobile ? MODAL_TYPE.MOBILE_SEARCH : MODAL_TYPE.WEB_SEARCH);
  };

  const handleRecommendCar = (type: string) => {
    const pickUpAt = convertToKST(searchOptions.pickUpDate?.format());
    const dropOffAt = convertToKST(searchOptions.dropOffDate?.format());

    navigate({
      pathname: PAGE_NAME.search,
      search: `?pickUpAt=${pickUpAt}&dropOffAt=${dropOffAt}`,
    });

    if (Fuel.EV === type) {
      setRecoilFilterOption({
        ...recoilFilterOption,
        carFuelList: [Fuel.EV],
        carTypeList: [VehicleModelType.EV],
      });
    } else {
      setRecoilFilterOption({
        ...recoilFilterOption,
        carTypeList: [type],
      });
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      resetFilterOption();
      resetFilterInsurance();
    }
  }, [location.pathname]);

  return (
    <S.Wrapper $isMobile={isMobile}>
      <S.BackgroundImageWrapper $isMobile={isMobile}>
        <img
          src={isMobile ? RainbowHomeMobileBanner : RainbowHomePcBanner}
          alt='무지개렌트카 홈 배너'
          width={isMobile ? '100%' : '1200px'}
          height={isMobile ? '100%' : '380px'}
          style={{
            objectFit: isMobile ? 'cover' : undefined,
            borderRadius: isMobile ? '0' : '10px',
          }}
        />
      </S.BackgroundImageWrapper>
      <S.Text
        hidden={isModalOpen === MODAL_TYPE.WEB_SEARCH}
        $isMobile={isMobile}
      >
        즐거운 제주도 여행! <br />
        <S.Text $bold>렌터카 예약</S.Text>을 도와드릴게요
      </S.Text>
      <S.SearchButtonWrapper
        isOpen={isModalOpen === MODAL_TYPE.WEB_SEARCH}
        className='homeSearchBar'
        onClick={handleOpenModal}
        $isMobile={isMobile}
      >
        <S.ButtonText $isMobile={isMobile}>여행 일정을 알려주세요</S.ButtonText>
        <Button
          width={isMobile ? '70px' : '90px'}
          height={isMobile ? '36px' : '48px'}
          borderRadius={isMobile ? '3px' : '6px'}
          font={isMobile ? Styles.NEW_FONT.B1 : Styles.NEW_FONT.H4}
        >
          검색
        </Button>
      </S.SearchButtonWrapper>

      <RecommendSection
        vehicleModelList={vehicleModelList}
        onClick={handleRecommendCar}
        isMobile={isMobile}
      />

      <S.BannerSection $isMobile={isMobile}>
        <S.SectionTitle>무지개 공지사항</S.SectionTitle>
      </S.BannerSection>

      <S.BannerWrapper $isMobile={isMobile}>
        <S.ImgWrapper>
          <img
            src={isMobile ? MobileHomeBanner : HomeBanner}
            alt='무지개렌트카 홈 리뉴얼 안내 배너'
          />
        </S.ImgWrapper>
      </S.BannerWrapper>
      <MobileOptionSearchModal
        showModal={isModalOpen === MODAL_TYPE.MOBILE_SEARCH}
        onClose={handleCloseModal}
        searchOptionIdx={searchOptionIdx}
        setSearchOptionIdx={setSearchOptionIdx}
        searchOptions={searchOptions}
        isNextBtnDisabled={isNextBtnDisabled}
        isCheckTimeSelected={isCheckTimeSelected}
        handleNextBtn={handleNextButton}
        handleTabBtn={handleTabButton}
      />
      <SearchModal
        showModal={isModalOpen === MODAL_TYPE.WEB_SEARCH}
        onClose={() => setIsModalOpen(null)}
        searchOptions={searchOptions}
        handleClickIndicator={handleClickIndicator}
        handleNextButton={handleNextButton}
        searchOptionIdx={searchOptionIdx}
        setSearchOptionIdx={setSearchOptionIdx}
        indicatorTexts={indicatorTexts}
        isNextBtnDisabled={isNextBtnDisabled}
        isCheckTimeSelected={isCheckTimeSelected}
      />
    </S.Wrapper>
  );
};

export default HomePage;
