import { Nullable } from '@orcar/common';
import {
  Button,
  convertToKST,
  Modal,
  PAGE_NAME,
  SearchOption,
  SearchParamsType,
  SHOW_TIME_WARNING_FROM,
} from '@orcar/common-d2c';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSearchOptions } from '@/hooks/useSearchOptions';
import { DateSelection } from '@/pages/home/components/dateSelection/DateSelection';
import { DriverInfoSelection } from '@/pages/home/components/driverInfoSelection/DriverInfoSelection';
import {
  BtnAreaWrapper,
  ModalWrapper,
} from '@/pages/home/components/searchModal/SearchModal';
import { TimeSelection } from '@/pages/home/components/timeSelection/TimeSelection';

type SelectModalProps = {
  initialSearchOptions: SearchParamsType;
  searchOptionId: Nullable<string>;
  setSearchOptionId: Dispatch<SetStateAction<Nullable<string>>>;
  onClose: () => void;
};

const OptionChangeModal = ({
  searchOptionId,
  setSearchOptionId,
  onClose,
  initialSearchOptions,
}: SelectModalProps) => {
  const navigate = useNavigate();
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(true);

  const getProducts = (params?: SearchParamsType) => {
    navigate({
      pathname: PAGE_NAME.search,
      search: new URLSearchParams({
        pickUpAt: convertToKST(params?.pickUpAt),
        dropOffAt: convertToKST(params?.dropOffAt),
        driverAge: params?.driverAge ? String(params.driverAge) : '',
        numberOfPeople: params?.numberOfPeople
          ? String(params.numberOfPeople)
          : '',
        drivingExperience: params?.drivingExperience
          ? String(params.drivingExperience)
          : '',
      }).toString(),
    });

    onClose();
  };

  const { searchOptions, handleRentalDate, handleDriverInfo } =
    useSearchOptions({ onSubmit: getProducts, initialSearchOptions });

  const handleApplyOption = () => {
    setSearchOptionId(null);
    onClose();

    if (
      searchOptionId === SearchOption.DriverInfo ||
      searchOptionId === SearchOption.NumberOfPeople
    ) {
      handleDriverInfo();
    } else {
      handleRentalDate();
    }
  };

  /** 각 모달별 설정 버튼 */
  useEffect(() => {
    if (searchOptionId !== SearchOption.Date) return;
    if (searchOptions.pickUpDate && searchOptions.dropOffDate)
      setIsNextBtnDisabled(false);
    else setIsNextBtnDisabled(true);
  }, [searchOptionId, searchOptions.pickUpDate, searchOptions.dropOffDate]);

  useEffect(() => {
    if (
      searchOptionId !== SearchOption.DriverInfo &&
      searchOptionId !== SearchOption.NumberOfPeople
    )
      return;
    if (
      searchOptions.driverAge &&
      searchOptions.drivingExperience &&
      searchOptions.numberOfPeople
    )
      setIsNextBtnDisabled(false);
    else setIsNextBtnDisabled(true);
  }, [
    searchOptionId,
    searchOptions.driverAge,
    searchOptions.drivingExperience,
    searchOptions.numberOfPeople,
  ]);

  useEffect(() => {
    if (searchOptionId !== SearchOption.Time) return;
    if (!!searchOptions.pickUpTimeValue && !!searchOptions.dropOffTimeValue)
      setIsNextBtnDisabled(false);
    else setIsNextBtnDisabled(true);
  }, [
    searchOptionId,
    searchOptions.pickUpTimeValue,
    searchOptions.dropOffTimeValue,
  ]);

  return (
    <Modal
      onClose={onClose}
      paddingHorizontal={33}
      paddingVertical={61}
      yPosition='450px'
      hasBorder={false}
      topLevel
    >
      <SearchModalWrapper>
        <ModalWrapper singleStep>
          {searchOptionId === SearchOption.Date ? (
            <DateSelection
              pickUpDate={searchOptions.pickUpDate}
              setPickUpDate={searchOptions.setPickUpDate}
              dropOffDate={searchOptions.dropOffDate}
              setDropOffDate={searchOptions.setDropOffDate}
            />
          ) : searchOptionId === SearchOption.DriverInfo ||
            searchOptionId === SearchOption.NumberOfPeople ? (
            <SelectionWrapper>
              <DriverInfoSelection
                driverAge={searchOptions.driverAge}
                setDriverAge={searchOptions.setDriverAge}
                numberOfPeople={searchOptions.numberOfPeople}
                setNumberOfPeople={searchOptions.setNumberOfPeople}
                drivingExperience={searchOptions.drivingExperience}
                setDrivingExperience={searchOptions.setDrivingExperience}
              />
            </SelectionWrapper>
          ) : (
            <TimeSelectionWrapper>
              <TimeSelection
                pickUpTimeValue={searchOptions.pickUpTimeValue}
                setPickUpTimeValue={(value) => {
                  searchOptions.setPickUpTimeValue(value);
                }}
                dropOffTimeValue={searchOptions.dropOffTimeValue}
                setDropOffTimeValue={(value) => {
                  searchOptions.setDropOffTimeValue(value);
                }}
                pickUpDate={searchOptions.pickUpDate}
                dropOffDate={searchOptions.dropOffDate}
                showWarning={
                  (!!searchOptions.pickUpTimeValue &&
                    searchOptions.pickUpTimeValue >= SHOW_TIME_WARNING_FROM) ||
                  (!!searchOptions.dropOffTimeValue &&
                    searchOptions.dropOffTimeValue >= SHOW_TIME_WARNING_FROM)
                }
              />
            </TimeSelectionWrapper>
          )}
        </ModalWrapper>
        <BtnAreaWrapper>
          <Button
            {...buttonStyle}
            onClick={handleApplyOption}
            disabled={isNextBtnDisabled}
          >
            설정
          </Button>
        </BtnAreaWrapper>
      </SearchModalWrapper>
    </Modal>
  );
};

export default OptionChangeModal;

const buttonStyle = {
  width: '94px',
  height: '29px',
  margin: '0 0 0 50px',
};

const SearchModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 896px;
`;

const SelectionWrapper = styled.div`
  padding: 0 32px;
`;

const TimeSelectionWrapper = styled(SelectionWrapper)`
  margin-top: 118px;
`;
