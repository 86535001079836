import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ isMobile?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 110px;

  width: 100%;
  height: 400px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      flex-direction: column;
      justify-content: flex-start;
      width: 320px;
      gap: 80px;
    `}
`;

export const LabelWrapper = styled.div`
  position: relative;
  margin-bottom: 22px;
`;

export const WarningWrapper = styled.div<{ isMobile?: boolean }>`
  position: absolute;
  left: 0;
  bottom: ${({ isMobile }) => (isMobile ? '30px' : '130px')};
  width: ${({ isMobile }) => (isMobile ? '300px' : '345px')};
`;

export const WarningTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: ${({ theme }) => theme.COLOR.DANGER};
`;

export const WarningDescription = styled.p`
  ${({ theme }) => theme.FONT.B2};
  color: ${({ theme }) => theme.COLOR.DANGER};
`;
