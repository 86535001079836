import {
  authAtom,
  CloseIcon,
  DimmedBackground,
  FixedOuterWrapper,
  getPrimaryColor,
  Icon,
  PAGE_NAME,
  Styles,
  useRecoil,
} from '@orcar/common-d2c';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { APP_NAME } from '@/constants/common';
import { slideIn } from '@/styles/animation';

type DrawerProps = {
  onClose: () => void;
  isDrawerOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  handleLogin: () => void;
  handleLogout: () => void;
};

const Drawer = ({
  onClose,
  isDrawerOpen,
  setIsModalOpen,
  handleLogin,
  handleLogout,
}: DrawerProps) => {
  const navigate = useNavigate();
  const { recoilValue: user } = useRecoil(authAtom);

  const handleModal = () => {
    setIsModalOpen(true);
    onClose();
  };

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);

  return (
    <>
      <DimmedBackground onClick={onClose} topLevel />
      <FixedOuterWrapper>
        <Wrapper isDrawerOpen={isDrawerOpen}>
          <Icon
            src={CloseIcon}
            width={20}
            height={20}
            onClick={onClose}
            wrapperWidth='40px'
            wrapperHeight='40px'
          />
          <LinkWrapper>
            {!!user ? (
              <LoginButton
                onClick={() => {
                  handleLogout();
                  onClose();
                }}
              >
                로그아웃
              </LoginButton>
            ) : (
              <LoginButton
                onClick={() => {
                  onClose();
                  handleLogin();
                }}
              >
                로그인
              </LoginButton>
            )}
            <NavLink
              onClick={() => {
                onClose();
                navigate(`${PAGE_NAME.rent_info}/?type=insurance`);
              }}
            >
              보험 안내
            </NavLink>
            <NavLink
              onClick={() => {
                onClose();
                navigate(`${PAGE_NAME.rent_info}/?type=reservation`);
              }}
            >
              대여 안내
            </NavLink>
            <NavLink onClick={handleModal}>고객센터</NavLink>
            {!!user ? (
              <NavLink onClick={() => navigate(PAGE_NAME.my_page)}>
                마이페이지
              </NavLink>
            ) : (
              <NavLink onClick={() => navigate(PAGE_NAME.find_reservation)}>
                예약 조회
              </NavLink>
            )}
          </LinkWrapper>
        </Wrapper>
      </FixedOuterWrapper>
    </>
  );
};

export default Drawer;

const Wrapper = styled.div<{ isDrawerOpen: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  top: 0;
  left: 0;
  width: 300px;
  height: 100vh;
  padding: 16px 10px;
  background-color: ${Styles.COLOR.WHITE};
  z-index: 100;
  animation: 0.3s ease-out ${slideIn};
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 10px;
  gap: 28px;
`;

const NavLink = styled.span`
  ${Styles.FONT.H3};
  color: ${Styles.COLOR.GREY_01};
`;

const LoginButton = styled.button`
  height: 44px;
  ${({ theme }) => theme.FONT.H3};
  color: ${Styles.COLOR.WHITE};
  background-color: ${getPrimaryColor(APP_NAME)};
  border-radius: 5px;
`;
