import {
  FinalConfirmModalInfoWrapper,
  FinalConfirmModalText,
  Icon,
  isMobileAtom,
  RenewalElectronicCarIcon,
  RenewalFishingIcon,
  RenewalForeignIcon,
  RenewalPetIcon,
  Styles,
  useRecoil,
} from '@orcar/common-d2c';
import styled, { css } from 'styled-components';

const CONDITION_ARR = [
  {
    id: 'pet',
    iconUrl: RenewalPetIcon,
    text: '반려동물 동반 시 대여가 불가능합니다.',
  },
  {
    id: 'fishing',
    iconUrl: RenewalFishingIcon,
    text: '낚시 용품 소지시 대여가 불가능합니다.',
  },
  {
    id: 'charge',
    iconUrl: RenewalElectronicCarIcon,
    text: '전기차 충전카드는 제공하지 않습니다.',
  },
  {
    id: 'foreign',
    iconUrl: RenewalForeignIcon,
    text: '(외국인) 국제면허증과 여권 미지참 시 대여가 불가능합니다.',
  },
];

const RefundConfirm = () => {
  const { recoilValue: isMobile } = useRecoil(isMobileAtom);

  return (
    <>
      <ConditionWrapper outer isMobile={isMobile}>
        {CONDITION_ARR.map((item) => (
          <ConditionLine key={item.id} isMobile={isMobile}>
            <Icon
              src={item.iconUrl}
              width={20}
              height={20}
              iconcolor={Styles.SVG_FILTER.GREY_01}
            />
            <ConditionWrapper isMobile={isMobile}>
              <Text isMobile={isMobile} width={isMobile ? '207px' : '100%'}>
                {item.text}
              </Text>
            </ConditionWrapper>
          </ConditionLine>
        ))}
      </ConditionWrapper>
      <FinalConfirmModalInfoWrapper isMobile={isMobile} hasList>
        <FinalConfirmModalText isMobile={isMobile}>
          환불 사항
        </FinalConfirmModalText>
        <ul>
          <li>인수 불가에 따른 책임은 예약 당사자에게 있습니다.</li>
          <li>인수 불가 시 취소에 따른 취소 수수료가 발생합니다.</li>
          <li>취소 수수료는 당일 취소 수수료 구간이 적용됩니다.</li>
        </ul>
      </FinalConfirmModalInfoWrapper>
    </>
  );
};

export default RefundConfirm;

const ConditionWrapper = styled.div<{ outer?: boolean; isMobile?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ outer, isMobile }) =>
    outer &&
    css`
      width: 100%;
      margin-top: ${isMobile ? '12px' : '16px'};
      margin-bottom: ${isMobile && '12px'};
      border-top: 1px solid ${({ theme }) => theme.COLOR.GREY_05};

      & > * {
        border-bottom: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
      }
    `}
`;

const ConditionLine = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: ${({ isMobile }) => (isMobile ? '10px 0' : '16px 0')};
`;

const Text = styled.p<{ sub?: boolean; isMobile?: boolean; width?: string }>`
  width: ${({ width }) => width};
  ${({ theme, sub, isMobile }) =>
    sub ? theme.NEW_FONT.B3 : isMobile ? theme.NEW_FONT.B2 : theme.NEW_FONT.B1};
  color: ${({ sub, theme }) => sub && theme.COLOR.GREY_02};
  margin-top: ${({ sub }) => sub && '8px'};
`;
