import { Styles } from '@orcar/common-d2c';
import logo from '@/assets/img/logo_rainbow-grey-v2.svg';
import CompanyInfoSection from './components/CompanyInfoSection';
import LinkButtonSection from './components/LinkButtonSection';
import * as S from './styles';

const Footer = () => {
  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.TopSection>
          <S.LeftSection>
            <img src={logo} alt='footer로고' width={173} height={32} />
            <CompanyInfoSection />
          </S.LeftSection>
          <S.RightSection>
            <LinkButtonSection />
          </S.RightSection>
        </S.TopSection>
      </S.InnerWrapper>
      <S.BottomSection>
        <S.InnerWrapper bottom>
          <S.InfoContent color={Styles.COLOR.GREY_01} font={Styles.FONT.B2}>
            Copyright © 2015 www.rainbowrent.co.kr All Rights Reserved.
          </S.InfoContent>
        </S.InnerWrapper>
      </S.BottomSection>
    </S.Wrapper>
  );
};

export default Footer;
