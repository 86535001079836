import styled, { css } from 'styled-components';

const MOBILE_IMG_HEIGHT = '395px';

export const Wrapper = styled.div<{ $isMobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  background-color: ${({ $isMobile, theme }) =>
    $isMobile && theme.COLOR.GREY_BG};
`;

export const Text = styled.span<{
  $bold?: boolean;
  hidden?: boolean;
  $isMobile?: boolean;
}>`
  display: ${({ hidden }) => hidden && 'none'};
  text-align: ${({ $isMobile }) => !$isMobile && 'center'};
  ${({ theme }) => theme.FONT.H3}
  color: ${({ theme }) => theme.COLOR.WHITE};
  line-height: 30px;
  font-weight: ${({ $bold }) => ($bold ? '700' : '400')};
  margin-top: ${({ $bold, $isMobile }) => {
    if ($bold) return;
    return $isMobile ? '110px' : '120px';
  }};
  margin-bottom: ${({ $bold, $isMobile }) => {
    if ($bold) return;
    return $isMobile ? '90px' : '120px';
  }};
  margin-left: ${({ $isMobile }) => $isMobile && '-50px'};
  z-index: 3;
`;

export const SearchButtonWrapper = styled.div<{
  isOpen: boolean;
  $isMobile: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ $isMobile }) => ($isMobile ? '320px' : '600px')};
  height: ${({ $isMobile }) => ($isMobile ? '48px' : '60px')};
  padding: 6px;
  margin-top: ${({ isOpen }) => isOpen && '300px'};
  margin-bottom: ${({ $isMobile }) => ($isMobile ? '60px' : '100px')};
  background-color: ${({ theme }) => theme.COLOR.WHITE};
  border: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  border-radius: ${({ $isMobile, theme }) =>
    $isMobile ? theme.BORDER_RADIUS.LEVEL_01 : theme.BORDER_RADIUS.LEVEL_02};
  cursor: pointer;
  z-index: 1;
`;

export const ButtonText = styled.p<{ $isMobile: boolean }>`
  margin-left: 18px;
  ${({ theme, $isMobile }) =>
    $isMobile ? theme.NEW_FONT.B1 : theme.NEW_FONT.H4};
  font-weight: 400;
  user-select: none;
`;

export const BackgroundImageWrapper = styled.div<{ $isMobile: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  top: ${({ $isMobile, theme }) =>
    $isMobile ? `-${theme.HEIGHT.MOBILE_HEADER}` : '0'};
  left: 50%;
  width: 100vw;
  margin-bottom: 80px;
  transform: translateX(-50%);
  z-index: 1;
  overflow: hidden;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      left: 0;
      transform: none;
      height: ${MOBILE_IMG_HEIGHT};
      margin-bottom: 0;
    `}
`;

export const BackgroundImageFilter = styled.div<{ $isMobile: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 50%;
  width: ${({ $isMobile }) => $isMobile && '100%'};
  min-width: ${({ $isMobile, theme }) => !$isMobile && theme.WIDTH.PC_LAYOUT};
  height: ${({ $isMobile, theme }) =>
    $isMobile ? `calc(${theme.HEIGHT.MOBILE_HEADER} + 400px)` : '380px'};
  border-radius: ${({ $isMobile }) => !$isMobile && '10px'};
  background: rgba(0, 0, 0, 0.2);
  transform: translateX(-50%);
  z-index: 2;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      left: 0;
      transform: none;
      height: ${MOBILE_IMG_HEIGHT};
    `}
`;

export const BannerSection = styled.div<{ $isMobile: boolean }>`
  display: flex;
  width: ${({ theme, $isMobile }) =>
    $isMobile ? theme.WIDTH.MOBILE_LAYOUT : theme.WIDTH.PC_LAYOUT};
  margin-top: ${({ $isMobile }) => !$isMobile && '80px'};

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-bottom: 20px;
      padding: 0 20px;
    `}
`;

export const BannerWrapper = styled.div<{ $isMobile: boolean }>`
  position: relative;
  width: 100vw;
  height: 300px;
  margin-top: 40px;
  background-color: #293648;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      height: 410px;
      margin-top: 0;
    `}
`;

export const ImgWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: 1500px;
  transform: translateX(-50%);
`;

// *********** 추천차량 영역 **************** //
export const RecommendCarWrapper = styled.div<{
  $isMobile: boolean;
  isOpen: boolean;
}>`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: ${({ isOpen }) => isOpen && '160px'};
`;

export const RecommendCaredInnerWrapper = styled.div<{ $isMobile: boolean }>`
  width: ${({ $isMobile, theme }) => !$isMobile && theme.WIDTH.PC_LAYOUT};
  padding-top: ${({ $isMobile }) => ($isMobile ? '120px' : '203px')};
  padding-bottom: ${({ $isMobile }) => $isMobile && '40px'};
`;

export const SectionTitle = styled.p`
  ${({ theme }) => theme.FONT.H3};
`;

export const CardWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  gap: 24px;
  margin-top: 40px;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      display: grid;
      gap: 8px;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 20px;
    `}
`;
