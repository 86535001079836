import { D2CDiscountCategory, ReservationState } from '@orcar/common';
import {
  ALERT_MESSAGE_TYPE,
  authAtom,
  cn,
  CommonModal,
  convertToKST,
  CustomerForm,
  DiscountArea,
  FinalConfirmModal,
  getDiscountList,
  getDriverAgeModalSubText,
  getPreReservation,
  getSureUserId,
  INSURANCE_TYPE,
  isMobileAtom,
  ListStyle,
  MUTATION_KEY,
  OrderConfirmArea,
  OrderConfirmationInsuranceInfo,
  PAGE_NAME,
  PAYMENT_METHOD_ID,
  PaymentInfoCard,
  SESSION_STORAGE_KEY,
  useCustomerForm,
  useGetDiscountPolicy,
  useGetPreReservation,
  useGetVehicleModel,
  useRecoil,
  useUpdatePreReservation,
  useVehicleTag,
} from '@orcar/common-d2c';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CancellationTable from './components/CancellationTable';
import RefundConfirm from './components/RefundConfirm';

const PaymentPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const sureUserId = getSureUserId();

  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_METHOD_ID>(
    PAYMENT_METHOD_ID.DIRECT,
  );
  const [appliedReferralCode, setAppliedReferralCode] = useState('');
  const [isDriverAgeModalOpen, setIsDriverAgeModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isFullCoverage, setIsFullCoverage] = useState(
    sessionStorage.getItem(SESSION_STORAGE_KEY.IS_FULL_COVERAGE) === 'true',
  );

  const { recoilValue: isMobile } = useRecoil(isMobileAtom);
  const { recoilValue: user } = useRecoil(authAtom);

  const preReservationId =
    sessionStorage.getItem(SESSION_STORAGE_KEY.PRE_RESERVATION_ID) ?? '';
  const { data: preReservationData } = useGetPreReservation(preReservationId, {
    referralCode: appliedReferralCode,
    isFullCoverage,
    sureUserId: sureUserId ? Number(sureUserId) : undefined,
  });
  const { data: vehicleModelData } = useGetVehicleModel({
    id: preReservationData?.vehicleModelId,
    params: {
      insuranceName: preReservationData?.insuranceName,
      pickUpAt: convertToKST(preReservationData?.pickUpAt),
      dropOffAt: convertToKST(preReservationData?.dropOffAt),
    },
  });
  const { data: discountPolicyData } = useGetDiscountPolicy();
  const { mutate: updatePreReservation } = useUpdatePreReservation();

  const isMutatingUpdatePreReservation =
    queryClient.isMutating({
      mutationKey: [MUTATION_KEY.UPDATE_PRE_RESERVATION],
    }) > 0;

  const { options } = useVehicleTag(vehicleModelData);
  const { customerFormProps, checked, handleCheckbox } = useCustomerForm({
    user,
  });

  const insuranceName = preReservationData?.insuranceName;
  const isPerfectInsurance = insuranceName === INSURANCE_TYPE.PERFECT;
  const isSuperInsurance = insuranceName === INSURANCE_TYPE.SUPER;
  const isNormalInsurance = insuranceName === INSURANCE_TYPE.NORMAL;
  const canAddUnlimitedOption =
    vehicleModelData?.insurance.insuranceName === INSURANCE_TYPE.NORMAL
      ? !!vehicleModelData?.availableWithInsuranceOption1
      : vehicleModelData?.insurance.insuranceName === INSURANCE_TYPE.PERFECT
      ? !!vehicleModelData?.availableWithInsuranceOption2
      : false;

  const discountCode = preReservationData?.tempDiscounts.find(
    (discount) => discount?.category === D2CDiscountCategory.DISCOUNT_CODE,
  );

  const handlePayment = async () => {
    if (!preReservationData) {
      alert(ALERT_MESSAGE_TYPE.INVALID_RESERVATION);
      navigate(PAGE_NAME.search);
      return;
    }

    if (appliedReferralCode) {
      sessionStorage.setItem(
        SESSION_STORAGE_KEY.REFERRAL_CODE,
        appliedReferralCode,
      );
    } else {
      sessionStorage.removeItem(SESSION_STORAGE_KEY.REFERRAL_CODE);
    }

    if (isFullCoverage) {
      sessionStorage.setItem(SESSION_STORAGE_KEY.IS_FULL_COVERAGE, 'true');
    } else {
      sessionStorage.removeItem(SESSION_STORAGE_KEY.IS_FULL_COVERAGE);
    }

    if (paymentMethod === PAYMENT_METHOD_ID.DIRECT) {
      navigate({
        pathname: `${PAGE_NAME.request_payment}`,
        search: `?orderId=${preReservationData.id}`,
      });
    }
  };

  const handleAddUnlimitedInsurance = () => {
    setIsFullCoverage(!isFullCoverage);
  };

  useEffect(() => {
    if (!preReservationId) {
      alert(ALERT_MESSAGE_TYPE.NOT_ALLOWED_ACCESS);
      navigate(PAGE_NAME.index);
      return;
    }
  }, [preReservationId]);

  useEffect(() => {
    if (!!preReservationData?.reservationId) {
      if (preReservationData.reservation?.state === ReservationState.CANCELED) {
        alert(ALERT_MESSAGE_TYPE.INVALID_RESERVATION);
        navigate(PAGE_NAME.index);
      } else {
        alert(ALERT_MESSAGE_TYPE.PAID_RESERVATION);
        navigate(PAGE_NAME.index);
      }
    }
  }, [preReservationData]);

  return (
    <LayoutWrapper isMobile={isMobile}>
      <ContentWrapper isMobile={isMobile}>
        <SectionTitle isMobile={isMobile}>예약 진행</SectionTitle>
        <WhiteBgWrapper isMobile={isMobile}>
          <OrderConfirmArea
            modelName={vehicleModelData?.name || ''}
            options={options}
            pickUpTime={dayjs(preReservationData?.pickUpAt).tz('Asia/seoul')}
            dropOffTime={dayjs(preReservationData?.dropOffAt).tz('Asia/seoul')}
            companyName={preReservationData?.companyName || ''}
          />
        </WhiteBgWrapper>
        <WhiteBgWrapper isMobile={isMobile}>
          <CustomerForm {...customerFormProps} />
        </WhiteBgWrapper>
        <WhiteBgWrapper isMobile={isMobile}>
          <OrderConfirmationInsuranceInfo
            isMobile={isMobile}
            insuranceId={preReservationData?.insuranceName || ''}
            fullCoverageInsurancePrice={
              preReservationData?.fullCoverageInsurancePrice
            }
            onClick={handleAddUnlimitedInsurance}
            addInsurance={isFullCoverage && !isSuperInsurance}
            companyName={preReservationData?.companyName || ''}
            canAddUnlimitedOption={canAddUnlimitedOption}
            coverageLimit={
              isPerfectInsurance || isSuperInsurance
                ? vehicleModelData?.insurance2?.limit || ''
                : isNormalInsurance
                ? vehicleModelData?.insurance1?.limit || ''
                : ''
            }
            deductible={
              insuranceName === INSURANCE_TYPE.NORMAL
                ? vehicleModelData?.insurance1?.indemnification || ''
                : insuranceName === INSURANCE_TYPE.PERFECT
                ? vehicleModelData?.insurance2?.indemnification || ''
                : ''
            }
            lossOfUseFee={
              insuranceName === INSURANCE_TYPE.NORMAL
                ? vehicleModelData?.insurance1?.compensation || ''
                : insuranceName === INSURANCE_TYPE.PERFECT
                ? vehicleModelData?.insurance2?.compensation || ''
                : ''
            }
          />
        </WhiteBgWrapper>
        <WhiteBgWrapper isMobile={isMobile}>
          <DiscountArea
            referralDiscountedPrice={discountCode?.discountAmount || 0}
            appliedReferralCode={appliedReferralCode}
            setAppliedReferralCode={setAppliedReferralCode}
            getPreReservation={getPreReservation}
          />
          {/* <PaymentMethodArea
            selectedPaymentMethod={paymentMethod}
            setSelectedPaymentMethod={setPaymentMethod}
            onlyDirectPayment
          /> */}
        </WhiteBgWrapper>
      </ContentWrapper>

      <div
        className={cn(
          'sticky top-36 h-fit w-96 rounded-m bg-grey-000 shadow-050',
          isMobile && 'w-full shadow-none',
        )}
      >
        <PaymentInfoCard
          isLogin={!!user}
          isMobile={isMobile}
          insurance={insuranceName}
          addInsurance={isFullCoverage && !isSuperInsurance}
          listPrice={preReservationData?.listPrice || 0}
          price={preReservationData?.price || 0}
          discountList={getDiscountList(preReservationData?.tempDiscounts)}
          fullCoverageInsurancePrice={
            preReservationData?.fullCoverageInsurancePrice || 0
          }
          onClick={() => setIsDriverAgeModalOpen(true)}
          isCheckBoxChecked={checked}
          onCheckBoxClicked={handleCheckbox}
          memberDiscountRate={discountPolicyData?.discountValue}
          isMemberDiscountActive={
            !!discountPolicyData && discountPolicyData.isActive
          }
          isOnSitePaymentOnly
          isPaymentPage
        />
      </div>

      {isPaymentModalOpen && (
        <FinalConfirmModal
          onClose={() => setIsPaymentModalOpen(false)}
          handleTossPayment={handlePayment}
          RuleConfirm={CancellationTable}
          RefundConfirm={RefundConfirm}
        />
      )}
      {isDriverAgeModalOpen && (
        <CommonModal
          mainText={`운전자 나이가 정말 만 ${vehicleModelData?.driverAge}세 이상인가요?`}
          subText={getDriverAgeModalSubText(
            vehicleModelData?.driverAge || 0,
            dayjs(preReservationData?.pickUpAt).format(),
          )}
          onClick={() => {
            if (!preReservationData) return;

            updatePreReservation(
              {
                id: preReservationData?.id,
                input: {
                  customerName: customerFormProps.nameInput.value,
                  customerPhoneNumber: customerFormProps.phoneNumberInput.value,
                  customerEmail: customerFormProps.emailInput.value,
                },
              },
              {
                onSuccess: () => {
                  setIsDriverAgeModalOpen(false);
                  setIsPaymentModalOpen(true);
                },
                onError: () => {
                  alert(ALERT_MESSAGE_TYPE.ERROR_OCCURRENCE);
                  setIsDriverAgeModalOpen(false);
                },
              },
            );
          }}
          buttonText='예'
          buttonLoading={isMutatingUpdatePreReservation}
          onClose={() => setIsDriverAgeModalOpen(false)}
        />
      )}
    </LayoutWrapper>
  );
};

export default PaymentPage;

const LayoutWrapper = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '0' : '24px')};
  width: ${({ isMobile }) => (isMobile ? '100%' : '1200px')};
  ${({ isMobile }) => isMobile && 'padding: 40px 0 0'};
`;

const ContentWrapper = styled.div<{
  hasPadding?: boolean;
  isMobile?: boolean;
}>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '792px')};
  scroll-margin: 150px;
`;

const SectionTitle = styled.p<{
  isMobile: boolean;
}>`
  margin-bottom: 20px;
  padding: 0 20px;
  ${({ isMobile, theme }) =>
    isMobile ? theme.NEW_FONT.H4 : theme.NEW_FONT.H3};
`;

const WhiteBgWrapper = styled.div<{
  isMobile: boolean;
}>`
  width: 100%;
  gap: 30px;
  padding: 0 28px;
  margin-bottom: ${({ isMobile }) => (isMobile ? '10px' : '24px')};
  background-color: ${({ theme }) => theme.COLOR.WHITE};
  background-color: white;
  border-radius: ${({ theme }) => theme.BORDER_RADIUS.LEVEL_02};

  & > *:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  }

  & li {
    ${ListStyle}
  }
`;
