import {
  Button,
  Calendar,
  getMonth,
  getYear,
  Styles,
  TODAY_RESERVATION_AVAILABLE_TIME,
} from '@orcar/common-d2c';
import { useState } from 'react';
import styled from 'styled-components';
import { DateSelectionProps } from './DateSelection';
import * as S from './styles';

const MobileDateSelection = ({
  pickUpDate,
  setPickUpDate,
  dropOffDate,
  setDropOffDate,
  isMobile,
}: DateSelectionProps) => {
  const [months, setMonths] = useState([...Array(3)]);

  const handleMoreView = () => {
    setMonths(months.concat(...Array(2)));
  };
  return (
    <S.Wrapper isMobile={isMobile}>
      {months.map((_, idx) => (
        <DateContainer idx={idx} key={idx} months={months}>
          <Calendar
            year={getYear(idx)}
            month={getMonth(idx)}
            pickUpDate={pickUpDate}
            dropOffDate={dropOffDate}
            onSelectPickup={setPickUpDate}
            onSelectDropOff={setDropOffDate}
            todayReservationDeadline={TODAY_RESERVATION_AVAILABLE_TIME}
          />
          {idx === months.length - 1 && (
            <Button
              width='100%'
              height='60px'
              margin='10px 0 0'
              bgColor={Styles.COLOR.WHITE}
              color={Styles.COLOR.GREY_02}
              borderColor={Styles.COLOR.GREY_02}
              borderRadius='50px'
              font={Styles.FONT.H4}
              onClick={handleMoreView}
            >
              이후 날짜 보기
            </Button>
          )}
        </DateContainer>
      ))}
    </S.Wrapper>
  );
};

export { MobileDateSelection };

const DateContainer = styled.div<{ idx: number; months: number[] }>`
  margin-bottom: 20px;
  padding-bottom: ${({ idx, months }) =>
    idx === months?.length - 1 ? '135px' : '0'};
`;
