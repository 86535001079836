import {
  ALERT_MESSAGE_TYPE,
  getPreReservation,
  getSureUserId,
  PAGE_NAME,
  removeSureUserId,
  SESSION_STORAGE_KEY,
  useConfirmPreReservationPayment,
  useDetectMobile,
} from '@orcar/common-d2c';
import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as S from '../styles';

const RequestPaymentPage = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const paymentKey = searchParams.get('paymentKey');
  const amount = Number(searchParams.get('amount'));
  const orderId = searchParams.get('orderId');

  const hasRequestedPayment = useRef(false);

  const { isMobile, screenHeight } = useDetectMobile();

  const confirmPayment = useConfirmPreReservationPayment();

  useEffect(() => {
    const sureUserId = getSureUserId();
    const referralCode =
      sessionStorage.getItem(SESSION_STORAGE_KEY.REFERRAL_CODE) || undefined;
    const preReservationId = sessionStorage.getItem(
      SESSION_STORAGE_KEY.PRE_RESERVATION_ID,
    );
    const isFullCoverage =
      sessionStorage.getItem(SESSION_STORAGE_KEY.IS_FULL_COVERAGE) === 'true';

    if (!preReservationId || !orderId) {
      alert(ALERT_MESSAGE_TYPE.NOT_ALLOWED_ACCESS);
      navigate(PAGE_NAME.index);
      return;
    } else {
      getPreReservation(preReservationId).then((res) => {
        if (!hasRequestedPayment.current && !res?.reservation) {
          hasRequestedPayment.current = true;

          confirmPayment
            .mutateAsync({
              id: orderId,
              input: {
                paymentKey: paymentKey ? paymentKey : undefined,
                amount: amount ? amount : undefined,
                referralCode,
                isFullCoverage,
                sureUserId: sureUserId ? Number(sureUserId) : undefined,
              },
            })
            .then(() => {
              navigate(PAGE_NAME.confirm_payment, {
                replace: true,
              });
            })
            .catch(() => {
              navigate(`${PAGE_NAME.payment_fail}`, {
                replace: true,
              });
            })
            .finally(() => {
              sessionStorage.removeItem(SESSION_STORAGE_KEY.IS_FULL_COVERAGE);
              sessionStorage.removeItem(SESSION_STORAGE_KEY.REFERRAL_CODE);
              removeSureUserId();
            });
        }
      });
    }
  }, [paymentKey, amount, orderId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Wrapper isMobile={isMobile} screenHeight={screenHeight - 148}>
      <S.Text type='main' isMobile={isMobile}>
        예약이 진행중입니다
      </S.Text>
    </S.Wrapper>
  );
};

export default RequestPaymentPage;
