import { Nullable } from '@orcar/common';
import {
  DriverOptionType,
  getDateOptionText,
  getDriverOptionText,
  getHMFromNumber,
  getRentalTimeValue,
  getTimeOptionText,
  OPTION_TITLES,
  SearchParamsType,
  setStaticDate,
  TOAST_MESSAGE_TYPE,
  useGetCompanyInfo,
  useToast,
} from '@orcar/common-d2c';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { DRIVER_OPTIONS } from '@/constants/searchOptions';
import {
  getAvailablePickUpTimeOptions,
  getRentalOperatingHours,
} from '@/utils/date';

type SearchOptionsType = {
  onSubmit: (params: SearchParamsType) => void;
  onModalClose?: (option?: { onRouterBack?: boolean }) => void;
  initialSearchOptions?: SearchParamsType;
};

const useSearchOptions = ({
  onModalClose,
  onSubmit,
  initialSearchOptions,
}: SearchOptionsType) => {
  const { addToast } = useToast();

  const { data: companyData } = useGetCompanyInfo();
  const rentalOperatingHours = getRentalOperatingHours(companyData);
  const availablePickUpTimeOptions =
    getAvailablePickUpTimeOptions(rentalOperatingHours);

  const [pickUpDate, setPickUpDate] = useState<Nullable<Dayjs>>(
    dayjs(setStaticDate('pickUp')),
  );
  const [dropOffDate, setDropOffDate] = useState<Nullable<Dayjs>>(
    dayjs(setStaticDate('dropOff')),
  );
  const [pickUpTimeValue, setPickUpTimeValue] = useState(800);
  const [dropOffTimeValue, setDropOffTimeValue] = useState(800);
  const [driverAge, setDriverAge] = useState<Nullable<DriverOptionType>>({
    key: 'gteTwentySix',
    value: null,
    text: '만 26세 이상',
  });
  const [drivingExperience, setDrivingExperience] = useState<
    Nullable<DriverOptionType>
  >({
    key: 'gteTwo',
    value: null,
    text: '2년 이상',
  });
  const [numberOfPeople, setNumberOfPeople] = useState<
    Nullable<DriverOptionType>
  >({
    key: 'notNow',
    value: null,
    text: '지금 선택 안 함',
  });

  const [searchOptionIdx, setSearchOptionIdx] = useState(0);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(true);
  const [isCheckTimeSelected, setIsCheckTimeSelected] = useState(false);
  const [shouldShowToast, setShouldShowToast] = useState(false);
  const [indicatorTexts, setIndicatorTexts] = useState<string[]>(OPTION_TITLES);

  const searchOptions = {
    pickUpDate,
    setPickUpDate,
    dropOffDate,
    setDropOffDate,
    pickUpTimeValue,
    setPickUpTimeValue,
    dropOffTimeValue,
    setDropOffTimeValue,
    driverAge,
    setDriverAge,
    drivingExperience,
    setDrivingExperience,
    numberOfPeople,
    setNumberOfPeople,
  };

  const handleRentalDate = () => {
    const pickTimeValue = getHMFromNumber(pickUpTimeValue);
    const dropTimeValue = getHMFromNumber(dropOffTimeValue);

    const searchOptions = initialSearchOptions || {
      pickUpAt: dayjs().format(),
      dropOffAt: dayjs().add(1, 'day').format(),
      driverAge: null,
      numberOfPeople: null,
      drivingExperience: null,
    };

    onSubmit({
      ...searchOptions,
      pickUpAt: pickUpDate
        ?.set('hour', pickTimeValue.hh)
        .set('minute', pickTimeValue.mm)
        .format(),
      dropOffAt: dropOffDate
        ?.set('hour', dropTimeValue.hh)
        .set('minute', dropTimeValue.mm)
        .format(),
    });
  };

  const handleDriverInfo = () => {
    const searchOptions = initialSearchOptions || {
      pickUpAt: dayjs().format(),
      dropOffAt: dayjs().add(1, 'day').format(),
      driverAge: null,
      numberOfPeople: null,
      drivingExperience: null,
    };

    onSubmit({
      ...searchOptions,
      driverAge: driverAge?.value,
      drivingExperience: drivingExperience?.value,
      numberOfPeople: numberOfPeople?.value,
    });
  };

  const handleNextButton = () => {
    if (isNextBtnDisabled || !onModalClose) return;

    if (searchOptionIdx === 2) {
      const pickTimeValue = getHMFromNumber(pickUpTimeValue);
      const dropTimeValue = getHMFromNumber(dropOffTimeValue);

      onSubmit({
        pickUpAt: pickUpDate
          ?.set('hour', pickTimeValue.hh)
          .set('minute', pickTimeValue.mm)
          .format(),
        dropOffAt: dropOffDate
          ?.set('hour', dropTimeValue.hh)
          .set('minute', dropTimeValue.mm)
          .format(),
        driverAge: driverAge?.value,
        numberOfPeople: numberOfPeople?.value,
        drivingExperience: drivingExperience?.value,
      });
      onModalClose();
      setSearchOptionIdx(0);
      setIsCheckTimeSelected(false);
    } else if (searchOptionIdx === 1) {
      setSearchOptionIdx(searchOptionIdx + 1);
      setIsCheckTimeSelected(true);
    } else {
      setSearchOptionIdx(searchOptionIdx + 1);
    }
  };

  const handleTabButton = (idx: number) => {
    if ((!pickUpDate || !dropOffDate) && idx > 0) return;
    if (!isCheckTimeSelected && idx > 1) return;
    setSearchOptionIdx(idx);
  };

  const handleClickIndicator = (idx: number) => {
    if ((!searchOptions.pickUpDate || !searchOptions.dropOffDate) && idx > 0)
      return;
    if (!isCheckTimeSelected && idx > 1) return;
    setSearchOptionIdx(idx);
  };

  useEffect(() => {
    if (searchOptionIdx !== 0) return;
    if (pickUpDate && dropOffDate) {
      setIsNextBtnDisabled(false);
    } else setIsNextBtnDisabled(true);
  }, [searchOptionIdx, pickUpDate, dropOffDate]);

  useEffect(() => {
    if (searchOptionIdx !== 1) return;
    else {
      setIsCheckTimeSelected(true);
    }
  }, [searchOptionIdx, pickUpTimeValue, dropOffTimeValue, isCheckTimeSelected]);

  useEffect(() => {
    let hasChanged = false;

    if (pickUpDate?.isSame(dayjs(), 'date')) {
      if (
        !availablePickUpTimeOptions.find(
          (option) => option.value === pickUpTimeValue,
        )
      ) {
        setPickUpTimeValue(availablePickUpTimeOptions[0].value);
        hasChanged = true;
      }
    }

    if (dropOffDate?.isSame(pickUpDate, 'date')) {
      if (
        !availablePickUpTimeOptions.find(
          (option) => option.value === dropOffTimeValue,
        ) &&
        availablePickUpTimeOptions.length > 0
      ) {
        setDropOffTimeValue(availablePickUpTimeOptions[0].value);
        hasChanged = true;
      }
    }

    if (hasChanged && !!isCheckTimeSelected) {
      setIsCheckTimeSelected(false);
      setShouldShowToast(true);
    }
  }, [pickUpDate, dropOffDate]);

  useEffect(() => {
    if (searchOptionIdx !== 2) return;
    if (driverAge && numberOfPeople && drivingExperience) {
      setIsNextBtnDisabled(false);
    } else setIsNextBtnDisabled(true);
  }, [searchOptionIdx, driverAge, numberOfPeople, drivingExperience]);

  useEffect(() => {
    if (initialSearchOptions) {
      setPickUpDate(dayjs(initialSearchOptions?.pickUpAt));
      setDropOffDate(dayjs(initialSearchOptions?.dropOffAt));

      setPickUpTimeValue(getRentalTimeValue(initialSearchOptions?.pickUpAt));
      setDropOffTimeValue(getRentalTimeValue(initialSearchOptions?.dropOffAt));

      setDriverAge(
        DRIVER_OPTIONS.driverAge.find(
          (age) => age.value === initialSearchOptions.driverAge,
        ) || null,
      );
      setDrivingExperience(
        DRIVER_OPTIONS.drivingExperience.find(
          (experience) =>
            experience.value === initialSearchOptions?.drivingExperience,
        ) || null,
      );
      setNumberOfPeople(
        DRIVER_OPTIONS.numberOfPeople.find(
          (num) => num.value === initialSearchOptions?.numberOfPeople,
        ) || null,
      );
    }
  }, [initialSearchOptions]);

  useEffect(() => {
    const newIndicatorTexts = [];

    if (searchOptionIdx === 0 || !pickUpDate || !dropOffDate)
      newIndicatorTexts.push(OPTION_TITLES[0]);
    else {
      newIndicatorTexts.push(getDateOptionText(pickUpDate, dropOffDate, false));
      if (shouldShowToast) {
        addToast({
          content: TOAST_MESSAGE_TYPE.RESET_TIME_OPTION,
        });
        setShouldShowToast(false);
      }
    }

    if (
      searchOptionIdx === 1 ||
      !isCheckTimeSelected ||
      !pickUpDate ||
      !dropOffDate ||
      !pickUpTimeValue ||
      !dropOffTimeValue
    ) {
      newIndicatorTexts.push(OPTION_TITLES[1]);
    } else {
      const pickTimeValue = getHMFromNumber(pickUpTimeValue);
      const dropTimeValue = getHMFromNumber(dropOffTimeValue);

      const pickUpAt = pickUpDate
        ?.set('hour', pickTimeValue.hh)
        .set('minute', pickTimeValue.mm);
      const dropOffAt = dropOffDate
        ?.set('hour', dropTimeValue.hh)
        .set('minute', dropTimeValue.mm);

      newIndicatorTexts.push(getTimeOptionText(pickUpAt, dropOffAt, false));
    }

    if (
      searchOptionIdx === 2 ||
      !driverAge ||
      !numberOfPeople ||
      !drivingExperience
    )
      newIndicatorTexts.push(OPTION_TITLES[2]);
    else
      newIndicatorTexts.push(
        getDriverOptionText(
          driverAge as DriverOptionType,
          drivingExperience as DriverOptionType,
          numberOfPeople as DriverOptionType,
        ),
      );

    setIndicatorTexts(newIndicatorTexts);
  }, [
    searchOptionIdx,
    pickUpDate,
    dropOffDate,
    pickUpTimeValue,
    dropOffTimeValue,
  ]);

  return {
    searchOptions,
    searchOptionIdx,
    setSearchOptionIdx,
    isNextBtnDisabled,
    isCheckTimeSelected,
    indicatorTexts,

    handleNextButton,
    handleTabButton,
    handleClickIndicator,
    handleRentalDate,
    handleDriverInfo,
  };
};

export { useSearchOptions };
