import { VehicleModelWithInsurancesForD2CDto } from '@orcar/common';
import {
  convertToKST,
  filterOptionAtom,
  PAGE_NAME,
  SearchResultBlock,
  SESSION_STORAGE_KEY,
  useRecoil,
} from '@orcar/common-d2c';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchResultProps } from '../../components/SearchResult';
import { TopButton } from '../../components/TopButton';
import SearchFilterNull from '../../components/vehicleCard/SearchFilterNull';

interface MobileSearchResultProps extends SearchResultProps {
  filterResult?: VehicleModelWithInsurancesForD2CDto[];
}

const MobileSearchResult = ({
  searchOptions,
  filterResult,
}: MobileSearchResultProps) => {
  const navigate = useNavigate();

  const { recoilValue: recoilFilterOption } = useRecoil(filterOptionAtom);

  const [fadeInTrigger, setFadeInTrigger] = useState(false);

  const handleSelectProducts = async (id: number, insuranceName: string) => {
    sessionStorage.setItem(SESSION_STORAGE_KEY.PRODUCT_ID, String(id));

    const pickUpAt = convertToKST(searchOptions.pickUpAt);
    const dropOffAt = convertToKST(searchOptions.dropOffAt);

    navigate({
      pathname: PAGE_NAME.order_confirmation,
      search: `?pickUpAt=${pickUpAt}&dropOffAt=${dropOffAt}&insuranceName=${insuranceName}`,
    });
  };

  useEffect(() => {
    setFadeInTrigger(true);
    setTimeout(() => {
      setFadeInTrigger(false);
    }, 100);
  }, [recoilFilterOption]);

  return (
    <div className='flex h-full flex-col gap-3'>
      {filterResult?.length === 0 ? (
        <SearchFilterNull />
      ) : (
        filterResult?.map((vehicleModel) => (
          <SearchResultBlock
            key={vehicleModel.id}
            platform='mobile'
            vehicleModel={vehicleModel}
            unavailable={vehicleModel.availableCount === 0}
            handleClickCard={handleSelectProducts}
            fadeInTrigger={fadeInTrigger}
          />
        ))
      )}
      {filterResult?.length !== 0 && <TopButton />}
    </div>
  );
};

export default MobileSearchResult;
