import { isMobileAtom, useRecoil } from '@orcar/common-d2c';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import AgreementNonMemberPrivacy from '@/assets/img/img_agreement-non-member-privacy.avif';
import AgreementOfferPrivacy from '@/assets/img/img_agreement-offer-privacy.avif';
import AgreementPaymentPrivacy from '@/assets/img/img_agreement-payment-privacy.avif';
import CommercePolicy from '@/assets/img/img_footer-link-commerce.avif';
import PrivacyPolicy from '@/assets/img/img_footer-link-privacy.png';
import RentPolicy from '@/assets/img/img_footer-link-rent.avif';
import TravelPolicy from '@/assets/img/img_footer-link-travel.avif';

const PolicyPage = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const [queryString, setQueryString] = useState('');
  const [imgUrl, setImgUrl] = useState<string>('');

  const { recoilValue: isMobile } = useRecoil(isMobileAtom);

  useEffect(() => {
    if (type) {
      setQueryString(type);
    }
  }, [type]);

  useEffect(() => {
    switch (queryString) {
      case 'travel':
        return setImgUrl(TravelPolicy);
      case 'privacy':
        return setImgUrl(PrivacyPolicy);
      case 'commerce':
        return setImgUrl(CommercePolicy);
      case 'rent':
        return setImgUrl(RentPolicy);
      case 'agreement-non-member-privacy':
        return setImgUrl(AgreementNonMemberPrivacy);
      case 'agreement-offer-privacy':
        return setImgUrl(AgreementOfferPrivacy);
      case 'agreement-payment-privacy':
        return setImgUrl(AgreementPaymentPrivacy);
      case 'agreement-rent':
        return setImgUrl(RentPolicy);
      default:
        return;
    }
  }, [queryString]);
  return (
    <Wrapper isMobile={isMobile}>
      {!!imgUrl && <img src={imgUrl} width='100%' />}
    </Wrapper>
  );
};

export default PolicyPage;

const Wrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  width: 100%;
  margin-top: ${({ isMobile }) => (isMobile ? '0' : '-70px')};
`;
