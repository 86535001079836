import {
  EXCLUSION_SUPER_INSURANCE_COVERAGE,
  INSURANCE_INFO,
  INSURANCE_RANGE,
  isMobileAtom,
  ListIcon,
  SpeechBubble,
  UNLIMITED_CONTRACT_COMPENSATION_LIMIT,
  UNLIMITED_CONTRACT_DISPATCH,
  useRecoil,
} from '@orcar/common-d2c';
import MobileInsuranceInfoImg from '@/assets/img/img_insurance-info-mobile.png';
import * as S from '../styles';

const InsuranceInfo = () => {
  const { recoilValue: isMobile } = useRecoil(isMobileAtom);
  return (
    <>
      {isMobile ? (
        <img
          src={MobileInsuranceInfoImg}
          width='100%'
          alt='모바일버전 보험안내 이미지'
          style={{ maxWidth: '350px' }}
        />
      ) : (
        <S.Wrapper isMobile={isMobile}>
          <S.Title type='page'>렌터카 보험 안내</S.Title>
          <S.Section>
            <S.Title type='section'>
              렌터카에 적용되는 보험은 어떻게 나눠지나요?
            </S.Title>
            <S.Text type='main'>
              렌터카 업체에서 가입한 ‘종합 보험’과 운전자가 가입한
              ‘자차면책제도’가 있습니다.
              <br />
              대인(상대방), 대물(상대방의 차량), 자손(나), 자차(나의 차량)를
              기준으로 적용되는 보험이 상이합니다.
            </S.Text>
          </S.Section>
          <S.Section border sub>
            <img src={ListIcon} />
            <S.Title>종합 보험</S.Title>
            <S.Text>
              렌터카 업체에서 보험사에 가입한 보험입니다. <br />
              차량을 대여하시면 자동으로 가입되는 보험으로 고객에게 추가로
              보험비를 요구하지 않는 항목으로 대인, 대물, 자손에 적용됩니다.
              <br /> 자동차 사고시 보험 처리를 희망하시면 고객은 피해금액과
              상관없이 1건 당{' '}
              <S.Text type='emphasis'>
                대인(1인당 30만원), 대물(1건당 30~50만원), 자손(1인당 30만원)
              </S.Text>
              까지 지불하게 됩니다.
              <br />
              계약서에 등록된 임차인(제1운전자)과 제2운전자는 자손보험으로
              처리됩니다.
            </S.Text>
          </S.Section>
          <S.Title isColor>종합 보험의 경우</S.Title>
          <S.Text type='main'>
            종합 보험 한도 초과 시 초과금은 임차인이 부담하며 계약서에 등록된
            임차인(제1운전자)과 제2운전자는 자손보험으로 처리됩니다. <br />
            고객은 피해금액과 상관없이 1건 당{' '}
            <S.Text type='emphasis'>
              대인(1인당 30만원), 대물(1건당 30~50만원), 자손(1인당 30만원)
            </S.Text>
            까지 지불하게 됩니다.
          </S.Text>
          <S.TableWrapper>
            <S.Table>
              <thead>
                <S.TableRow>
                  <S.Th>대인</S.Th>
                  <S.Th>대물</S.Th>
                  <S.Th>자손</S.Th>
                </S.TableRow>
              </thead>
              <tbody>
                <S.TableRow>
                  <S.Td>무한</S.Td>
                  <S.Td>2,000만원</S.Td>
                  <S.Td>1,500만원</S.Td>
                </S.TableRow>
              </tbody>
            </S.Table>
          </S.TableWrapper>
          <S.Section>
            <S.Title type='section'>
              자차 보험의 종류와 보장 항목이 궁금해요!
            </S.Title>
            <S.Text type='main'>
              보험의 종류는 ‘일반자차’, ‘고급자차’, ‘슈퍼자차’가 있으며, 보장
              범위에 따라 구분됩니다.
              <br />
              아래 보장 항목은 렌터카 업체마다 상이할 수 있으니, 예약 단계에서
              확인해 주세요.
            </S.Text>
          </S.Section>
          <S.TableWrapper>
            <S.Table>
              <thead>
                <S.TableRow height='93px'>
                  <S.Th isFirst>구분</S.Th>
                  {INSURANCE_RANGE.map((item, idx) => (
                    <S.Th key={idx}>
                      {item.insurance}
                      <SpeechBubble xPosition={item.xPosition}>
                        {item.info}
                      </SpeechBubble>
                    </S.Th>
                  ))}
                </S.TableRow>
              </thead>
              <tbody>
                {INSURANCE_INFO.map((table, idx) => (
                  <S.TableRow key={idx} height='80px'>
                    <S.Td header>
                      {table.title}
                      {table.subs.map((sub, idx) => (
                        <p key={idx}>{sub}</p>
                      ))}
                    </S.Td>
                    {table.items.map((item, idx) => (
                      <S.Td key={idx}>{item}</S.Td>
                    ))}
                  </S.TableRow>
                ))}
              </tbody>
            </S.Table>
            <S.Table width='705px'>
              <tbody>
                <S.TableRow>
                  <S.Th>현장출동 특약 추가 시</S.Th>
                  <S.Td>휠/타이어 보장</S.Td>
                  <S.Td>휠/타이어 보장</S.Td>
                </S.TableRow>
                <S.TableRow>
                  <S.Th>무제한 특약 추가 시</S.Th>
                  <S.Td>
                    보상한도 무제한
                    <br />
                    휠/타이어 보장
                  </S.Td>
                  <S.Td>
                    보상한도 무제한
                    <br />
                    휠/타이어 보장
                  </S.Td>
                </S.TableRow>
              </tbody>
            </S.Table>
          </S.TableWrapper>
          <S.Section>
            <S.Title type='section'>차량 손해 면책(자차보험) 계약 제도</S.Title>
            <ul>
              <S.List>
                임차인은 차량 사고 발생 시 손해를 줄이기 위해 자기차량손해보험을
                사전에 개별적으로 가입해서 이용할 수 있으며, 또는 당사가
                임차인을 보호하기 위해 운영하는 차량손해면책(자차보험) 계약 제도
                중 하나를 선택하여 계약할 수 있습니다.
              </S.List>
              <S.List>
                차량손해면책(자차보험) 계약 제도 임차인이 렌트카를 임차 시점부터
                회사에 반환하는 시점까지 선량한 관리자의 주의의무를 다하여
                렌트카를 사용하고 보관하며, 제반교통법규를 성실히 준수하여
                이용하는 조건하에 당사와 임차인이 체결하는 계약제도이며,
                임차인이 사고 발생 시 임차차량 수리비용에 대해 계약 내용에서
                정한 일정 부분을 면책받는 제도입니다.
              </S.List>
              <S.List>
                차량손해면책 계약은 반드시 내용 및 제외 항목 등 을 충분히
                숙지하고 가입하여야 합니다.
              </S.List>
              <S.List>
                차량손해면책 요금은 총 대여일수로 산출하고{' '}
                <S.Text type='emphasis'>1회 소멸성</S.Text>
                으로 적용합니다. (예, 2박3일 : 3일적용, 임차기간 중 일부기간만
                가입은 불가합니다.)
              </S.List>
              <S.List>
                차량 임차 이후에 유선상으로 손해면책 계약 유형 변경은 불가하며,
                계약 유형 변경은 본사 방문 후 가능합니다.
              </S.List>
            </ul>
          </S.Section>
          <S.Section>
            <S.Title type='section'>
              차량 손해 면책(자차차보험) 계약 제도 제외 항목
            </S.Title>
            <ol>
              <S.List number>
                당사 미 통보 사고
                <ul>
                  <S.List>
                    <S.Text type='highlight'>
                      단독사고(경미한 파손 및 훼손 포함) 및 차 대 차사고 발생 시
                      즉시 현장에서 당사로 통보해야 합니다.
                    </S.Text>
                  </S.List>
                  <S.List>
                    차량 반납 이후 다음 임차인에게 대여되는 차량이므로 당사로
                    통보되지 않을 시 원활한 예약 및 출고에 많은 제약 상황이
                    발생합니다.
                  </S.List>
                  <S.List>
                    <S.Text type='emphasis'>
                      당사로 통보되지 않을 시 자차보험 처리 불가
                    </S.Text>
                  </S.List>
                </ul>
              </S.List>
              <S.List number>
                최초 사고 1건을 초과하는 사고
                <ul>
                  <S.List>
                    <S.Text type='highlight'>
                      차량손해면책(자차보험) 제도는 1회 소멸성 보험이므로, 최초
                      사고 1건 발생 시 자동 소멸됩니다.
                    </S.Text>
                  </S.List>
                  <S.List>
                    단독사고 및 차 대 차사고 발생 시 차량손해 면책계약은 자동
                    해지 됩니다.
                  </S.List>
                  <S.List>추가 사고 발생 시 전액 고객 부담</S.List>
                </ul>
              </S.List>
              <S.List number>
                명백한 교통법규 위반 사고(음주운전, 신호 위반, 불법 유턴, 중앙선
                침범 등)
              </S.List>
              <S.List number>
                소모품
                <ul>
                  <S.List>
                    <S.Text type='highlight'>
                      타이어, 휠, 키 분실 및 훼손, 네비게이션, 실내부품 등
                    </S.Text>
                  </S.List>
                </ul>
              </S.List>
              <S.List number>
                임차인 부주의로 인한 출동 및 견인, 구난
                <ul>
                  <S.List>
                    타이어 펑크 또는 파스, 배터리 방전, 사고 후 운행 불가 상태
                  </S.List>
                </ul>
              </S.List>
              <S.List number>
                혼유사고
                <ul>
                  <S.List>유종이 다른 연료를 주입한 경우</S.List>
                </ul>
              </S.List>
              <S.List number>
                제주 본섬 외 지역(우도 등) 무단반출 후 사고
              </S.List>
              <S.List number>
                전기 차량 주 배터리 충전 혹은 사고로 인한 파손, 무리한 조작으로
                인한 충전기 삽입구 파손 및 훼손
                <ul>
                  <S.List>과열 상태의 배터리 충전 혹은 사고로 인한 파손</S.List>
                  <S.List>
                    무리한 조작으로 인한 충전기 상입구 파손 및 훼손
                  </S.List>
                </ul>
              </S.List>
              <S.List number>차량 손해 면책 보상한도 초과 금액</S.List>
              <S.List number>
                태풍, 폭우, 폭설 등 자연 재해 속에서 무리한 운전으로 인한 차량
                훼손
                <ul>
                  <S.List>폭우 중 침수지역 주행으로 인한 엔진 침수</S.List>
                  <S.List>폭설 중 통제된 도로 진입 후 사고 등</S.List>
                </ul>
              </S.List>
            </ol>
          </S.Section>
          <S.Section>
            <S.Title type='section'>
              차량 손해 면책(자차보험) 보상한도 무제한&현장출동 특약
            </S.Title>
            <ul>
              {UNLIMITED_CONTRACT_COMPENSATION_LIMIT.map((item, idx) => (
                <S.List key={idx}>{item}</S.List>
              ))}
            </ul>
          </S.Section>
          <S.Section>
            <S.Title type='section'>
              차량 손해 면책(자차보험) 현장출동 특약
            </S.Title>
            <ul>
              {UNLIMITED_CONTRACT_DISPATCH.map((item, idx) => (
                <S.List key={idx} none={idx === 2}>
                  {item}
                </S.List>
              ))}
            </ul>
          </S.Section>
          <S.Section>
            <S.Title type='section'>
              무제한 특약 및 제외 항목 특약 적용 불가 항목
            </S.Title>
            <ul>
              {EXCLUSION_SUPER_INSURANCE_COVERAGE.map((item, idx) => (
                <S.List key={idx}>{item}</S.List>
              ))}
            </ul>
          </S.Section>
        </S.Wrapper>
      )}
    </>
  );
};

export default InsuranceInfo;
