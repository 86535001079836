import {
  Fuel,
  translateVehicleModelType,
  VehicleModelType,
  VehicleModelWithInsurancesForD2CDto,
} from '@orcar/common';
import {
  findCheapestInsurance,
  getModelFuel,
  hasBlackBoxInVehicleModelType,
  IMG_AVANTE,
  IMG_COOPER,
  IMG_GRENDEUR,
  IMG_IONIQ5,
  IMG_RAY,
  IMG_SELTOS,
  IMG_SONATA,
  IMG_STAREX,
  RecommendationCard,
  Styles,
} from '@orcar/common-d2c';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import { SwiperWrapper } from '@/components/recommendSwiper';
import TwoByTwoShape from './TwoByTwoShape';

type RecommendSectionProps = {
  vehicleModelList?: VehicleModelWithInsurancesForD2CDto[];
  onClick: (id: VehicleModelType | Fuel) => void;
  isMobile: boolean;
};

export type RecommendCarType = {
  id: VehicleModelType | Fuel;
  type: string;
  img_url: any;
  text: string;
  price: number;
  discountPrice?: number;
  hasBlackBox?: boolean;
};

const RecommendSection = ({
  vehicleModelList,
  onClick,
  isMobile,
}: RecommendSectionProps) => {
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);

  const defaultRecommendCarList: RecommendCarType[] = [
    {
      id: VehicleModelType.URBAN,
      type: translateVehicleModelType(VehicleModelType.URBAN),
      img_url: IMG_RAY,
      text: '주차하기 편해요',
      price:
        findCheapestInsurance(VehicleModelType.URBAN, vehicleModelList)
          ?.finalPrice || 0,
      discountPrice:
        findCheapestInsurance(VehicleModelType.URBAN, vehicleModelList)
          ?.userFinalPrice || 0,
      hasBlackBox: hasBlackBoxInVehicleModelType(
        VehicleModelType.URBAN,
        vehicleModelList,
      ),
    },
    {
      id: VehicleModelType.COMPACT,
      type: translateVehicleModelType(VehicleModelType.COMPACT),
      img_url: IMG_AVANTE,
      text: '가성비 갑!',
      price:
        findCheapestInsurance(VehicleModelType.COMPACT, vehicleModelList)
          ?.finalPrice || 0,
      discountPrice:
        findCheapestInsurance(VehicleModelType.COMPACT, vehicleModelList)
          ?.userFinalPrice || 0,
      hasBlackBox: hasBlackBoxInVehicleModelType(
        VehicleModelType.COMPACT,
        vehicleModelList,
      ),
    },
    {
      id: VehicleModelType.MIDSIZE,
      type: translateVehicleModelType(VehicleModelType.MIDSIZE),
      img_url: IMG_SONATA,
      text: '가족끼리 여행할때',
      price:
        findCheapestInsurance(VehicleModelType.MIDSIZE, vehicleModelList)
          ?.finalPrice || 0,
      discountPrice:
        findCheapestInsurance(VehicleModelType.MIDSIZE, vehicleModelList)
          ?.userFinalPrice || 0,
      hasBlackBox: hasBlackBoxInVehicleModelType(
        VehicleModelType.MIDSIZE,
        vehicleModelList,
      ),
    },
    {
      id: VehicleModelType.LUXURY,
      type: translateVehicleModelType(VehicleModelType.LUXURY),
      img_url: IMG_GRENDEUR,
      text: '편안한 이동을 원한다면',
      price:
        findCheapestInsurance(VehicleModelType.LUXURY, vehicleModelList)
          ?.finalPrice || 0,
      discountPrice:
        findCheapestInsurance(VehicleModelType.LUXURY, vehicleModelList)
          ?.userFinalPrice || 0,
      hasBlackBox: hasBlackBoxInVehicleModelType(
        VehicleModelType.LUXURY,
        vehicleModelList,
      ),
    },
    {
      id: VehicleModelType.IMPORTED,
      type: translateVehicleModelType(VehicleModelType.IMPORTED),
      img_url: IMG_COOPER,
      text: '연인과의 화려한 여행엔',
      price:
        findCheapestInsurance(VehicleModelType.IMPORTED, vehicleModelList)
          ?.finalPrice || 0,
      discountPrice:
        findCheapestInsurance(VehicleModelType.IMPORTED, vehicleModelList)
          ?.userFinalPrice || 0,
      hasBlackBox: hasBlackBoxInVehicleModelType(
        VehicleModelType.IMPORTED,
        vehicleModelList,
      ),
    },
    {
      id: VehicleModelType.RV,
      type: '승합', // 모바일 추천차량 카드 UI 깨지는 이슈로 임시 수정
      img_url: IMG_STAREX,
      text: '5인 이상, 함께 이동해요',
      price:
        findCheapestInsurance(VehicleModelType.RV, vehicleModelList)
          ?.finalPrice || 0,
      discountPrice:
        findCheapestInsurance(VehicleModelType.RV, vehicleModelList)
          ?.userFinalPrice || 0,
      hasBlackBox: hasBlackBoxInVehicleModelType(
        VehicleModelType.RV,
        vehicleModelList,
      ),
    },
    {
      id: VehicleModelType.SUV,
      type: translateVehicleModelType(VehicleModelType.SUV),
      img_url: IMG_SELTOS,
      text: '짐이 많다면',
      price:
        findCheapestInsurance(VehicleModelType.SUV, vehicleModelList)
          ?.finalPrice || 0,
      discountPrice:
        findCheapestInsurance(VehicleModelType.SUV, vehicleModelList)
          ?.userFinalPrice || 0,
      hasBlackBox: hasBlackBoxInVehicleModelType(
        VehicleModelType.SUV,
        vehicleModelList,
      ),
    },
    {
      id: Fuel.EV,
      type: getModelFuel(Fuel.EV),
      img_url: IMG_IONIQ5,
      text: '환경을 생각하는 전기차',
      price:
        findCheapestInsurance(VehicleModelType.EV, vehicleModelList)
          ?.finalPrice || 0,
      discountPrice:
        findCheapestInsurance(VehicleModelType.EV, vehicleModelList)
          ?.userFinalPrice || 0,
      hasBlackBox: hasBlackBoxInVehicleModelType(
        VehicleModelType.EV,
        vehicleModelList,
      ),
    },
  ];

  const filteredRecommendCarList = defaultRecommendCarList.reduce(
    (acc, cur) => {
      if (cur.price === 0) return acc;
      return acc.concat(cur);
    },
    [] as RecommendCarType[],
  );

  const getSlidesPerViewOfRecommendCars = (
    recommendCarList?: RecommendCarType[],
  ) => {
    if (!recommendCarList) return 0;

    const result = recommendCarList?.filter(
      (recommendCar) => recommendCar.price !== 0,
    ).length;

    return Math.min(result, 4);
  };

  return (
    <RecommendCarWrapper isMobile={isMobile}>
      <RecommendCaredInnerWrapper
        isMobile={isMobile}
        slidesPerView={getSlidesPerViewOfRecommendCars(
          filteredRecommendCarList,
        )}
      >
        <MobileTitleWrapper isMobile={isMobile}>
          <TitleWrapper>
            <SectionTitle isMobile={isMobile}>
              어떤 차가 필요하세요?
            </SectionTitle>
            {isMobile && filteredRecommendCarList.length > 4 && (
              <span>{currentSlideIdx + 1}/2</span>
            )}
          </TitleWrapper>
        </MobileTitleWrapper>

        {isMobile ? (
          <TwoByTwoShapeWrapper>
            <TwoByTwoShape
              recommendCarData={filteredRecommendCarList}
              isMobile={isMobile}
              onClick={onClick}
              setCurrentSlideIdx={setCurrentSlideIdx}
            />
          </TwoByTwoShapeWrapper>
        ) : (
          !!getSlidesPerViewOfRecommendCars(filteredRecommendCarList) && (
            <CardWrapper>
              <SwiperWrapper
                slidesPerView={getSlidesPerViewOfRecommendCars(
                  filteredRecommendCarList,
                )}
                spaceBetween={24}
                height={'300px'}
                setCurrentSlideIdx={setCurrentSlideIdx}
                leftNavButtonPosition={{
                  left: -34,
                }}
                rightNavButtonPosition={{
                  right: -34,
                }}
              >
                {filteredRecommendCarList.map(
                  (car) =>
                    !!car.price && (
                      <SwiperSlide key={car.id}>
                        <RecommendationCard
                          platform='desktop'
                          imgUrl={car.img_url}
                          title={car.text}
                          fixedPrice={car.price}
                          membershipPrice={car.discountPrice}
                          vehicleModelType={car.type}
                          hasBlackBox={car.hasBlackBox}
                          onClick={() => onClick(car.id)}
                        />
                      </SwiperSlide>
                    ),
                )}
              </SwiperWrapper>
            </CardWrapper>
          )
        )}
      </RecommendCaredInnerWrapper>
    </RecommendCarWrapper>
  );
};

export default RecommendSection;

const RecommendCarWrapper = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
`;

const RecommendCaredInnerWrapper = styled.div<{
  isMobile: boolean;
  slidesPerView?: number;
}>`
  padding-bottom: ${({ isMobile }) => isMobile && '40px'};
  width: ${({ slidesPerView, isMobile }) => {
    if (isMobile) return '100%';

    switch (slidesPerView) {
      case 2:
        return '600px';
      case 3:
        return '900px';
      default:
        return Styles.WIDTH.PC_LAYOUT;
    }
  }};
`;

const TitleWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${`calc(${Styles.WIDTH.MOBILE_LAYOUT} - 40px)`};

  & > span {
    &:last-child {
      color: ${Styles.COLOR.GREY_02};
    }
  }
`;

const SectionTitle = styled.p<{ isMobile: boolean }>`
  ${({ isMobile }) => (isMobile ? Styles.FONT.H5 : Styles.FONT.H3)};
`;

const CardWrapper = styled.div<{
  isMobile?: boolean;
  isSumcar?: boolean;
}>`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  ${({ isMobile, isSumcar }) =>
    (isMobile || isSumcar) &&
    css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: ${isSumcar ? '20px 7px' : '7px'};
      margin-top: 30px;
    `}
`;

const TwoByTwoShapeWrapper = styled.div<{
  inner?: boolean;
  isMobile?: boolean;
}>`
  display: flex;
  justify-content: center;
  width: 100%;
  ${({ inner, isMobile }) =>
    inner &&
    isMobile &&
    css`
      max-width: ${`calc(${Styles.WIDTH.MOBILE_LAYOUT} - 40px)`};
    `}
`;

const MobileTitleWrapper = styled.span<{ isMobile: boolean }>`
  ${({ isMobile }) =>
    isMobile &&
    css`
      display: flex;
      justify-content: center;
    `}
`;
