import {
  ALERT_MESSAGE_TYPE,
  PAGE_NAME,
  SkeletonReservationDetail,
  useGetReservation,
} from '@orcar/common-d2c';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import D2CReservationDetail from './components/D2CReservationDetail';

const ReservationDetailPage = () => {
  const navigate = useNavigate();
  const { reservationId } = useParams();

  const {
    data: reservationData,
    isLoading: isLoadingReservation,
    error,
  } = useGetReservation(reservationId);

  useEffect(() => {
    if (error) {
      if (error.response?.data.statusCode === 401) {
        alert(ALERT_MESSAGE_TYPE.UNAUTHORIZE_ACCESS);
      } else if (error.response?.data.statusCode === 403) {
        alert(ALERT_MESSAGE_TYPE.INVALID_TOKEN);
      } else {
        alert(ALERT_MESSAGE_TYPE.INVALID_RESERVATION);
      }
      navigate(PAGE_NAME.index, { replace: true });
    }
  }, [error]);

  if (isLoadingReservation) return <SkeletonReservationDetail />;

  return (
    reservationData && (
      <D2CReservationDetail
        reservationData={reservationData}
        preReservation={reservationData.preReservation}
      />
    )
  );
};

export default ReservationDetailPage;
