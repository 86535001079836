import { Nullable } from '@orcar/common';
import {
  Button,
  CalendarIcon,
  ClockIcon,
  getDateOptionText,
  getDriverInfoOptionText,
  getNumOfPeopleOptionText,
  getPrimarySvgColor,
  getTimeOptionText,
  HandleIcon,
  Icon,
  PeopleIcon,
  QUERY_KEY,
  SearchOption,
  SearchParamsType,
  selectOptionAtom,
  Styles,
  useRecoil,
} from '@orcar/common-d2c';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components';
import { APP_NAME } from '@/constants/common';
import OptionChangeModal from './OptionChangeModal';

type ButtonProps = {
  children: string;
  width?: number;
  iconUrl: string;
  selected: boolean;
  selectOption?: Nullable<string>;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

type OptionSearchStyleType = {
  selectOption?: Nullable<string>;
};

interface OptionsSearchProps extends OptionSearchStyleType {
  searchOptions: SearchParamsType;
}

const OptionSearchButton = ({
  children,
  width,
  iconUrl,
  selected,
  selectOption,
  onClick,
}: ButtonProps) => {
  return (
    <ButtonWrapper
      onClick={onClick}
      width={width || 292}
      selected={selected}
      selectOption={selectOption ? selectOption : null}
    >
      <Icon
        src={iconUrl}
        width={19}
        height={19}
        margin='0 10px 0 25px'
        iconcolor={getPrimarySvgColor(APP_NAME)}
      />
      {children}
    </ButtonWrapper>
  );
};

const OptionSearch = ({ searchOptions }: OptionsSearchProps) => {
  const queryClient = useQueryClient();
  const {
    recoilState: [searchOptionId, setSearchOptionId],
  } = useRecoil(selectOptionAtom);
  const isFetching =
    useIsFetching({
      queryKey: [QUERY_KEY.GET_VEHICLE_MODELS],
    }) > 0;

  const optionButtonArr = [
    {
      id: SearchOption.Date,
      iconUrl: CalendarIcon,
      textState: getDateOptionText(
        dayjs(searchOptions.pickUpAt),
        dayjs(searchOptions.dropOffAt),
        true,
      ),
    },
    {
      id: SearchOption.DriverInfo,
      iconUrl: HandleIcon,
      textState: getDriverInfoOptionText(
        searchOptions.driverAge,
        searchOptions.drivingExperience,
        APP_NAME,
      ),
    },
    {
      id: SearchOption.NumberOfPeople,
      iconUrl: PeopleIcon,
      textState: getNumOfPeopleOptionText(searchOptions.numberOfPeople),
    },
    {
      id: SearchOption.Time,
      iconUrl: ClockIcon,
      textState: getTimeOptionText(
        dayjs(searchOptions.pickUpAt),
        dayjs(searchOptions.dropOffAt),
        true,
      ),
    },
  ];

  return (
    <OuterWrapper>
      <InnerWrapper>
        {optionButtonArr?.map((option) => (
          <OptionSearchButton
            key={option.id}
            iconUrl={option.iconUrl}
            selected={option.id === searchOptionId}
            selectOption={searchOptionId}
            onClick={() => setSearchOptionId(option.id)}
          >
            {option.textState}
          </OptionSearchButton>
        ))}
        {!!searchOptionId && (
          <OptionChangeModal
            searchOptionId={searchOptionId}
            setSearchOptionId={setSearchOptionId}
            onClose={() => setSearchOptionId(null)}
            initialSearchOptions={searchOptions}
          />
        )}
        <Button
          width='78px'
          onClick={() => {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.GET_VEHICLE_MODELS],
            });
          }}
          font={Styles.FONT.B1}
          isLoading={isFetching}
          borderRadius='10px'
          disabled={!!searchOptionId}
        >
          검색
        </Button>
      </InnerWrapper>
    </OuterWrapper>
  );
};

export default OptionSearch;

const OuterWrapper = styled.div`
  position: fixed;
  top: 142px;
  @media (max-width: ${({ theme }) => theme.WIDTH.PC_LAYOUT}) {
    left: ${({ theme }) => `calc(${parseFloat(theme.WIDTH.PC_LAYOUT) / 2}px)`};
  }
  @media (min-width: ${({ theme }) => theme.WIDTH.PC_LAYOUT}) {
    left: 50%;
  }
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: ${({ theme }) => theme.WIDTH.PC_LAYOUT};
  height: 108px;
  padding: 40px 0 20px;
  margin-top: -50px;
  background-color: ${({ theme }) => theme.COLOR.GREY_BG};
  transform: translateX(-50%);
  transition: transform 0.3s;
  z-index: 3;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1384px;
`;

const ButtonWrapper = styled.div<
  Pick<ButtonProps, 'width' | 'selected' | 'selectOption'>
>`
  display: flex;
  align-items: center;
  width: ${({ width }) => `${width}px`};
  ${({ theme }) => theme.FONT.B1};
  background-color: ${({ selectOption, selected, theme }) =>
    (!selectOption || selected) && theme.COLOR.WHITE};
  border: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  border-radius: 10px;
  user-select: none;
  cursor: pointer;
  z-index: ${({ selected }) => selected && '10'};
`;
