import { Viewer } from '@orcar/common-d2c';
import styled from 'styled-components';
import { SectionTitle } from './commonStyles';

type RentPolicyProps = {
  name: string;
  rentPolicies: string;
  isMobile: boolean;
  hasPadding?: boolean;
  gap?: number;
};

const RentPolicy = ({
  name,
  rentPolicies,
  isMobile,
  hasPadding,
  gap,
}: RentPolicyProps) => {
  return (
    <ContentWrapper hasPadding={hasPadding} gap={gap}>
      <SectionTitle isMobile={isMobile}>{name} 대여 규정</SectionTitle>
      <ViewerWrapper>
        <Viewer content={rentPolicies} style={{ lineHeight: '27px' }} />
      </ViewerWrapper>
    </ContentWrapper>
  );
};

export default RentPolicy;

const ContentWrapper = styled.div<{
  hasPadding?: boolean;
  isMobile?: boolean;
  gap?: number;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? `${gap}px` : '0px')};
  width: 100%;
  padding: ${({ hasPadding }) => hasPadding && '30px 0'};
  scroll-margin: ${({ isMobile }) => (isMobile ? '110px' : '150px')};
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.COLOR.GREY_01};
  white-space: pre-wrap;
`;

const ViewerWrapper = styled.div`
  padding: 4px 0;
`;
