import 'dayjs/locale/ko';
import '../../common/tailwind.css';
import { CommonD2CWrapper, Toast } from '@orcar/common-d2c';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Analytics } from '@vercel/analytics/react';
import { extend, locale } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import Layout from './components/layout/Layout';
import GlobalStyle from './styles/GlobalStyle';

extend(utc);
extend(timezone);
extend(isBetween);
locale('ko');

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <CommonD2CWrapper>
      <Layout>
        <App />
      </Layout>
      <GlobalStyle />
      <Analytics />
      <Toast />
      <ReactQueryDevtools initialIsOpen={false} />
    </CommonD2CWrapper>
  </React.StrictMode>,
);
