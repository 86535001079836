import { Nullable } from '@orcar/common';
import { DriverOptionType, OptionLabel } from '@orcar/common-d2c';
import DriverOption from './DriverOption';
import * as S from './styles';

type DriverOptionSectionProps = {
  title: string;
  description: string;
  options: Array<DriverOptionType>;
  onSelect: (key: Nullable<DriverOptionType>) => void;
  selectedOption: Nullable<DriverOptionType>;
  isMobile?: boolean;
  isLast?: boolean;
};

const DriverOptionSection = ({
  title,
  description,
  options,
  onSelect,
  selectedOption,
  isMobile,
  isLast,
}: DriverOptionSectionProps) => {
  return (
    <S.DriverOptionSectionWrapper isLast={isLast} isMobile={isMobile}>
      <OptionLabel title={title} description={description} />
      <S.OptionsWrapper isMobile={isMobile}>
        {options.map((option) => (
          <DriverOption
            option={option}
            selected={selectedOption?.key === option.key}
            onSelect={onSelect}
            key={option.key}
          />
        ))}
      </S.OptionsWrapper>
    </S.DriverOptionSectionWrapper>
  );
};

export default DriverOptionSection;
