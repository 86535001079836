import { isMobileAtom, useRecoil } from '@orcar/common-d2c';
import SearchLayout from './components/SearchLayout';
import MobileSearchLayout from './mobile/MobileSearchLayout';

const SearchPage = () => {
  const { recoilValue: isMobile } = useRecoil(isMobileAtom);
  return isMobile ? <MobileSearchLayout /> : <SearchLayout />;
};

export default SearchPage;
