import {
  ALERT_MESSAGE_TYPE,
  authAtom,
  cn,
  hasToken,
  isMobileAtom,
  PAGE_NAME,
  useGetReservations,
  useGetReservationsByUser,
  useRecoil,
} from '@orcar/common-d2c';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { MY_PAGE_TABS } from '@/components/layout/MyPageWrapper';
import ReservationList from './components/ReservationList';

const MyPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { recoilValue: user } = useRecoil(authAtom);
  const { recoilValue: isMobile } = useRecoil(isMobileAtom);

  const [searchParams] = useSearchParams();
  const customerName = searchParams.get('customerName') || '';
  const customerPhoneNumber = searchParams.get('customerPhoneNumber') || '';

  const {
    data: reservationList,
    isError: reservationListError,
    isLoading: reservationListLoading,
  } = useGetReservations({
    params: {
      customerPhoneNumber,
      customerName,
    },
    enabled: !user && !hasToken(),
  });

  const {
    data: reservationListByUser,
    isError: reservationListByUserError,
    isLoading: reservationListByUserLoading,
  } = useGetReservationsByUser({
    enabled: !!user && !!hasToken(),
  });

  const onReservationCardClick = (reservationId?: string) => {
    if (!reservationId) return;
    navigate(PAGE_NAME.reservation_detail + `/${reservationId}`);
  };

  useEffect(() => {
    if (reservationListError || reservationListByUserError) {
      alert(ALERT_MESSAGE_TYPE.FAIL_ORDER_CONFIRMATION);
      navigate(PAGE_NAME.find_reservation);
    }
  }, [reservationListError, reservationListByUserError]);

  useEffect(() => {
    if (!user && !hasToken()) {
      if (reservationList?.length === 0) {
        alert(ALERT_MESSAGE_TYPE.FAIL_ORDER_CONFIRMATION);
        navigate(PAGE_NAME.find_reservation);
        return;
      }

      if (!customerName || !customerPhoneNumber) {
        alert(ALERT_MESSAGE_TYPE.INVALID_RESERVATION);
        navigate(PAGE_NAME.find_reservation);
      }
    }
  }, [user, hasToken, reservationList, customerName, customerPhoneNumber]);

  if (
    pathname.includes(MY_PAGE_TABS[0].pathname) ||
    pathname === PAGE_NAME.my_page
  )
    return (
      <div
        className={cn(
          'flex flex-col items-center',
          isMobile && 'w-full px-5 py-4',
          !isMobile && 'w-[792px]',
        )}
      >
        {!!user ? (
          <ReservationList
            isLoading={reservationListByUserLoading}
            reservationList={reservationListByUser}
            onReservationCardClick={onReservationCardClick}
          />
        ) : (
          <ReservationList
            isLoading={reservationListLoading}
            reservationList={reservationList}
            customerName={customerName}
            onReservationCardClick={onReservationCardClick}
          />
        )}
      </div>
    );
};

export default MyPage;
