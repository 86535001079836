export const DRIVER_OPTIONS = {
  driverAge: [
    {
      key: 'ltTwentySix',
      value: 25,
      text: '만 21세 ~ 25세',
    },
    {
      key: 'gteTwentySix',
      value: null,
      text: '만 26세 이상',
    },
  ],
  numberOfPeople: [
    {
      key: 'gteOne',
      value: 1,
      text: '1인 ~ 2인',
    },
    {
      key: 'gteThree',
      value: 3,
      text: '3인 ~ 5인',
    },
    {
      key: 'gteSix',
      value: 6,
      text: '6인 이상',
    },
    {
      key: 'notNow',
      value: null,
      text: '지금 선택 안 함',
    },
  ],
  drivingExperience: [
    {
      key: 'ltTwo',
      value: 1,
      text: '1년 ~ 2년',
    },
    {
      key: 'gteTwo',
      value: null,
      text: '2년 이상',
    },
  ],
};
