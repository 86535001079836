import {
  InsuranceName,
  Nullable,
  PreReservationWithPaymentDto,
  ReservationInsuranceInfoWithPreReservationDto,
  ReservationState,
  translateVehicleModelType,
} from '@orcar/common';
import {
  Button,
  CarOption,
  DriverPaymentInfo,
  getAvailableVehicleOptionsArray,
  getCurrentInsuranceRange,
  getModelFuel,
  getTransmissionText,
  INSURANCE_DETAIL,
  INSURANCE_TYPE,
  InsuranceInfoModal,
  isMobileAtom,
  OptionalService,
  RentalCondition,
  RentInfo,
  ReservationDetailInsuranceInfo,
  Styles,
  translateInsuranceType,
  useGetCompanyInfo,
  useRecoil,
  useToast,
} from '@orcar/common-d2c';
import dayjs from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';
import { InfoConfirmModal } from '@/components/header';
import { CompanyInfo } from '@/components/reservation/CompanyInfo';
import RentPolicy from '@/components/reservation/RentPolicy';
import { CAR_SHEET_COMPANY } from '@/constants/common';
import MobileInsuranceInfoModal from '@/pages/search/mobile/components/filter/MobileInsuranceInfoModal';

type D2CReservationDetailProp = {
  reservationData: ReservationInsuranceInfoWithPreReservationDto;
  preReservation: Nullable<PreReservationWithPaymentDto>;
};

const D2CReservationDetail = ({
  reservationData,
  preReservation,
}: D2CReservationDetailProp) => {
  const { recoilValue: isMobile } = useRecoil(isMobileAtom);

  const [cancelReason, setCancelReason] = useState('차량 이용 날짜/시간 변경');
  const [modalOpen, setModalOpen] = useState(false);
  const [cancelInfoOpen, setCancelInfoOpen] = useState(false);
  const [cancelInfoText, setCancelInfoText] = useState('');

  const vehicleModelData = reservationData.vehicleModel;

  const { data: companyData } = useGetCompanyInfo();

  const isCanceled = reservationData.state === ReservationState.CANCELED;
  const isBeforePickUp = dayjs().isBefore(dayjs(reservationData.pickUpAt));
  const isBeforeDropOff = dayjs().isBefore(dayjs(reservationData.dropOffAt));
  const isPickUpToday = dayjs().isSame(dayjs(reservationData.pickUpAt), 'day');
  const hasPreReservation = !!preReservation;

  const isFullCoverage = reservationData.preReservation?.isFullCoverage;
  const isNormalInsurance =
    reservationData.insuranceOption === 1 ||
    reservationData.insurances?.[0]?.insurance.name.startsWith('일반');
  const insuranceType =
    isFullCoverage && isNormalInsurance
      ? INSURANCE_TYPE.PARTIAL_UNLIMITED
      : reservationData.insuranceInfo?.name || InsuranceName.None;

  const { addToast } = useToast();
  const options = vehicleModelData
    ? [
        translateVehicleModelType(vehicleModelData.type),
        `${vehicleModelData.seat}인승`,
        getTransmissionText(vehicleModelData.transmission),
        getModelFuel(vehicleModelData.fuel),
      ]
    : [];

  const handleClickCancelButton = () => {
    if (isPickUpToday) {
      setCancelInfoText(
        `대여 당일 취소는 불가능합니다. 예약실로 연락 부탁드립니다.`,
      );
      setCancelInfoOpen(true);
      return;
    }

    // if (hasPreReservation && preReservation.d2cPayment) {
    //   setModalOpen(true);
    //   return;
    // } else {
    setCancelInfoText(`고객센터로 전화주시면 예약 취소를 도와드리겠습니다.`);
    setCancelInfoOpen(true);
    // }
  };

  // const handleCancel = () => {
  //   if (!reservationData.id || !preReservation) return;

  //   const reason = !!etcText.length ? etcText : cancelReason;

  //   cancelReservation(
  //     {
  //       input: {
  //         cancelReason: reason,
  //         customerName: reservationData.customerName1 || '',
  //         customerPhoneNumber: reservationData.customerPhoneNumber1 || '',
  //       },
  //       id: preReservation.id,
  //     },
  //     {
  //       onSuccess: () => {
  //         queryClient.invalidateQueries({
  //           queryKey: [QUERY_KEY.GET_RESERVATION],
  //         });
  //         setModalOpen(false);
  //         addToast({ content: TOAST_MESSAGE_TYPE.CONFIRM_RESERVATION_CANCEL });
  //       },
  //       onError: () => {
  //         setModalOpen(false);
  //         setCancelInfoText(
  //           `예약 취소를 실패했습니다. 고객센터로 전화주시면 예약 취소를 도와드리겠습니다. (${companyData?.phoneNumber})`,
  //         );
  //         setCancelInfoOpen(true);
  //       },
  //     },
  //   );
  // };

  return (
    <ContentWrapper isMobile={isMobile}>
      <RentInfo
        isMobile={isMobile}
        reservationState={reservationData.state}
        reservationId={reservationData.id || ''}
        pickUpDate={dayjs(reservationData.pickUpAt).format()}
        dropOffDate={dayjs(reservationData.dropOffAt).format()}
        options={options}
        vehicleModelImgUrl={vehicleModelData?.images?.[0]?.url || ''}
        vehicleModelName={vehicleModelData?.name || ''}
        companyName={companyData?.name || ''}
        companyPhoneNumber={companyData?.phoneNumber || ''}
      />

      {!isCanceled && (
        <>
          {isBeforeDropOff && (
            <>
              <TopLine isMobile={isMobile} />
              <OptionalService
                isMobile={isMobile}
                title='유모차/카시트 안내'
                titleFontSize='20px'
                titleMargin='0 0 30px 0'
                carSheetCompanyName={CAR_SHEET_COMPANY.name}
                carSheetCompanyURL={CAR_SHEET_COMPANY.url}
                carSheetCompanyTelNumber={CAR_SHEET_COMPANY.tel}
              />
            </>
          )}
          <TopLine isMobile={isMobile} />
          <RentalCondition
            isMobile={isMobile}
            company={companyData}
            numberOfSeat={vehicleModelData?.seat}
            driverAge={reservationData.insuranceInfo?.minDriverAge}
            drivingExperience={
              reservationData.insuranceInfo?.minDriverExperience
            }
          />

          <TopLine isMobile={isMobile} />
          <CarOption
            isMobile={isMobile}
            options={getAvailableVehicleOptionsArray(vehicleModelData)}
          />
          {insuranceType && (
            <>
              <TopLine isMobile={isMobile} />
              <ReservationDetailInsuranceInfo
                insuranceName={translateInsuranceType(insuranceType)}
                coverageLimit={getCurrentInsuranceRange(
                  INSURANCE_DETAIL.COVERAGE_LIMIT,
                  insuranceType,
                  reservationData.insuranceInfo?.limit || '',
                )}
                deductible={getCurrentInsuranceRange(
                  INSURANCE_DETAIL.DEDUCTIBLE,
                  insuranceType,
                  reservationData.insuranceInfo?.indemnification || '',
                )}
                lossOfUseFee={getCurrentInsuranceRange(
                  INSURANCE_DETAIL.LOSS_OF_USE_FEE,
                  insuranceType,
                  reservationData.insuranceInfo?.compensation || '',
                )}
                soloAccident={getCurrentInsuranceRange(
                  INSURANCE_DETAIL.SOLO_ACCIDENT,
                  insuranceType,
                )}
                wheelTire={getCurrentInsuranceRange(
                  INSURANCE_DETAIL.WHEEL_TIRE,
                  insuranceType,
                )}
                InsuranceInfoModal={InsuranceInfoModal}
                MobileInsuranceInfoModal={MobileInsuranceInfoModal}
              />
            </>
          )}
        </>
      )}
      {hasPreReservation && (
        <>
          <TopLine isMobile={isMobile} />
          <DriverPaymentInfo
            totalPrice={preReservation.price}
            paymentMethod={preReservation.d2cPayment?.method || ''}
            easyPayProvider={preReservation.d2cPayment?.easyPayProvider || ''}
            isMobile={isMobile}
            isOnSitePaymentOnly
          />
        </>
      )}
      {!isCanceled && (
        <>
          <TopLine isMobile={isMobile} />
          <CompanyInfo company={companyData} isMobile={isMobile} />
          <TopLine isMobile={isMobile} />
          <RentPolicy
            name={companyData?.name || ''}
            rentPolicies={companyData?.rentalRules || ''}
            isMobile={isMobile}
          />
          {isBeforePickUp && (
            <ButtonWrapper>
              <Button
                width='300px'
                height='44px'
                color={Styles.COLOR.WHITE}
                bgColor={Styles.COLOR.DANGER}
                borderRadius='5px'
                font={Styles.NEW_FONT.H5}
                onClick={handleClickCancelButton}
              >
                예약 취소하기
              </Button>
            </ButtonWrapper>
          )}
          {/* TODO: 취소 기능 붙이면서 취소 규정 넣기 */}
          {/* {isBeforePickUp && (
              <>
                <TopLine isMobile={isMobile} />
                <CancellationFee />
                <S.ButtonWrapper>
                  <Button
                    width='300px'
                    height='44px'
                    color={Styles.COLOR.WHITE}
                    bgColor={Styles.COLOR.DANGER}
                    borderRadius='5px'
                    font={Styles.NEW_FONT.H5}
                    onClick={handleClickCancelButton}
                  >
                    예약 취소하기
                  </Button>
                </S.ButtonWrapper>
              </>
            )} */}
        </>
      )}

      {/* 패밀리 취소 기능 생기면 주석 해제하기 */}
      {/* {isCanceled &&
        hasPreReservation &&
        preReservation.d2cPayment &&
        reservationData.finalPrice && (
          <>
            <TopLine isMobile={isMobile} />
            <RefundInfo
              price={preReservation.price}
              refundFee={
                preReservation.price - preReservation.d2cPayment.cancelAmount
              }
              isMobile={isMobile}
            />
            <TopLine isMobile={isMobile} />
            <CancelInfo />
          </>
        )} */}

      {/* {modalOpen && reservationData && hasPreReservation && (
          <CancelModal
            cancelReason={cancelReason}
            etcText={etcText}
            reservationData={reservationData}
            vehicleModelName={vehicleModelData?.name || ''}
            insuranceName={insuranceName}
            paidAt={reservationData.createdAt}
            setCancelReason={setCancelReason}
            setEtcText={setEtcText}
            onClose={() => setModalOpen(false)}
            onClick={handleCancel}
          />
        )} */}

      {cancelInfoOpen && (
        <InfoConfirmModal
          onClose={() => setCancelInfoOpen(false)}
          title='예약 취소 안내'
          callNumber={companyData?.phoneNumber || ''}
        >
          <p>{cancelInfoText}</p>
          <p>고객센터 운영시간 08:00~21:00</p>
        </InfoConfirmModal>
      )}
    </ContentWrapper>
  );
};

export default D2CReservationDetail;

const ContentWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isMobile }) => (isMobile ? '100%' : '792px')};
  padding: ${({ isMobile }) =>
    isMobile ? '30px 20px 40px 20px' : '60px 55px'};
  background-color: ${({ theme }) => theme.COLOR.WHITE};
  border: 1px solid ${({ theme }) => theme.COLOR.GREY_05};
  border-radius: ${({ isMobile }) => (isMobile ? '0px' : '10px')};
  box-shadow: ${({ isMobile }) =>
    isMobile && '0px 3px 10px rgba(0, 0, 0, 0.13)'};
`;

const TopLine = styled.hr<{
  margin?: string;
  isMobile?: boolean;
}>`
  width: 100%;
  height: 1px;
  margin: ${({ margin }) => (margin ? margin : '30px 0')};
  border: 0;
  background-color: ${({ theme }) => theme.COLOR.GREY_05};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 48px;
`;
