import { D2CCompanyProfileDto } from '@orcar/common';
import {
  convertToKST,
  DropdownOptionType,
  getHMFromNumber,
  getTimeText,
  isValidDateString,
  setStaticDate,
} from '@orcar/common-d2c';
import dayjs, { Dayjs, extend } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

extend(utc);
extend(timezone);
extend(isBetween);

const SAME_DAY_TIME_OFFSET = 200;

const isTimeBetween = (time: Dayjs, from: number, to: number) => {
  // 현재 날짜에 입력받은 시간을 설정

  const start = dayjs(time)
    .hour(Math.floor(from / 100))
    .minute(from % 100);
  const end = dayjs(time)
    .hour(Math.floor(to / 100))
    .minute(to % 100);

  // 시간 비교
  return time.isBetween(start, end, null, '[]');
};

/**
 *
 * @param pickUpAt 대여시간
 * @param dropOffAt 반납시간
 * @returns boolean (대여시간, 반납시간이 유효한지)
 */
export const isValidRentTime = (
  pickUpAt: string,
  dropOffAt: string,
  rentalOperatingHours: {
    pickUpOpenTime: number;
    pickUpCloseTime: number;
    dropOffOpenTime: number;
    dropOffCloseTime: number;
  },
) => {
  const now = dayjs().tz('Asia/Seoul');
  const pickUpForValid = pickUpAt
    ? dayjs(pickUpAt).tz('Asia/Seoul')
    : dayjs(convertToKST(setStaticDate('pickUp')));

  const dropOffForValid = dropOffAt
    ? dayjs(dropOffAt).tz('Asia/Seoul')
    : dayjs(convertToKST(setStaticDate('dropOff')));

  const isBothValidDateString =
    isValidDateString(pickUpForValid) && isValidDateString(dropOffForValid);

  // 30분 단위인지 검증
  const isValidPickUp = pickUpForValid.minute() % 30 === 0;
  const isValidDropOff = dropOffForValid.minute() % 30 === 0;

  const isDropOffAfterPickUp = dropOffForValid.isAfter(pickUpForValid);
  const isPickUpTimeTwoHoursLater = pickUpForValid.isAfter(
    (now.minute() > 30 ? now.set('minute', 30) : now.set('minute', 0)).add(
      2,
      'hour',
    ),
  );

  const isInOperatingPickUpTime = isTimeBetween(
    pickUpForValid,
    rentalOperatingHours.pickUpOpenTime,
    rentalOperatingHours.pickUpCloseTime,
  );
  const isInOperatingDropOffTime = isTimeBetween(
    dropOffForValid,
    rentalOperatingHours.dropOffOpenTime,
    rentalOperatingHours.dropOffCloseTime,
  );

  return (
    isBothValidDateString &&
    isValidPickUp &&
    isValidDropOff &&
    isDropOffAfterPickUp &&
    isPickUpTimeTwoHoursLater &&
    isInOperatingPickUpTime &&
    isInOperatingDropOffTime
  );
};

export const convertTimeStringToNumber = (timeString: string) => {
  const [hours, minutes] = timeString.split(':');

  const hourNumber = parseInt(hours, 10);
  const minuteNumber = parseInt(minutes, 10);

  return hourNumber * 100 + minuteNumber;
};

export const getRentalOperatingHours = (companyData?: D2CCompanyProfileDto) => {
  return {
    pickUpOpenTime: companyData?.pickUpOpenTime,
    pickUpCloseTime: companyData?.pickUpCloseTime,
    dropOffOpenTime: companyData?.dropOffOpenTime,
    dropOffCloseTime: companyData?.dropOffCloseTime,
  };
};

export const getTimeOptions = (
  type: 'pickUp' | 'dropOff',
  rentalOperatingHours: {
    pickUpOpenTime?: string;
    pickUpCloseTime?: string;
    dropOffOpenTime?: string;
    dropOffCloseTime?: string;
  },
) => {
  if (
    !rentalOperatingHours.pickUpOpenTime ||
    !rentalOperatingHours.pickUpCloseTime ||
    !rentalOperatingHours.dropOffOpenTime ||
    !rentalOperatingHours.dropOffCloseTime
  )
    return [];
  const timeOptions: Array<DropdownOptionType<number>> = [];

  const pickUpOpenTimeNumber = convertTimeStringToNumber(
    rentalOperatingHours.pickUpOpenTime,
  );
  const pickUpCloseTimeNumber = convertTimeStringToNumber(
    rentalOperatingHours.pickUpCloseTime,
  );
  const dropOffOpenTimeNumber = convertTimeStringToNumber(
    rentalOperatingHours.dropOffOpenTime,
  );
  const dropOffCloseTimeNumber = convertTimeStringToNumber(
    rentalOperatingHours.dropOffCloseTime,
  );

  if (type === 'pickUp') {
    for (let time = pickUpOpenTimeNumber; time <= pickUpCloseTimeNumber; ) {
      const { hh, mm } = getHMFromNumber(time);
      timeOptions.push({
        value: time,
        text: getTimeText(hh, mm),
      });
      time += mm === 0 ? 30 : 70;
    }
    return timeOptions;
  } else {
    for (let time = dropOffOpenTimeNumber; time <= dropOffCloseTimeNumber; ) {
      const { hh, mm } = getHMFromNumber(time);
      timeOptions.push({
        value: time,
        text: getTimeText(hh, mm),
      });
      time += mm === 0 ? 30 : 70;
    }
    return timeOptions;
  }
};

// 현재 시간을 30분 단위로 설정 (ex. 12:31~13:00 -> 13:00, 13:01~13:30 -> 13:30)
const nowInTimeOption =
  dayjs().tz('Asia/seoul').get('minute') === 0
    ? Number(dayjs().tz('Asia/seoul').get('hour').toString() + '00')
    : dayjs().tz('Asia/seoul').get('minute') > 30
    ? Number(dayjs().tz('Asia/seoul').get('hour').toString() + '00') + 100
    : Number(dayjs().tz('Asia/seoul').get('hour').toString() + '30');

export const getAvailablePickUpTimeOptions = (rentalOperatingHours: {
  pickUpOpenTime?: string;
  pickUpCloseTime?: string;
  dropOffOpenTime?: string;
  dropOffCloseTime?: string;
}) => {
  return getTimeOptions('pickUp', rentalOperatingHours).filter(
    (option) => option.value >= nowInTimeOption + SAME_DAY_TIME_OFFSET,
  );
};
