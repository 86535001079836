import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  gap: 20px;
`;

export const Container = styled.div`
  display: flex;
  padding-top: 56px;
  min-height: 720px;
`;

export const AsideWrapper = styled.div`
  position: sticky;
  top: 200px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  align-self: flex-start;
`;

export const ContentWrapper = styled.div<{ resultSection?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ resultSection }) =>
    resultSection &&
    css`
      align-items: center;
    `}
`;
