import { Nullable } from '@orcar/common';
import {
  Calendar,
  isKST,
  LeftIcon,
  RightIcon,
  TODAY_RESERVATION_AVAILABLE_TIME,
} from '@orcar/common-d2c';
import dayjs, { type Dayjs } from 'dayjs';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import * as S from './styles';

export type DateSelectionProps = {
  pickUpDate: Nullable<Dayjs>;
  setPickUpDate: Dispatch<SetStateAction<Nullable<Dayjs>>>;
  dropOffDate: Nullable<Dayjs>;
  setDropOffDate: Dispatch<SetStateAction<Nullable<Dayjs>>>;
  isMobile?: boolean;
};

const DateSelection = ({
  pickUpDate,
  setPickUpDate,
  dropOffDate,
  setDropOffDate,
  isMobile,
}: DateSelectionProps) => {
  const [leftDate, setLeftDate] = useState(
    pickUpDate ? pickUpDate.set('date', 1) : dayjs().set('date', 1),
  );
  const [rightDate, setRightDate] = useState(leftDate.add(1, 'month'));
  const [prevMonthDisabled, setPrevMonthDisabled] = useState(true);

  useEffect(() => {
    setPrevMonthDisabled(dayjs().isSame(leftDate, 'month'));
  }, [leftDate]);

  return (
    <S.Wrapper isMobile={isMobile}>
      {!isMobile && (
        <S.IconWrapper
          onClick={() => {
            if (prevMonthDisabled) return;
            setLeftDate(leftDate.subtract(1, 'month'));
            setRightDate(rightDate.subtract(1, 'month'));
          }}
          isLeft
          disabled={prevMonthDisabled}
        >
          <S.ArrowIcon src={LeftIcon} />
        </S.IconWrapper>
      )}
      <Calendar
        year={leftDate.year()}
        month={leftDate.month() + 1}
        pickUpDate={pickUpDate}
        dropOffDate={dropOffDate}
        onSelectPickup={setPickUpDate}
        onSelectDropOff={setDropOffDate}
        todayReservationDeadline={TODAY_RESERVATION_AVAILABLE_TIME}
      />
      <Calendar
        year={rightDate.year()}
        month={rightDate.month() + 1}
        pickUpDate={pickUpDate}
        dropOffDate={dropOffDate}
        onSelectPickup={setPickUpDate}
        onSelectDropOff={setDropOffDate}
        todayReservationDeadline={TODAY_RESERVATION_AVAILABLE_TIME}
      />
      {!isMobile && (
        <S.IconWrapper
          onClick={() => {
            setLeftDate(leftDate.add(1, 'month'));
            setRightDate(rightDate.add(1, 'month'));
          }}
          isLeft={false}
        >
          <S.ArrowIcon src={RightIcon} width={6} />
        </S.IconWrapper>
      )}
      {!isKST && <S.Text>* 한국 시간 기준입니다.</S.Text>}
    </S.Wrapper>
  );
};

export { DateSelection };
