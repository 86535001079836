import { Nullable } from '@orcar/common';
import {
  Button,
  DriverOptionType,
  Indicator,
  Modal,
  SHOW_TIME_WARNING_FROM,
  Styles,
} from '@orcar/common-d2c';
import { Dayjs } from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { DateSelection } from '../dateSelection/DateSelection';
import { DriverInfoSelection } from '../driverInfoSelection/DriverInfoSelection';
import { TimeSelection } from '../timeSelection/TimeSelection';

export type SearchModalProps = {
  showModal: boolean;
  searchOptionIdx: number;
  setSearchOptionIdx: Dispatch<SetStateAction<number>>;
  searchOptions: {
    pickUpDate: Nullable<Dayjs>;
    setPickUpDate: Dispatch<SetStateAction<Nullable<Dayjs>>>;
    dropOffDate: Nullable<Dayjs>;
    setDropOffDate: Dispatch<SetStateAction<Nullable<Dayjs>>>;
    pickUpTimeValue: number;
    setPickUpTimeValue: Dispatch<SetStateAction<number>>;
    dropOffTimeValue: number;
    setDropOffTimeValue: Dispatch<SetStateAction<number>>;
    driverAge: Nullable<DriverOptionType>;
    setDriverAge: Dispatch<SetStateAction<Nullable<DriverOptionType>>>;
    numberOfPeople: Nullable<DriverOptionType>;
    setNumberOfPeople: Dispatch<SetStateAction<Nullable<DriverOptionType>>>;
    drivingExperience: Nullable<DriverOptionType>;
    setDrivingExperience: Dispatch<SetStateAction<Nullable<DriverOptionType>>>;
  };
  isNextBtnDisabled: boolean;
  isCheckTimeSelected: boolean;
  indicatorTexts: string[];
  handleClickIndicator: (idx: number) => void;
  handleNextButton: () => void;
  onClose: (option?: { onRouterBack?: boolean }) => void;
};

const SearchModal = ({
  searchOptions,
  setSearchOptionIdx,
  searchOptionIdx,
  handleNextButton,
  isNextBtnDisabled,
  indicatorTexts,
  handleClickIndicator,
  isCheckTimeSelected,
  showModal,
  onClose,
}: SearchModalProps) => {
  const {
    pickUpDate,
    setPickUpDate,
    dropOffDate,
    setDropOffDate,
    pickUpTimeValue,
    setPickUpTimeValue,
    dropOffTimeValue,
    setDropOffTimeValue,
    driverAge,
    setDriverAge,
    drivingExperience,
    setDrivingExperience,
    numberOfPeople,
    setNumberOfPeople,
  } = searchOptions;

  return (
    <>
      {showModal && (
        <Modal
          onClose={onClose}
          paddingVertical={25}
          paddingHorizontal={20}
          yPosition='calc(250px + 230px)'
          topLevel
          hasBorder={false}
        >
          <>
            <ModalWrapper>
              <IndicatorWrapper>
                <Indicator
                  items={indicatorTexts}
                  selectedIndex={searchOptionIdx}
                  onClick={handleClickIndicator}
                />
              </IndicatorWrapper>
              {searchOptionIdx === 0 ? (
                <DateSelection
                  pickUpDate={pickUpDate}
                  setPickUpDate={setPickUpDate}
                  dropOffDate={dropOffDate}
                  setDropOffDate={setDropOffDate}
                />
              ) : searchOptionIdx === 1 ? (
                <TimeSelectionWrapper>
                  <TimeSelection
                    pickUpTimeValue={pickUpTimeValue}
                    setPickUpTimeValue={(value) => {
                      setPickUpTimeValue(value);
                    }}
                    dropOffTimeValue={dropOffTimeValue}
                    setDropOffTimeValue={(value) => {
                      setDropOffTimeValue(value);
                    }}
                    pickUpDate={pickUpDate}
                    dropOffDate={dropOffDate}
                    showWarning={
                      (isCheckTimeSelected &&
                        pickUpTimeValue >= SHOW_TIME_WARNING_FROM) ||
                      (isCheckTimeSelected &&
                        dropOffTimeValue >= SHOW_TIME_WARNING_FROM)
                    }
                  />
                </TimeSelectionWrapper>
              ) : (
                <SelectionWrapper>
                  <DriverInfoSelection
                    driverAge={driverAge}
                    setDriverAge={setDriverAge}
                    numberOfPeople={numberOfPeople}
                    setNumberOfPeople={setNumberOfPeople}
                    drivingExperience={drivingExperience}
                    setDrivingExperience={setDrivingExperience}
                  />
                </SelectionWrapper>
              )}
            </ModalWrapper>
            <BtnAreaWrapper>
              {searchOptionIdx > 0 && (
                <Button
                  onClick={() => setSearchOptionIdx(searchOptionIdx - 1)}
                  {...buttonStyle}
                  borderColor={Styles.COLOR.GREY_02}
                  bgColor={Styles.COLOR.WHITE}
                  color={Styles.COLOR.GREY_02}
                >
                  뒤로
                </Button>
              )}
              <Button
                className={searchOptionIdx === 2 ? 'searchButton' : undefined}
                onClick={handleNextButton}
                {...buttonStyle}
                disabled={isNextBtnDisabled}
              >
                {searchOptionIdx < 2 ? '다음' : '검색'}
              </Button>
            </BtnAreaWrapper>
          </>
        </Modal>
      )}
    </>
  );
};

export const ModalWrapper = styled.div<{ singleStep?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 896px;
  height: ${({ singleStep }) => (singleStep ? '400px' : '471px')};
`;

const IndicatorWrapper = styled.div`
  width: 95%;
  margin: 0 20px 28px 20px;
  padding: 0 21px;
`;

const SelectionWrapper = styled.div`
  width: 100%;
  padding: 0 32px;
`;

const TimeSelectionWrapper = styled(SelectionWrapper)`
  margin-top: 80px;
`;

export const BtnAreaWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 74px;
  bottom: 53px;
  z-index: 1;
`;

const buttonStyle = {
  width: '94px',
  height: '29px',
  margin: '0 0 0 50px',
};

export { SearchModal };
