import { Styles } from '@orcar/common-d2c';
import styled, { css } from 'styled-components';

export const OuterWrapper = styled.header<{
  $isHomePage: boolean;
  hasShadow?: boolean;
  $isMobile?: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: ${({ $isMobile }) =>
    $isMobile ? Styles.WIDTH.MOBILE_LAYOUT : Styles.WIDTH.PC_LAYOUT};
  height: ${({ $isMobile }) =>
    $isMobile ? Styles.HEIGHT.MOBILE_HEADER : Styles.HEIGHT.PC_HEADER};
  background-color: ${({ $isHomePage }) =>
    $isHomePage ? 'transparent' : Styles.COLOR.WHITE};
  box-shadow: ${({ hasShadow }) =>
    hasShadow ? '0px 3px 10px rgba(0, 0, 0, 0.1)' : 'none'};
  z-index: 10;

  ${({ $isHomePage }) =>
    !$isHomePage &&
    css`
      position: fixed;
      top: 0;
      left: 0;
    `}
`;

export const InnerWrapper = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  justify-content: ${({ $isMobile }) =>
    $isMobile ? 'center' : 'space-between'};
  align-items: center;
  width: ${({ $isMobile, theme }) =>
    $isMobile ? theme.WIDTH.MOBILE_LAYOUT : theme.WIDTH.PC_LAYOUT};
`;

export const Gnb = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const GnbPart = styled.div`
  display: flex;
  gap: 34px;
`;

export const NavLink = styled.span<{
  $logo?: boolean;
  color?: string;
}>`
  display: flex;
  align-items: center;
  padding: ${({ $logo }) => !$logo && '10px'};
  ${({ $logo }) => ($logo ? null : Styles.FONT.H4)};
  color: ${({ color }) => (color ? color : Styles.COLOR.GREY_01)};
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 27px;
  transform: translateY(-50%);
  z-index: 6;
`;
