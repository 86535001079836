import {
  convertToKST,
  filterInsuranceAtom,
  filterOptionAtom,
  getFilterSearchResult,
  getSureUserId,
  SearchFilter,
  useGetVehicleModels,
  useRecoil,
} from '@orcar/common-d2c';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGetSearchOptionFromUrl } from '@/hooks/useGetSearchOptionFromUrl';
import OptionSearch from './OptionSearch';
import SearchLoading from './SearchLoading';
import SearchResult from './SearchResult';
import * as S from './styles';
import TimeoutModal from './TimeoutModal';
import { TopButton } from './TopButton';
import SearchNull from './vehicleCard/SearchNull';

const SearchLayout = () => {
  const sureUserId = getSureUserId();

  const { initialSearchOptions } = useGetSearchOptionFromUrl();

  const { recoilValue: recoilInsurance } = useRecoil(filterInsuranceAtom); // TODO: 보험 전역 상태값을 없애고 검색 핑터 전역 내부로 이동시키기
  const { recoilValue: recoilFilterOption } = useRecoil(filterOptionAtom);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: vehicleModelList,
    isLoading,
    isError,
    error,
  } = useGetVehicleModels({
    params: {
      pickUpAt: convertToKST(initialSearchOptions.pickUpAt),
      dropOffAt: convertToKST(initialSearchOptions.dropOffAt),
      driverAge: initialSearchOptions.driverAge,
      numberOfPeople: initialSearchOptions.numberOfPeople,
      drivingExperience: initialSearchOptions.drivingExperience,
      sureUserId: sureUserId ? Number(sureUserId) : undefined,
    },
  });

  const filterResult = getFilterSearchResult(
    vehicleModelList,
    recoilFilterOption,
    recoilInsurance,
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsModalOpen(true);
    }, 900000);

    return () => {
      clearTimeout(timer);
    };
  }, [isModalOpen]);

  return (
    <Wrapper>
      <OptionSearch searchOptions={initialSearchOptions} />
      <S.Container>
        <S.AsideWrapper>
          <SearchFilter vehicleModelList={vehicleModelList} />
        </S.AsideWrapper>
        <S.ContentWrapper>
          <S.ContentWrapper resultSection>
            {isError ? (
              <SearchNull error={error} />
            ) : isLoading ? (
              <SearchLoading />
            ) : (
              <SearchResult
                searchOptions={initialSearchOptions}
                vehicleModelList={filterResult}
              />
            )}
          </S.ContentWrapper>
        </S.ContentWrapper>
      </S.Container>
      {isModalOpen && <TimeoutModal onClose={() => setIsModalOpen(false)} />}
      <TopButton />
    </Wrapper>
  );
};

export default SearchLayout;

const Wrapper = styled.div<{ isMobile?: boolean }>`
  display: flex
  gap: 20px;
  width: 1384px;
`;
