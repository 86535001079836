import { COMPANY_NAME } from '@orcar/common-d2c';
import * as S from '../styles';

const CompanyInfoSection = () => {
  return (
    <S.CompanyInfoSectionWrapper>
      <S.InfoContent>
        {COMPANY_NAME.RAINBOW} | 사업자등록번호 : 130-86-50144 | 대표 : 변 희 정
      </S.InfoContent>
      <S.InfoContent>
        통신판매신고 : 제2022-제주도두-0033호 | 주소: 제주특별자치도 제주시
        제성1길 28-1
      </S.InfoContent>
      <S.InfoContent>
        {`상담문의전화 : 064)710-1600 | email : rainbowrentacar@naver.com`}
      </S.InfoContent>
    </S.CompanyInfoSectionWrapper>
  );
};

export default CompanyInfoSection;
